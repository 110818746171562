import React from "react";
import { IoTicket } from "react-icons/io5";
import { StyledTimeline } from "./styled";
import { format } from "date-fns";
import { derivedAllAgentsDataSelector } from "@components-inbox/ConversationPanel/utils";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setActiveConversation } from "@store-inbox/ConversationPanel/action";

function Timeline({ item }) {
  const derivedAgents = useSelector(derivedAllAgentsDataSelector);
  const dispatch = useDispatch();
  return (
    <StyledTimeline className="relative">
      <div className="column-right ">
        <div className="col-card-right">
          <Link to={`/inbox/tickets/${item.id}`}>
            <div
              className="col-card-title col-card-title-right"
              onClick={() => dispatch(setActiveConversation(item.id))}
            >
              #{item.id}{" "}
              {item.created_at && (
                <span style={{ fontSize: "12px" }}>
                  ( {format(Date.parse(item.created_at), "dd MMM yyyy")} )
                </span>
              )}
            </div>
          </Link>
          {item.subject !== null && item.subject !== "" && (
            <div className="col-card-subtitle col-card-subtitle-right">
              <span style={{ color: "#585f6d", fontWeight: "500" }}>Sub:</span>{" "}
              {item.subject}
            </div>
          )}
          <div className="col-card-subtitle col-card-subtitle-right">
            <div style={{ display: "flex" }}>
              <span
                style={{
                  color: "#585f6d",
                  fontWeight: "500",
                  marginRight: "2px",
                }}
              >
                Status:
              </span>{" "}
              {item.status}
            </div>
            <div style={{ marginLeft: "1rem", display: "flex" }}>
              <span
                style={{
                  color: "#585f6d",
                  fontWeight: "500",
                  marginRight: "2px",
                }}
              >
                Agent:
              </span>{" "}
              {item.agent_id !== null &&
              item.agent_id != -1 &&
              derivedAgents !== null &&
              derivedAgents.length > 0 &&
              derivedAgents.filter((a) => a.id == item.agent_id).length > 0
                ? derivedAgents.filter((a) => a.id == item.agent_id)[0].first_name
                : "Nil"}
            </div>
          </div>
          {item.tags !== null && (
            <div className="col-card-subtitle col-card-subtitle-right">
              {item.tags.split(",").length > 0 &&
                item.tags.split(",").map((tag) => {
                  return (
                    <p
                      style={{
                        background: "#fff",
                        marginRight: "5px",
                        fontSize: "9px",
                        padding: "1px 8px",
                        border: "1px solid var(--color-primary)",
                        color: "var(--color-primary)",
                      }}
                    >
                      {tag}
                    </p>
                  );
                })}
            </div>
          )}
        </div>
      </div>

      <div className="column-center">
        <div className="line"></div>
        <div className="ticket-circle">
          <IoTicket />
        </div>
      </div>

      <div className="clearfix"></div>
    </StyledTimeline>
  );
}

export default Timeline;
