import styled from "styled-components";

export const StyledMultiSelectBar = styled.div`
    display: flex;
    margin: 0px 18px 10px;
    box-shadow: 2px 2px 2px rgba(29, 141, 204, 0.25), -2px -2px 2px rgba(29, 141, 204, 0.25);
    border-radius: 8px;
    height: 60px;
    align-items: center;
    padding: 0 20px;
`