import styled from "styled-components";

export const StyledTagsInput = styled.div`
  padding: 0px 15px;
  border-left: 1px solid #D5D5D5;
  border-right: 1px solid #D5D5D5;
	background: #1D8DCC1A;
  .react-tags__selected-tag{
    background: rgba(255,255,255,0.7);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    padding: 4px 8px;
    border-radius: 2px;
    :after{
      color: var(--color-primary);
      cursor: pointer;
    }
  }

  .react-tags__search-wrapper{
    background : #F0F2F6;
  }

  .react-tags__search-input{
    background : #F0F2F6;
    color: #3C474E;
  }

  .ant-select-clear{
    display: none;
  }

`;

export const StyledDisabledTag = styled.div`
  .ant-tag{
    background: rgba(255,255,255,0.7);
    color: #3C474E;
    margin: 0 6px 6px 0;
    padding: 0px 5px;
    font-size:0.8em;
  }
`;