import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import { getACCalculations } from './utils';
import { StyledChart, StyledLegend, StyledTotal } from './styled';

const initialState = {
    abandoned: 0,
    converted: 0,
}

function ACChart(props) {
    const { analytics, loading } = props;
    const [total, setTotal] = useState(0);
    const [percentages, setPercentages] = useState(initialState);

    useEffect(() => {
        if (!loading && analytics.total_abandoned_checkouts !== undefined) {
            const totalOrder = analytics.total_abandoned_checkouts + analytics.abandoned_checkouts_converted;
            setTotal(totalOrder);
            const calculatedPercentages = getACCalculations(analytics.total_abandoned_checkouts, analytics.abandoned_checkouts_converted, totalOrder);
            setPercentages(calculatedPercentages);
        } else {
            setPercentages(initialState);
        }
    }, [analytics, loading]);

    return (
        <StyledChart>
            {/* <Progress type="circle" percent={percentages.abandoned} success={{ percent: percentages.converted, strokeColor: '#5DA797' }} strokeColor={"#F7A400"} format={() => <StyledTotal>
                <div className="total-number">{!loading ? total : '-'}</div>
                <div>ACs</div>
            </StyledTotal>
            } /> */}
            <StyledTotal>
                <div className="total-number">{!loading ? total : '-'}</div>
                <div style={{width: '50%'}}>Abandoned Checkouts</div>
            </StyledTotal>
            <div className="legends">
                <StyledLegend>{!loading ? analytics.total_abandoned_checkouts : '-'} - Abandoned </StyledLegend>
                <StyledLegend>{!loading ? analytics.abandoned_checkouts_converted : '-'} - Converted</StyledLegend>
            </div>
        </StyledChart>
    );
}

export default ACChart;