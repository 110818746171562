import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";
import { conversationsDataSelector, connectionsSelector, initiationSelector } from '@store-inbox/MainPanel/selector';


export const tabsDataSelector = createSelector(conversationsDataSelector, (conversationData) => {
    let tabs = [];
    if (conversationData && conversationData.length > 0) {
        const conversations = conversationData;
        tabs = orderBy(conversations, [(object) => new Date(object.conversation?.last_message.created_at)], ['desc']);
    }
    return tabs;
});

export const derivedConnectionsSelector = createSelector(connectionsSelector, (connectionsData) => {
    if(connectionsData && connectionsData.response){
        return connectionsData.response;
    }
    else{
        return {};
    }
})