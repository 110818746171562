import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --color-background-light: #F5F4F9;
    --color-background-dark: #E3E4EE;
    --color-primary: #0C477D;
    --color-icon-background: #26282F;
    --color-red: #F44336;
  }
`;

export default GlobalStyles;