import React, { Fragment, useEffect, useRef, useState } from "react";
import find from "lodash/find";
import { useDispatch, useSelector } from "react-redux";
import {
  removeSubscriber,
  addSubscriber,
  changeOwnership,
} from "@store-inbox/ConversationPanel/action";
import { derivedAllAgentsDataSelector } from "../utils";
import {
  StyledAgentsInput,
  StyledDropdownIcon,
  StyledSubscriberIcon,
} from "./styled";
import { ReactComponent as AddUserIcon } from "@assets/icons/message/adduser.svg";
import { ReactComponent as AssignUserIcon } from "@assets/icons/message/assignuser.svg";
import {
  Menu,
  Tooltip,
  Modal,
  Radio,
  Space,
  Button,
  Col,
  Avatar,
  Divider,
} from "antd";
import { getInitials } from "@utils/functions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { setSearchFilters } from "@store-inbox/MainPanel/action";
import { derivedConnectionsSelector } from "../../MainPanel/utils";
import { payloadSelector } from "@store-inbox/MainPanel/selector";

function Subscribers(props) {
  const {
    id,
    formerSubscribers,
    ownerStatus,
    disabled,
    owner_id,
    setOwnerStatus,
    status,
  } = props;
  const dispatch = useDispatch();
  const allAgents = useSelector(derivedAllAgentsDataSelector);
  const connections = useSelector(derivedConnectionsSelector);
  const [derivedAgents, setDerivedAgents] = useState([]);
  const [agents, setAgents] = useState([]);
  const [visible, setVisible] = useState(false);
  const [agentKey, setAgentKey] = useState(null);
  const [footerButton, setFooterButton] = useState(null);
  //   const [addVisible, setAddVisible] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [currentAgent, setCurrentAgent] = useState({});

  const payload = useSelector(payloadSelector);
  const history = useHistory();

  toast.configure();

  const handleDeleteAgent = (index) => {
    dispatch(
      removeSubscriber({
        ticket_id: id,
        subscriber: index,
      })
    );
  };

  const handleAddAgent = (index) => {
    dispatch(
      addSubscriber({
        ticket_id: id,
        subscriber: index,
      })
    );
  };

  const handleChangeOwner = () => {
    setVisible(false);
    setOwnerStatus(false);
    dispatch(
      changeOwnership({
        ticket_ids: [id],
        transfer_to: agentKey,
      })
    );
    history.push("/inbox/tickets");
    dispatch(
      setSearchFilters({
        filters: {
          ...payload.filters,
        },
        fetch_since: null,
        page_size: 25,
      })
    );
    const assignedAgent = derivedAgents.filter((item) => item.id == agentKey);
    toast.success(
      `#${id} has been assigned to ${
        assignedAgent[0].first_name + " " + assignedAgent[0].last_name
      }`,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
  };

  useEffect(() => {
    if (connections && Object.keys(connections).length > 0) {
      const filteredAgents = allAgents.filter(
        (agent) => agent.id !== Number(connections.current_user_id)
      );
      const matchedCurrentAgent = allAgents.filter(
        (agent) => agent.id === Number(connections.current_user_id)
      );
      setCurrentAgent(matchedCurrentAgent[0]);
      setDerivedAgents(filteredAgents);
    }
  }, [allAgents, connections]);

  useEffect(() => {
    const oldAgents = [];
    if (formerSubscribers) {
      formerSubscribers.map((subscriber) => {
        oldAgents.push(find(allAgents, ["id", subscriber]));
      });
    }
    setAgents(
      oldAgents.map((item) => {
        return item.id;
      })
    );
    setAgentName(oldAgents);
  }, [formerSubscribers]);

  const agentsMenu = (
    <Menu className="header-menu-icon" onClick={handleChangeOwner}>
      {derivedAgents.map((item) => {
        return (
          <Menu.Item key={item.id} value={item.id}>
            <Tooltip placement="right" className="tooltip">
              <StyledDropdownIcon>
                <input type="checkbox" />
                <span
                  style={{ fontSize: "16px", marginLeft: "10px", width: "80%" }}
                >
                  {item.first_name}
                </span>
              </StyledDropdownIcon>
            </Tooltip>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const prevAgentsRef = useRef();

  useEffect(() => {
    prevAgentsRef.current = agents;
  }, [agents]);

  // const onChange=(e,index)=>{
  //         const sampleAgent = derivedAgents.filter((item)=>{if(e.includes(item.id))return(item.first_name)})
  //         // if(sampleAgent.length>0){
  //         //     let firstName = sampleAgent[sampleAgent.length-1].first_name;
  //         //     let lastName = sampleAgent[sampleAgent.length-1].last_name;
  //         //     setAgentName(firstName+lastName);
  //         // }
  //         // else{
  //         //     setAgentName('');
  //         // }
  //         // setAgentName(sampleAgent[0].first_name);
  //     setAgents();
  //     setAgentName(sampleAgent);
  //     // if(agents.length<e.length){
  //     //     let temp = e.filter(x => !prevAgentsRef.current.includes(x));
  //     //     handleAddAgent(temp[0]);
  //     // }else{
  //     //     let temp = prevAgentsRef.current.filter(x => !e.includes(x));
  //     //     handleDeleteAgent(temp[0]);
  //     // }
  //     setFooterButton(true);
  // }

  //   const handleAgents = (e, agentid) => {
  //     if (agents.length > 0) {
  //       if (agents.indexOf(agentid) > -1) {
  //         let tempAgents = [...agents];
  //         setAgents(tempAgents.splice(tempAgents.indexOf(agentid), 1));
  //       } else {
  //         setAgents([...agents, agentid]);
  //       }
  //     }
  //     if (e.target.checked) {
  //       handleAddAgent(agentid);
  //       let tempAgentsName = [...agentName];
  //       const sampleAgent = derivedAgents.filter((item) => item.id == agentid);
  //       setAgentName([sampleAgent[0], ...tempAgentsName]);
  //     } else if (!e.target.checked) {
  //       handleDeleteAgent(agentid);
  //       let tempAgentsName = [...agentName];
  //       const sampleAgent = derivedAgents.filter((item) => item.id == agentid);
  //       tempAgentsName.splice(tempAgentsName.indexOf(sampleAgent[0]), 1);
  //       setAgentName(tempAgentsName);
  //     }
  //   };

  return (
    <StyledAgentsInput>
      {ownerStatus && !disabled && status !== "closed" ? (
        // disabled ?
        // <StyledDisabledAgents > {
        //     (agents.length > 1) &&
        //     <Tag>
        //         agents[0].name(agents.length)
        //     </Tag>
        // }
        // </StyledDisabledAgents> :
        // <ReactTags
        //     disabled = { disabled }
        //     allowBackspace = { false }
        //     placeholderText = "+ Add Subscriber..."
        //     tags = { agents }
        //     suggestions = { derivedAgents }
        //     onDelete = { handleDeleteAgent }
        //     onAddition = { handleAddAgent } />
        // <Dropdown overlay={priorityMenu} placement="bottomLeft" disabled={!ownerStatus}>
        //         <Tooltip
        //             placement="top"
        //             // title={currentPriority.title}
        //             className="tooltip">
        //         <a style={{fontSize: "16px",marginRight: "20px", marginTop: "5px"}}>Add Agents</a>
        //     </Tooltip>
        // </Dropdown>
        <Fragment>
          {/* <Multiselect
                    options={derivedAgents} // Options to display in the dropdown
                    selectedValues={agents}
                    onSelect={(value,i)=>handleAddAgent(value,i)} // Function will trigger on select event
                    onRemove={(value,i)=>handleDeleteAgent(value,i)} // Function will trigger on remove event
                    displayValue="first_name" // Property name to display in the dropdown options
                    showCheckbox={true}
                    placeholder="Add Agents"
                    className='agent-add'
                    style={{multiselectContainer:{width: "200px",height: "100%"}}}
                /> 
                <Select {...selectProps}>
                {derivedAgents.map(item => {
                    return(
                        <Option key={item.id} value={item.id}>{item.first_name}</Option>
                    )
                    }
                )}
                <AddUserIcon style={agentName!=='' ? {marginLeft: '-10px'} : {marginLeft: '0px'}} />
                {sampleAgent.length>1 ?  <Fragment><Avatar style={{background: '#D1D5E3',color: '#0C477D',height: '35px',width: '40px'}}>{getInitials(agentName)}</Avatar> + {sampleAgent.length}</Fragment> : <AddUserIcon style={agentName!=='' ? {marginLeft: '-10px'} : {marginLeft: '0px'}} /> }
                </Select> */}
          {/* <StyledSubscriberIcon onClick={()=>setAddVisible(true)}>
                        <Tooltip placement='bottom' title="Add Agent" className='agent-tooltip'>
                                {agentName.length > 0 ? <Avatar style={{background: 'var(--color-icon-background)',color: '#fff'}}>{getInitials(agentName[0].first_name + agentName[0].last_name)}</Avatar> : <AddUserIcon /> }
                                {agentName.length > 1 ? <Fragment>+ <span>{agentName.length - 1}</span></Fragment> : ""}
                        </Tooltip>
                    </StyledSubscriberIcon>
                    <Modal
                        visible={addVisible}
                        onCancel={()=>setAddVisible(false)}
                        centered
                        footer={false}
                    >
                        <p style={{color: '#474747',marginTop: '-10px',marginBottom: '0',fontWeight: '500',fontSize: '18px'}}>Add agents to this conversation</p>
                        <p style={{color: '#818181',marginTop: '5px',fontWeight: '200',fontSize: '16px'}}>You will also be a part of this ticket</p>
                        <Divider style={{margin: '15px 0px'}} />
                            {derivedAgents.map(item => {
                                return (
                                    <Col style={{marginTop: '10px'}} key={item.first_name}>
                                        <label>
                                            <input type="checkbox" defaultChecked={agents.includes(item.id)} style={{marginRight: '10px'}} onChange={(e)=>handleAgents(e,item.id)} />
                                            {item.first_name}
                                        </label>
                                    </Col>
                                )
                            })}
                    </Modal> */}
          {currentAgent &&
            Object.keys(currentAgent).length > 0 &&
            currentAgent.can_assign_ticket_to_other_agent && (
              <StyledSubscriberIcon onClick={() => setVisible(true)}>
                <Tooltip
                  placement="bottom"
                  className="agent-tooltip"
                  title="Assign Ticket"
                >
                  <AssignUserIcon />
                </Tooltip>
              </StyledSubscriberIcon>
            )}
          <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            centered
            footer={
              footerButton && (
                <Button
                  key="submit"
                  style={{
                    background: "#0C477D",
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                  onClick={handleChangeOwner}
                >
                  Update
                </Button>
              )
            }
          >
            <p
              style={{
                color: "#474747",
                marginTop: "-10px",
                marginBottom: "0",
                fontWeight: "500",
                fontSize: "18px",
              }}
            >
              Assign ticket to
            </p>
            <p
              style={{
                color: "#818181",
                marginTop: "5px",
                fontWeight: "200",
                fontSize: "16px",
              }}
            >
              You will be removed from this ticket
            </p>
            <Divider style={{ margin: "15px 0px" }} />
            <Radio.Group
              aria-label="agents"
              name="agents-group"
              value={agentKey}
              onChange={(e) => {
                setAgentKey(e.target.value);
                setFooterButton(true);
              }}
            >
              <Space direction="vertical">
                {derivedAgents.map((item) => {
                  return (
                    <Radio key={item.id} value={item.id}>
                      {item.first_name}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Modal>
          {/* <Multiselect
                    <Menu.Item key={item.id} value={item.id}>
                                <Tooltip
                                    placement="left"
                                    className="tooltip"
                                >
                                <StyledDropdownIcon>
                                    <label htmlFor={item.id} style={{fontSize: "16px", marginLeft: "10px", width: "100%"}}>{item.first_name}</label>
                                    <input type="checkbox" id={item.id} />
                                </StyledDropdownIcon>
                                 </Tooltip>
                            </Menu.Item>
                    options={derivedAgents} // Options to display in the dropdown
                    singleSelect
                    onSelect={(value,i)=>handleChangeOwner(value,i)} // Function will trigger on select event
                    // onRemove={(value,i)=>handleDeleteAgent(value,i)} // Function will trigger on remove event
                    displayValue="first_name" // Property name to display in the dropdown options
                    showCheckbox={true}
                    placeholder="Assign Ticket"
                    style={{multiselectContainer:{width: "80%",height: "100%"}}}
                /> */}
        </Fragment>
      ) : null}
    </StyledAgentsInput>
  );
}

export default Subscribers;
