import React, { useEffect, useState } from "react";
import { StyledMultiSelectBar } from "./styled";
import { ReactComponent as AssignUserIcon } from "@assets/icons/message/assignuser.svg";
import { Dropdown, Menu, Radio, Space } from "antd";
import { derivedAllAgentsDataSelector } from "@components-inbox/ConversationPanel/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOwnership,
  setStatus,
} from "@store-inbox/ConversationPanel/action";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "@assets/icons/main/slash.svg";
import { tabsDataSelector, derivedConnectionsSelector } from "../utils";

function MultiSelect(props) {
  const {
    selectedArray,
    setSelectedArray,
    activeKey,
    setTemplate,
    setNewChat,
  } = props;
  const allAgents = useSelector(derivedAllAgentsDataSelector);
  const [derivedAgents, setDerivedAgents] = useState([]);
  const [showAssignDropdown, setShowAssignDropdown] = useState(true);
  // const [agents, setAgents] = useState([]);
  // const [agentName,setAgentName] = useState('');
  const dispatch = useDispatch();
  const alltickets = useSelector(tabsDataSelector);
  const ticket = alltickets[0].ticket;

  const connections = useSelector(derivedConnectionsSelector);
  const { status } = ticket;

  // const handleDeleteAgent = (index) => {
  //   dispatch(removeSubscriber({
  //       "ticket_id": selectedArray,
  //       "subscriber": index
  //   }));
  // }

  // const handleAddAgent = (index) => {
  //     dispatch(addSubscriber({
  //         "ticket_id": selectedArray,
  //         "subscriber": index,
  //     }));
  // }

  useEffect(() => {
    const filteredAgents = allAgents.filter(
      (agent) => agent.id !== Number(connections.current_user_id)
    );
    const currentAgent = allAgents.filter(
      (agent) => agent.id === Number(connections.current_user_id)
    );
    if (activeKey === "assigned" || activeKey === "open") {
      if (currentAgent[0].can_assign_ticket_to_other_agent) {
        setDerivedAgents(filteredAgents);
      } else {
        setShowAssignDropdown(false);
      }
    } else if (
      currentAgent[0].can_assign_ticket_to_other_agent &&
      currentAgent[0].can_assign_ticket_to_self
    ) {
      setDerivedAgents(allAgents);
    } else if (
      !currentAgent[0].can_assign_ticket_to_self &&
      !currentAgent[0].can_assign_ticket_to_other_agent
    ) {
      setShowAssignDropdown(false);
    } else if (
      currentAgent[0].can_assign_ticket_to_self &&
      !currentAgent[0].can_assign_ticket_to_other_agent &&
      status == "unassigned"
    ) {
      setDerivedAgents(currentAgent);
      setShowAssignDropdown(true);
    } else if (
      !currentAgent[0].can_assign_ticket_to_self &&
      currentAgent[0].can_assign_ticket_to_other_agent &&
      status == "unassigned"
    ) {
      setDerivedAgents(filteredAgents);
      setShowAssignDropdown(true);
    } else {
      setShowAssignDropdown(false);
    }
  }, [allAgents]);

  // useEffect(() => {
  //     const oldAgents = [];
  //     if (subscribers) {
  //         subscribers.map((subscriber) => {
  //             oldAgents.push(find(allAgents, ['id', subscriber]));
  //         });
  //     }
  //     setAgents(oldAgents.map((item)=>{return item.id}));
  //     setAgentName(oldAgents);
  // }, [subscribers]);

  // const handleAgents = (e,agentid)=>{
  //   if(agents.length>0){
  //       if(agents.indexOf(agentid)>-1){
  //           let tempAgents = [...agents];
  //           setAgents(tempAgents.splice(tempAgents.indexOf(agentid),1));
  //       }else{
  //           setAgents([...agents,agentid]);
  //       }
  //   }
  //   if(e.target.checked){
  //       handleAddAgent(agentid);
  //       let tempAgentsName = [...agentName];
  //       const sampleAgent = derivedAgents.filter((item)=>item.id == agentid);
  //       setAgentName([sampleAgent[0],...tempAgentsName]);
  //   }
  //   else if(!e.target.checked){
  //       handleDeleteAgent(agentid);
  //       let tempAgentsName = [...agentName];
  //       const sampleAgent = derivedAgents.filter((item)=>item.id == agentid);
  //       tempAgentsName.splice(tempAgentsName.indexOf(sampleAgent[0]),1);
  //       setAgentName(tempAgentsName);
  //   }
  // }

  // const addAgentsMenu = (
  //   <Menu className="header-menu-icon">
  //     {derivedAgents.map(item => {
  //       return (
  //         <Menu.Item key={item.id} value={item.id}>
  //           <Col style={{marginTop: '10px'}}>
  //               <label>
  //                   {item.first_name + " " + item.last_name}
  //                   <input type="checkbox" defaultChecked={agents.includes(item.id)} style={{float:'right'}} onChange={(e)=>handleAgents(e,item.id)} />
  //               </label>
  //           </Col>
  //         </Menu.Item>
  //       )
  //     })}
  //   </Menu>
  // )

  toast.configure();

  const handleChangeOwner = (agentKey) => {
    dispatch(
      changeOwnership({
        ticket_ids: selectedArray,
        transfer_to: agentKey,
      })
    );
    const assignedAgent = derivedAgents.filter((item) => item.id == agentKey);
    toast.success(
      `Tickets have been assigned to ${
        assignedAgent[0].first_name + " " + assignedAgent[0].last_name
      }`,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
    setSelectedArray([]);
  };

  const assignAgentsMenu = (
    <Menu className="header-menu-icon" style={{ padding: "10px 15px" }}>
      <Radio.Group
        aria-label="agents"
        name="agents-group"
        onChange={(e) => {
          handleChangeOwner(e.target.value);
        }}
      >
        <Space direction="vertical">
          {derivedAgents.map((item) => {
            return (
              <Radio key={item.id} value={item.id}>
                {item.first_name}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    </Menu>
  );

  const handleStatusChange = () => {
    if (status !== "closed" && status !== "unassigned") {
      dispatch(
        setStatus({
          ticket_ids: selectedArray,
          status: "closed",
        })
      );
      toast.success(`Tickets have been closed`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else {
      toast.error("Tickets are not yet assigned to be closed.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
    setSelectedArray([]);
  };

  return (
    <StyledMultiSelectBar>
      <p
        style={{
          margin: "0",
          fontSize: "18px",
          fontWeight: "600",
          cursor: "pointer",
        }}
        onClick={() => setSelectedArray([])}
      >
        x
      </p>
      <p
        style={{
          margin: "0",
          marginLeft: "10px",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        {selectedArray.length}
      </p>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        {/*  <Dropdown overlay={addAgentsMenu} className='unread-dropdown' placement="bottomLeft" trigger={['click']}>
          <div style={{display: 'flex',alignItems: 'center',cursor: 'pointer'}}>
            <AddUserIcon />
            <p style={{margin: '0',marginLeft: '0px',color: 'var(--color-primary)',fontSize: '16px',fontWeight: '400'}}>Add agent</p>
          </div>
        </Dropdown> */}
        {showAssignDropdown && (
          <Dropdown
            overlay={assignAgentsMenu}
            className="unread-dropdown"
            placement="bottomCenter"
            trigger={["click"]}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            >
              <AssignUserIcon />
              <p
                style={{
                  margin: "0",
                  marginLeft: "0px",
                  color: "var(--color-primary)",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {activeKey === "unassigned" || activeKey === ""
                  ? "Assign"
                  : "Transfer"}
              </p>
            </div>
          </Dropdown>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginLeft: "10px",
          }}
          onClick={() => handleStatusChange()}
        >
          <CloseIcon />
          <p
            style={{
              margin: "0",
              marginLeft: "5px",
              color: "var(--color-primary)",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Close
          </p>
        </div>

        {(status === "assigned" || status === "open") && (
          <div style={{ marginLeft: "10px", cursor: "pointer" }}>
            <p
              style={{
                margin: "0",
                marginLeft: "10px",
                color: "var(--color-primary)",
                fontSize: "16px",
                fontWeight: "400",
              }}
              onClick={() => {
                setTemplate(true);
                setNewChat(true);
              }}
            >
              Send Message
            </p>
          </div>
        )}
      </div>
    </StyledMultiSelectBar>
  );
}

export default MultiSelect;
