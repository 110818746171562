import { useState, useRef, useEffect } from 'react'
import { AudioBox, AudioButton, AudioContainer, AudioSymbol } from './styled'
import { ReactComponent as PlayButton } from '@assets/icons/message/play.svg'
import { ReactComponent as PauseButton } from '@assets/icons/message/pause.svg'
import { ReactComponent as MusicIcon } from '@assets/icons/message/headphones.svg'
import { ReactComponent as VoiceIcon } from '@assets/icons/chatlist/mic.svg'

export default function AudioAttachment(props) {
  
  const { song, type, actor } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const audioRef = useRef()
  const progressBarRef = useRef();
  const animationRef = useRef();

 
  const changeRange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
    changePlayerCurrentTime();
  }

  const calculateTime = (secs)=>{
    
    const minutes = Math.floor(secs/60);
    const returnedMinutes = minutes<10 ? `0${minutes}` : `${minutes}`;
    
    const seconds = Math.floor(secs%60);
    const returnedSeconds = seconds<10 ? `0${seconds}` : `${seconds}`;
    
    return `${returnedMinutes}:${returnedSeconds}`;
  }

  const onLoadedMetaData = ()=>{
    const seconds = Math.floor(audioRef.current.duration);
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  }

  useEffect(()=>{
    onLoadedMetaData();
  },[audioRef?.current?.loadedmetadata, audioRef?.current?.readyState])


  const whilePlaying = ()=>{
    progressBarRef.current.value = audioRef.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  }

  const changePlayerCurrentTime =  ()=>{
    progressBarRef.current.style.setProperty('--seek-before-width',`${progressBarRef.current.value/duration * 100}%`);
    setCurrentTime(progressBarRef.current.value);
  }

  const togglePlay=()=>{
    const prevValue= isPlaying;
    setIsPlaying(!prevValue);
    if(!prevValue){
      audioRef.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    }else{
      audioRef.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }

  return (
    <AudioContainer actor={actor}>
      {/* <Slider percentage={percentage} onChange={onChange} />
      <audio
        ref={audioRef}
        onTimeUpdate={getCurrDuration}
        onLoadedData={(e) => {
          setDuration(e.currentTarget.duration.toFixed(2))
        }}
        src={song}
      >
      </audio>
      <ControlPanel
        play={play}
        isPlaying={isPlaying}
        duration={duration}
        currentTime={currentTime}
      />
      <div>{(duration && !isNaN(duration)) && calculateTime(duration)}</div>
     */}
      <AudioSymbol>
        {type=="audio" ? <MusicIcon style={{stroke: "#fff",width: "24px",height: "24px"}} /> : <VoiceIcon style={{stroke: "#fff",width: "24px",height: "24px"}} />}
      </AudioSymbol>
      <AudioBox>
        <audio ref={audioRef} src={song} preload="metadata" onLoadedMetadata={onLoadedMetaData} />
        <AudioButton onClick={togglePlay}>{isPlaying ? <PauseButton /> : <PlayButton />}</AudioButton>
        <input ref={progressBarRef} type="range" className='progressbar' defaultValue="0" onChange={changeRange} />
        <div>{calculateTime(currentTime)}</div>
      </AudioBox>
    </AudioContainer>
  )
}