import styled from 'styled-components';

export const StyledSummaryWrapper = styled.div`
    display: flex;
    min-width:300px;
    width: 30%;
    max-width: 420px;
    transition: width 300ms ease-in-out;
`;

export const StyledFlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
    margin: 10px 15px;

    p{
        font-size: 24px;
        margin: 0px;
    }
`;

export const StyledDetailAnalyticsWrapper = styled.div`
    min-height: 50vh;
`;

export const StyledEmptyHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin-top: 10px;
    flex:1;
    font-size: 0.8rem;
    border-radius: 15px;
    padding: 15px;
    min-height: 40vh;
`;

export const StyledOrdersWrapper = styled.div`
    flex:1;
    font-size: 0.8rem;
    background: #fff;
    border-radius: 15px;
    min-height: 40vh;

    .ant-tabs{
        overflow: hidden;
        margin: 0px 10px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #474747;
    }

    .ant-tabs-tab {
        color: #474747;
    }
`;
