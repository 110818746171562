import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Tag } from 'antd';
import { addTags, removeTags } from '@store-inbox/ConversationPanel/action';
import { derivedAllTags } from '../utils';
import { StyledTagsInput, StyledDisabledTag } from './styled';
import { popularTagsDataSelector } from '@store-inbox/MainPanel/selector';
import { AiFillQuestionCircle } from "react-icons/ai";

function Tags(props) {
    const { ticket: { id, owner_id }, isOwner } = props;
    const dispatch = useDispatch();
    const [ownerStatus, setOwnerStatus] = useState(false);
    const [tags, setTags] = useState([]);
    const [options, setOptions] = useState([]);
    const popularTags = useSelector(popularTagsDataSelector);
    const allTags = useSelector(derivedAllTags);
    const { Option } = Select;
    // const [expired, setExpired] = useState(true);
    
    // const timeStamp = Math.round(new Date().getTime() / 1000);
    // const timeStampYesterday = timeStamp - (24 * 3600);

    // useEffect(()=>{
    //     if(new Date(session_expiry_at) >= new Date(timeStampYesterday*1000).getTime()){
    //         setExpired(true)
    //     }else{
    //         setExpired(false);
    //     }
    // },[session_expiry_at])

    useEffect(()=>{
        const index = allTags.findIndex(p => p.ticket_id == id);
        if(index!==-1){
            if(allTags[index].tags !== null && allTags[index].tags !==""){
                let splitDesiredTags = allTags[index].tags.split(",")
                if (Array.isArray(splitDesiredTags)) {
                    const spiltTags = splitDesiredTags.map((splitTag) => {
                        return {
                            id: splitTag,
                            name: splitTag,
                        }
                    });
                    setTags(spiltTags);
                }else{
                    setTags([{
                        id: splitDesiredTags,
                        name: splitDesiredTags,
                    }]);
                }
            }else{
                setTags([]);
            }
        }else{
            setTags([]);
        }
    },[id])

    useEffect(() => {
        if (owner_id) {
            setOwnerStatus(true);
        } else {
            setOwnerStatus(false);
        }
    }, [owner_id]);

    const handleDeleteTag = (tag) => {
        const index = tags.findIndex(p => p.name == tag);
        const tempTags = [...tags];
        tempTags.splice(index, 1);
        setTags(tempTags);
        dispatch(removeTags({
            tags: tag,
            ticket_id: id
        }));
    }

    const handleAddTags = (newTag) => {
        if (newTag && newTag !== '') {
            setTags([...tags, {
                id: newTag,
                name: newTag,
            }]);
            dispatch(addTags({
                tags: newTag,
                ticket_id: id
            }));
        }else{
            const currentTag = popularTags[newTag];
            setTags([...tags, {
                id: currentTag.tag,
                name: currentTag.tag,
            }]);
            dispatch(addTags({
                tags: currentTag.tag,
                ticket_id: id
            }));
        }
    }

    const [children, setChildren] = useState([]);

    useEffect(()=>{
        if(popularTags){
            let tempChildren = [];
            let tempOptions = [];
            for (let i = 0; i < popularTags.length; i++) {
                tempChildren.push(<Option key={popularTags[i].tag}>{popularTags[i].tag}</Option>);
                tempOptions.push({value: popularTags[i].tag, label: popularTags[i].tag})
            }
            setChildren(tempChildren);
            setOptions(tempOptions);
        }
    },[popularTags])

    const customEmptyState = ()=>{
        return(
            <div style={{textAlign: 'center', fontSize: '14px'}}>
                <AiFillQuestionCircle size={30} />
                <p style={{marginTop: '2px'}}>No tags available</p>
                <p>Add it in <a href={`${process.env.REACT_APP_ORIGIN}/settings/#inboxSettings`} target='_blank'>Inbox Settings</a>.</p>
            </div>
        )
    }

    return (
        <StyledTagsInput>
            {ownerStatus ?
                isOwner ?
                    <Select
                        mode="multiple"
                        allowClear={true}
                        clearIcon={false}
                        style={{
                            minWidth: tags.length > 0 ? 'max-content' : '200px',
                            marginBottom: '6px',
                            width: '200px'
                        }}
                        popupMatchSelectWidth={false}
                        placeholder="+ Add Tags..."
                        value={tags.map(a=>a.name)}
                        placement='topRight'
                        onSelect={(e)=>handleAddTags(e)}
                        onDeselect={(e)=>handleDeleteTag(e)}
                        tokenSeparators={[',']}
                        options={options}
                        notFoundContent={customEmptyState()}
                        // disabled={expired}
                        >
                            {/* {children} */}
                    </Select>
                    : <StyledDisabledTag>
                        {
                            tags.map((tag, index) => {
                                return (
                                    <Tag key={index}>{tag.name}</Tag>
                                )
                            })
                        }
                    </StyledDisabledTag>
                : null
            }
        </StyledTagsInput>
    )
}

export default Tags;