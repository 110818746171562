import styled from "styled-components";

export const StyledTimeline = styled.div`
  position: relative;

  .timeline {
    background-color: rgb(206, 206, 206);
    height: 100%;
    position: relative;
  }

  .col-card-left {
    background-color: black;
    border-radius: 0.5rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 10px;
  }

  .col-note {
    color: #585f6d;
    font-size: 12px;
  }

  .col-card-title {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: #585f6d;
    padding: 1.1rem 0rem 0 0.2rem;
  }

  .col-card-title-left {
    text-align: right;
  }

  .col-card-title-right {
    text-align: left;

    :hover {
      text-decoration: underline;
      color: var(--toastify-color-info);
    }
  }

  .col-card-subtitle {
    color: #585f6d99;
    font-size: 12px;
    font-weight: 400;
    padding: 0.4rem 0rem 0 0.2rem;
  }

  .col-card-subtitle-right {
    text-align: left;
    text-transform: Capitalize;
    display: flex;
  }

  .col-note-left {
    text-align: right;
    margin: 0.8rem;
    margin-left: 0;
  }

  .col-card-url-right {
    text-align: left;
  }

  .col-card-url-left {
    text-align: right;
  }

  .circle {
    width: 14px;
    height: 14px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #16ac37;
    margin: 0 auto;
    z-index: 6;
    position: relative;
  }

  .icon {
    z-index: 5000;
    color: black;
    font-size: 0.75rem;
    margin: 0 auto;
    margin-top: 0.2rem;
    display: table;
    padding-top: 0.5rem;
  }

  .line {
    border-left: 1px dashed var(--color-primary);
    height: 100%;
    position: absolute;
    left: 4%;
    z-index: 1;
    top: 20px;
    bottom: 0;
  }

  .ticket-circle {
    border-radius: 50%;
    border: 1px solid var(--color-primary);
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clearfix {
    clear: both;
  }

  .column-left {
    float: left;
    width: 45%;
  }

  .column-right {
    float: right;
    width: 90%;
  }

  .column-center {
    display: inline-block;
    padding-top: 1rem;
    width: 10%;
  }
`;
