import React, { createRef, Fragment, useState, useEffect } from "react";
import { Button, Input } from "antd";
import {
  QuickReplyWrapper,
  TitleWrapper,
  HeaderWrapper,
  ContentWrapper,
  StyledReply,
} from "./styled";
import { ReactComponent as EditIcon } from "@assets/icons/quickreply/edit.svg";
import { ReactComponent as DeleteIcon } from "@assets/icons/quickreply/delete.svg";
import { addQuickReply, editQuickReply, deleteQuickReply } from "@utils/api";
import { useDispatch, useSelector } from "react-redux";
import { derivedAllQuickReplies } from "./utils";
import AddQuickReply from "./AddQuickReply";
import { customerDetailsDataSelector } from "@store-inbox/SummaryPanel/selector";
import { orderDataSelector } from "@store-inbox/SummaryPanel/selector";
import { ReactComponent as LeftArrowIcon } from "@assets/icons/quickreply/leftArrow.svg";
import { ReactComponent as Empty } from "@assets/icons/replynil.svg";
import { ReactComponent as MoreIcon } from "@assets/icons/message/more.svg";
import { conversationDataSelector } from "@store-inbox/ConversationPanel/selector";
import { toast } from "react-toastify";
import { ReactComponent as SearchIcon } from "@assets/icons/main/search.svg";
import { updateQuickReplies } from "@store-inbox/ConversationPanel/action";

function QuickReply(props) {
  const { openpanel, replyPanel, setReplyPanel, setInput } = props;

  const dispatch = useDispatch();

  const [addReply, setAddReply] = useState(false);
  const [text, setText] = useState("");
  const [shortcut, setShortcut] = useState("");
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [dropdownIcons, setDropdownIcons] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);

  const replies = useSelector(derivedAllQuickReplies);

  const [updatedReplies, setUpdatedReplies] = useState(replies);

  const customerData = useSelector(customerDetailsDataSelector);
  const orderData = useSelector(orderDataSelector);
  const conversation = useSelector(conversationDataSelector);

  toast.configure();

  const handleSendReply = (i) => {
    setInput(displayText(elRefs[i].current.textContent, variables));
    setReplyPanel(false);
  };

  useEffect(() => {
    setUpdatedReplies(replies);
  }, [replies]);

  function displayText(template, data) {
    const matcher = /{{(.*?)}}/g;

    return template.replace(matcher, (match) => {
      return data[match.split(/{{|}}/).filter(Boolean)[0].trim()];
    });
  }

  const arrLength = updatedReplies.length;
  const [elRefs, setElRefs] = React.useState([]);

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [arrLength]);

  const setNewReply = () => {
    const shortcuts = updatedReplies.map((a) => a.shortcut);
    setSaveLoader(true);
    if (shortcut.charAt(0) === "/") {
      if (!edit) {
        if (shortcuts.indexOf(shortcut) === -1) {
          addQuickReply({
            shortcut,
            message: text,
          })
            .then((resp) => {
              if (resp.data.success) {
                toast.success("Shortcut has been added successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
                dispatch(
                  updateQuickReplies([
                    ...updatedReplies,
                    {
                      id: resp.data.data.id,
                      shortcut: resp.data.data.shortcut,
                      message: resp.data.data.message,
                    },
                  ])
                );
                setUpdatedReplies([
                  ...updatedReplies,
                  {
                    id: resp.data.data.id,
                    shortcut: resp.data.data.shortcut,
                    message: resp.data.data.message,
                  },
                ]);
              } else {
                toast.error(resp.data.error.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
              }
            })
            .catch((err) => {
              toast.error(
                err.data && err.data.error
                  ? err.data.error.message
                  : "There has been an error. Please try again in some time.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                }
              );
            })
            .finally(() => {
              setAddReply(false);
              setSaveLoader(false);
            });
        } else {
          toast.error("This shortcut already exists.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setSaveLoader(false);
        }
      } else {
        editQuickReply({
          id: editId,
          shortcut,
          message: text,
        })
          .then((resp) => {
            if (resp.data.success) {
              toast.success("Shortcut has been edited successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
              });
              let replyIndex = updatedReplies.findIndex(
                (obj) => obj.id === resp.data.data.id
              );
              replies[replyIndex].shortcut = resp.data.data.shortcut;
              replies[replyIndex].message = resp.data.data.message;
            } else {
              toast.error(resp.data.error.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
              });
            }
          })
          .catch((err) => {
            toast.error(
              err.data && err.data.error
                ? err.data.error.message
                : "There has been an error. Please try again in some time.",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
              }
            );
          })
          .finally(() => {
            setAddReply(false);
            setSaveLoader(false);
            setEditId("");
          });
      }
    } else {
      toast.error("Add '/' at the beginning of the shortcut", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setSaveLoader(false);
    }
  };

  const deleteReply = (id) => {
    setDropdownIcons(false);
    deleteQuickReply({ id })
      .then((resp) => {
        if (resp.data.success) {
          toast.success("Shortcut has been deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setUpdatedReplies(updatedReplies.filter((a) => a.id !== id));
          dispatch(
            updateQuickReplies(updatedReplies.filter((a) => a.id !== id))
          );
        } else {
          toast.error(resp.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      })
      .catch(() => {
        toast.error("There has been an error. Please try again in some time.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      });
  };

  const editReply = (shortcut, message, id) => {
    setDropdownIcons(false);
    setAddReply(true);
    setShortcut(shortcut);
    setText(message);
    setEditId(id);
    setEdit(true);
  };

  const handleBack = () => {
    if (addReply) {
      setAddReply(false);
    } else {
      setReplyPanel(false);
    }
  };

  const variables = {
    userFirstName:
      customerData &&
      customerData.basic_info &&
      customerData.basic_info.first_name !== null
        ? customerData.basic_info.first_name
        : "NOT_FOUND",
    userName: conversation.data ? conversation.data.full_name : "NOT_FOUND",
    userEmail:
      customerData &&
      customerData.basic_info &&
      customerData.basic_info.email !== null
        ? customerData.basic_info.email
        : "NOT_FOUND",
    userPhone:
      customerData &&
      customerData.basic_info &&
      customerData.basic_info.phone !== null
        ? customerData.basic_info.phone
        : "NOT_FOUND",
    userAddress:
      customerData &&
      customerData.basic_info &&
      customerData.basic_info.address !== null
        ? customerData.basic_info.address
        : "NOT_FOUND",
    userAddressCity:
      customerData &&
      customerData.basic_info &&
      customerData.basic_info.city !== null
        ? customerData.basic_info.city
        : "NOT_FOUND",
    userAddressPinCode:
      customerData &&
      customerData.basic_info &&
      customerData.basic_info.pin !== null
        ? customerData.basic_info.pin
        : "NOT_FOUND",
    lastOrderNumber:
      orderData && orderData.orders && orderData.orders.length > 0
        ? orderData.orders[0].order_number
        : "NOT_FOUND",
    lastOrderPrice:
      orderData && orderData.orders && orderData.orders.length > 0
        ? orderData.orders[0].total_order_price
        : "NOT_FOUND",
    lastOrderTrackingUrl:
      orderData && orderData.orders && orderData.orders.length > 0
        ? orderData.orders[0].shipment_details.shipment_tracking_url
        : "NOT_FOUND",
    lastOrderAWB:
      orderData && orderData.orders && orderData.orders.length > 0
        ? orderData.orders[0].shipment_details.shipment_tracking_id
        : "NOT_FOUND",
    lastOrderShippingCompany:
      orderData && orderData.orders && orderData.orders.length > 0
        ? orderData.orders[0].shipment_details.shipment_company
        : "NOT_FOUND",
    lastOrderShipmentStatus:
      orderData && orderData.orders && orderData.orders.length > 0
        ? orderData.orders[0].shipment_details.fulfillment_status
        : "NOT_FOUND",
    lastOrderProducts:
      orderData && orderData.orders && orderData.orders.length > 0
        ? orderData.orders[0].line_items
            .map((el) => {
              return el.name;
            })
            .join(", ")
        : "NOT_FOUND",
  };

  return (
    <QuickReplyWrapper
      replypanel={replyPanel}
      style={openpanel ? { marginRight: "0px" } : { marginRight: "12%" }}
    >
      <HeaderWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              fontSize: "24px",
              marginRight: "16px",
              color: "#474747",
              marginTop: "0px",
              cursor: "pointer",
            }}
            onClick={() => handleBack()}
          >
            {" "}
            <LeftArrowIcon />{" "}
          </span>
          <TitleWrapper>
            {addReply ? "Add Quick Replies" : "Quick Replies"}
          </TitleWrapper>
        </div>
        {!addReply ? (
          <p
            style={{
              margin: "0",
              textDecoration: "underline",
              color: "#474747",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              setAddReply(true);
              setShortcut("");
              setText("");
              setEdit(false);
            }}
          >
            Add quick reply
          </p>
        ) : (
          ""
        )}
      </HeaderWrapper>

      <ContentWrapper>
        {!addReply ? (
          <Fragment>
            <div style={{ position: "relative" }}>
              <Input
                type="text"
                placeholder="Search quick reply"
                style={{
                  width: "100%",
                  height: "60px",
                  background: "#fff",
                  padding: "20px",
                  fontSize: "18px",
                  border: "none",
                  marginBottom: "20px",
                }}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <SearchIcon
                style={{
                  stroke: "#1d8dcc",
                  position: "absolute",
                  right: "20px",
                  top: "18px",
                  cursor: "pointer",
                }}
              />
            </div>
            {updatedReplies.length > 0 ? (
              updatedReplies
                .filter((reply) => {
                  if (searchQuery == "") {
                    return reply;
                  } else if (
                    reply.shortcut
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ) {
                    return reply;
                  }
                })
                .map((reply, i) => {
                  return (
                    <StyledReply key={i}>
                      <div>
                        <p
                          style={{
                            fontSize: "20px",
                            color: "#474747",
                            fontWeight: "200",
                            margin: "0 0 10px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "20px",
                              color: "#474747",
                              fontWeight: "700",
                            }}
                          >
                            {reply.shortcut}
                          </span>{" "}
                          - Shortcut
                        </p>
                        <p
                          ref={elRefs[i]}
                          style={{
                            color: "#6f6f6f",
                            fontSize: "16px",
                            lineHeight: "19px",
                          }}
                        >
                          {reply.message}
                        </p>
                      </div>
                      <div className="hover-icons">
                        <Button
                          className="send-button"
                          onClick={() => handleSendReply(i)}
                          variant="contained"
                        >
                          Send
                        </Button>
                        <MoreIcon
                          className="more-button"
                          style={{ cursor: "pointer" }}
                          onClick={() => setDropdownIcons(!dropdownIcons)}
                        />
                        {dropdownIcons && (
                          <div
                            className="dropdown-icons"
                            onMouseLeave={() => setDropdownIcons(false)}
                          >
                            <div
                              className="icons"
                              onClick={() =>
                                editReply(
                                  reply.shortcut,
                                  reply.message,
                                  reply.id
                                )
                              }
                            >
                              <EditIcon className="icon" />
                              <span>Edit</span>
                            </div>
                            <div
                              className="icons"
                              onClick={() => deleteReply(reply.id)}
                            >
                              <DeleteIcon className="icon" />
                              <span>Delete</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </StyledReply>
                  );
                })
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "80px",
                  color: "#474747",
                }}
              >
                <Empty />
                <p
                  style={{
                    fontSize: "32px",
                    fontWeight: "600",
                    margin: "20px 0px",
                  }}
                >
                  Hmmm. Nothing created yet
                </p>
                <Button
                  style={{
                    background: "var(--color-primary)",
                    color: "#fff",
                    height: "60px",
                    borderRadius: "4px",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setAddReply(true);
                    setEdit(false);
                  }}
                >
                  Add Quick Reply
                </Button>
              </div>
            )}
          </Fragment>
        ) : (
          <AddQuickReply
            shortcut={shortcut}
            text={text}
            setText={setText}
            setNewReply={setNewReply}
            setShortcut={setShortcut}
            saveLoader={saveLoader}
          />
        )}
      </ContentWrapper>
    </QuickReplyWrapper>
  );
}

export default QuickReply;
