import { put, select, all, takeLatest, call } from "@redux-saga/core/effects";
import * as selectors from "./selector";
import * as mainSelector from "../MainPanel/selector";
import * as types from "./types";
import * as api from "@utils/api";
import * as actions from "./action";
import * as mainActions from "../MainPanel/action";
import { getPopularTags } from "@store-inbox/MainPanel/action";
import { resolveErrorCodes } from "@utils/errorCodes";
import { toast } from "react-toastify";

toast.configure();

function* init() {
  yield all([put({ type: types.INIT })]);
}

function* getConversationSaga(action) {
  const { payload } = action;
  try {
    if (payload) {
      const conversationData = yield call(api.getTicketContentApi, {
        ticket_id: payload,
      });
      yield put(actions.receiveConversation(conversationData.data));
    }
  } catch (err) {
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    toast.error(
      "Internal Server error. Please refresh the page and try again",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
  }
}

function* redirectIfSubscriber({ payload }) {
  const {
    currentUserId,
    data: { subscriber },
  } = payload;
  if (currentUserId == subscriber) {
    yield window.location.replace(`/inbox/tickets`);
  }
}

function* setFileUpload(action) {
  const { payload } = action;
  try {
    const currentConversationId = yield select(selectors.derivedConversationId);
    const fileData = yield call(api.uploadConversationFiles, {
      ...payload,
      conversation_id: currentConversationId,
    });
    yield put({
      type: types.SET_FILE_UPLOAD_SUCCESS,
      payload: {
        currentConversationId,
        data: fileData.data.data,
      },
    });
  } catch (err) {
    yield put({
      type: types.SET_FILE_UPLOAD_ERROR,
      payload: err,
    });
  }
}

function* setEmailUpload(action) {
  const { payload } = action;
  try {
    const fileData = yield call(api.uploadEmailFiles, { payload });
    if (payload.onSuccess) {
      payload.onSuccess(fileData);
    }
    yield put({
      type: types.SET_EMAIL_UPLOAD_SUCCESS,
      payload: {
        data: fileData.data.data,
      },
    });
  } catch (err) {
    if (payload.onFailure) {
      payload.onFailure(err);
    }
    yield put({
      type: types.SET_EMAIL_UPLOAD_ERROR,
      payload: err,
    });
  }
}

function* getAllQuickRepliesSaga(action) {
  try {
    const quickReplies = yield call(api.getAllQuickReplies);
    yield put({
      type: types.GET_QUICK_REPLIES_SUCCESS,
      payload: quickReplies.data.data,
    });
  } catch (err) {
    // resolveErrorCodes(err);
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    toast.error(
      "Internal Server error. Please refresh the page and try again",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
    yield put({
      type: types.GET_QUICK_REPLIES_ERROR,
      payload: err,
    });
  }
}

function* writeNewMessage(action) {
  try {
    const ticketsData = yield call(api.sendMessageApi, {
      payload: action.payload,
    });
    const currentConversationId = yield select(selectors.derivedConversationId);
    yield put(
      actions.updateMessageSent({
        data: ticketsData.data.data,
        currentConversationId,
        success: ticketsData.data.success,
        error: ticketsData.data.error,
      })
    );
  } catch (err) {
    if (err.response && err.response.data) {
      api.errorLogsApi(err.response.data);
    } else {
      api.errorLogsApi(err);
    }
    const currentConversationId = yield select(selectors.derivedConversationId);
    yield put(
      actions.updateMessageSent({
        data: [],
        currentConversationId,
        success: false,
        error: err,
      })
    );
  }
}

function* changeOwnership(action) {
  try {
    const updateOwnership = yield call(api.transferOwnershipApi, {
      payload: action.payload,
    });
    const currentConversationId = yield select(
      selectors.currentActiveConversationSelector
    );
    const settings = yield select(mainSelector.connectionsSelector);
    const currentOwnerId = settings.response.current_user_id;
    yield put(
      actions.updateOwnershipChange({
        data: updateOwnership.data.data,
        currentConversationId,
        currentOwnerId,
      })
    );
    yield put({
      type: types.UPDATE_STATUS,
      payload: "closed"
    })
  } catch (err) {
    yield put({
      type: types.CHANGE_OWNERSHIP_ERROR,
      payload: err,
    });
  }
}

function* setStatus(action) {
  try {
    const updatedStatus = yield call(api.setStatusApi, {
      payload: action.payload,
    });
    yield put(mainActions.setSearchFilters());
  } catch (err) {
    yield put({
      type: types.STATUS_UPDATE_ERROR,
      payload: err,
    });
  }
}

function* setFollowUpSaga(action) {
  try {
    const updatedResponse = yield call(api.setFollowUpApi, {
      payload: action.payload,
    });
    yield put({
      type: types.UPDATE_STATUS,
      payload: "follow_up"
    })
    // yield put(actions.setActiveConversationBG(action.payload.ticket_id));
  } catch (err) {
    console.log(err);
  }
}

function* writeAddTags(action) {
  try {
    const updatedResponse = yield call(api.addTagsApi, {
      payload: action.payload,
    });
    const currentConversationId = yield select(
      selectors.currentActiveConversationSelector
    );
    yield put(
      actions.getTagsAdded({
        data: updatedResponse.data.data,
        currentConversationId,
      })
    );
  } catch (err) {
    console.log(err);
  }
}

function* writeRemoveTags(action) {
  try {
    const updatedResponse = yield call(api.removeTagsApi, {
      payload: action.payload,
    });
    const currentConversationId = yield select(
      selectors.currentActiveConversationSelector
    );
    yield put(
      actions.getTagsRemoved({
        data: updatedResponse.data.data,
        currentConversationId,
      })
    );
  } catch (err) {
    console.log(err);
  }
}

function* writeNewComments(action) {
  try {
    const data = yield call(api.sendCommentApi, { payload: action.payload });
    const currentConversationId = yield select(
      selectors.currentActiveConversationSelector
    );
    yield put(
      actions.commentCreated({ data: data.data, currentConversationId })
    );
  } catch (err) {
    console.log(err);
  }
}

function* deleteComments(action) {
  try {
    const currentConversationId = yield select(selectors.derivedConversationId);
    const apiData = {
      conversation_id: currentConversationId,
      ...action.payload,
    };
    const data = yield call(api.deleteCommentApi, apiData);
    yield put(
      actions.commentDeleted({ data: data.data, currentConversationId })
    );
  } catch (err) {
    console.log(err);
  }
}

function* watchActiveConversationLoad() {
  yield takeLatest([types.SET_ACTIVE_CONVERSATION_ID], getConversationSaga);
}

function* watchActiveConversationLoadBG() {
  yield takeLatest([types.SET_ACTIVE_CONVERSATION_ID_BG], getConversationSaga);
}

function* setPopularTags(action) {
  yield put(getPopularTags());
}

function* watchTagUpdate() {
  yield takeLatest([types.ADD_TAGS_FEEDBACK], setPopularTags);
  yield takeLatest([types.REMOVE_TAGS_FEEDBACK], setPopularTags);
}

function* watchFileUpload() {
  yield takeLatest([types.SET_FILE_UPLOAD], setFileUpload);
}

function* watchEmailUpload() {
  yield takeLatest([types.SET_EMAIL_UPLOAD], setEmailUpload);
}

function* watchSubscriberRemoveUpdate() {
  yield takeLatest([types.REMOVE_SUBSCRIBER_FEEDBACK], redirectIfSubscriber);
}

function* watchGetAllQuickReplies() {
  yield takeLatest([types.GET_QUICK_REPLIES], getAllQuickRepliesSaga);
}

function* watchSendMessage() {
  yield takeLatest(types.SEND_MESSAGE, writeNewMessage);
}

function* watchChangeOwnership() {
  yield takeLatest(types.CHANGE_OWNERSHIP, changeOwnership);
}

function* watchSetStatus() {
  yield takeLatest(types.SET_STATUS, setStatus);
}

function* watchFollowup() {
  yield takeLatest(types.SET_FOLLOW_UP, setFollowUpSaga);
}

function* watchAddTags() {
  yield takeLatest(types.ADD_TAGS, writeAddTags);
}

function* watchRemoveTags() {
  yield takeLatest(types.REMOVE_TAGS, writeRemoveTags);
}

function* watchSendComments() {
  yield takeLatest(types.REPLY_TO_COMMENT, writeNewComments);
}

function* watchDeleteComments() {
  yield takeLatest(types.DELETE_COMMENT, deleteComments);
}

export default [
  watchActiveConversationLoad,
  watchActiveConversationLoadBG,
  watchFileUpload,
  watchTagUpdate,
  watchSubscriberRemoveUpdate,
  watchGetAllQuickReplies,
  watchEmailUpload,
  watchSendMessage,
  watchChangeOwnership,
  watchSetStatus,
  watchFollowup,
  watchAddTags,
  watchRemoveTags,
  watchSendComments,
  watchDeleteComments,
];
