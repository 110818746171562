import styled from 'styled-components'

export const AudioContainer = styled.div`
display: flex;
width: 340px;
padding: ${props=> props.actor === "merchant" ? '0px' : '0px 15px'};
align-items: center;
border-radius: 10px;

.progressbar{
    --bar-bg: #1D8DCC;
    --seek-before-width: 0;
    --seek-before-color: #ff0000;
    --knobby: #1D8DCC;
    --selected-knobby: var(--color-primary); 

    appearance: none;
    background: #d5d5d5;
    border-radius: 10px;
    height: 3px;
    position: relative;
    width: 100%:
    outline: none;
    margin: 4px 8px;

    &::before {
        content: '';
        height: 3px;
        width: var(--seek-before-width);
        background: var(--bar-bg);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &::--webkit-slider-runnable-track,--moz-range-track{
        background: var(--color-icon-background);
        border-radius: 10px;
        position: relative;
        width: 100%:
        outline: none;
    }

    &::-moz-range-progress{
        height: 3px;
        width: var(--seek-before-width);
        background: #1D8DCC;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    &::-webkit-slider-thumb{
        --webkit-appearance: none;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        border:none;
        background-color: var(--knobby);
        cursor: pointer;
        position: relative;
        margin: -2px 0 0 0;
        z-index: 3;
        box-sizing: border-box;
    }

    &:active::-webkit-slider-thumb{
        transform: scale(1.2);
        background: var(--selected-knobby);
    }

    &::-moz-range-thumb{
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: transparent;
        background-color: var(--knobby);
        cursor: pointer;
        position: relative;
        z-index: 3;
        box-sizing: border-box;
    }

    &:active::-moz-range-thumb{
        transform: scale(1.2);
        background: var(--selected-knobby);
    }
}
`

export const AudioButton = styled.button`
border: none;
font-size: 20px;
background: transparent;
color: var(--color-primary);
display: flex;
align-items: center;
`

export const AudioBox = styled.div`
padding: 20px 0;
display: flex;
align-items: center;
`

export const AudioSymbol = styled.div`
background: #1D8DCC;
padding: 10px;
margin-right: 4px;
border-radius: 4px;
`