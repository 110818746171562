import * as types from "./types";

export const receiveConversation = (data) => ({
  type: types.RECEIVE_CONVERSATION,
  payload: data,
});

export const getConversation = (data) => ({
  type: types.GET_CONVERSATION,
  payload: data,
});

export const receiveNewMessage = (payload) => ({
  type: types.NEW_MESSAGE,
  payload: payload,
});

export const setActiveConversation = (id) => ({
  type: types.SET_ACTIVE_CONVERSATION_ID,
  payload: id,
});

export const setActiveID = (id) => ({
  type: types.ACTIVE_ID,
  payload: id,
});

export const sendMessage = (payload) => ({
  type: types.SEND_MESSAGE,
  payload: payload,
});

export const replyToComment = (payload) => ({
  type: types.REPLY_TO_COMMENT,
  payload: payload,
});

export const deleteComment = (payload) => ({
  type: types.DELETE_COMMENT,
  payload: payload,
});

export const commentCreated = (payload) => ({
  type: types.COMMENT_CREATED,
  payload: payload,
});

export const commentDeleted = (payload) => ({
  type: types.COMMENT_DELETED,
  payload: payload,
});

export const newComment = (payload) => ({
  type: types.NEW_COMMENT,
  payload: payload,
});

export const addTags = (payload) => ({
  type: types.ADD_TAGS,
  payload: payload,
});

export const removeTags = (payload) => ({
  type: types.REMOVE_TAGS,
  payload: payload,
});

export const getTagsAdded = (payload) => ({
  type: types.ADD_TAGS_FEEDBACK,
  payload: payload,
});

export const getTagsRemoved = (payload) => ({
  type: types.REMOVE_TAGS_FEEDBACK,
  payload: payload,
});

export const setStatus = (payload) => ({
  type: types.SET_STATUS,
  payload: payload,
});

export const getStatus = (payload) => ({
  type: types.GET_STATUS,
  payload: payload,
});

export const setPriority = (payload) => ({
  type: types.SET_PRIORITY,
  payload: payload,
});

export const getPriority = (payload) => ({
  type: types.GET_PRIORITY,
  payload: payload,
});

export const addSubscriber = (payload) => ({
  type: types.ADD_SUBSCRIBER,
  payload: payload,
});

export const removeSubscriber = (payload) => ({
  type: types.REMOVE_SUBSCRIBER,
  payload: payload,
});

export const getSubscriberAdded = (payload) => ({
  type: types.ADD_SUBSCRIBER_FEEDBACK,
  payload: payload,
});

export const getSubscriberRemoved = (payload) => ({
  type: types.REMOVE_SUBSCRIBER_FEEDBACK,
  payload: payload,
});

export const setUploadFile = (payload) => ({
  type: types.SET_FILE_UPLOAD,
  payload: payload,
});

export const updateMessageDelivered = (payload) => ({
  type: types.UPDATE_MESSAGE_DELIVERED,
  payload: payload,
});

export const updateMessageRead = (payload) => ({
  type: types.UPDATE_MESSAGE_READ,
  payload: payload,
});

export const updateMessageSent = (payload) => ({
  type: types.UPDATE_MESSAGE_SENT,
  payload: payload,
});

export const setOwnershipToMe = (payload) => ({
  type: types.SET_OWNERSHIP_TO_ME,
  payload: payload,
});

export const updateOwnershipChange = (payload) => ({
  type: types.UPDATE_OWNERSHIP_CHANGE,
  payload: payload,
});

export const changeOwnership = (payload) => ({
  type: types.CHANGE_OWNERSHIP,
  payload: payload,
});

export const updateDeletedMessage = (payload) => ({
  type: types.MESSAGE_DELETED,
  payload: payload,
});

export const getAllQuickReplies = (payload) => ({
  type: types.GET_QUICK_REPLIES,
  payload,
});

export const updateQuickReplies = (payload) => ({
  type: types.UPDATE_QUICK_REPLIES,
  payload,
});

export const setEmailUploadFile = (payload) => ({
  type: types.SET_EMAIL_UPLOAD,
  payload: payload,
});

export const updateMessageFailed = (payload) => ({
  type: types.MESSAGE_FAILED,
  payload,
});

export const setActiveConversationBG = (id) => ({
  type: types.SET_ACTIVE_CONVERSATION_ID_BG,
  payload: id,
});

export const setFollowUp = (payload) => ({
  type: types.SET_FOLLOW_UP,
  payload,
});

export const resetAttachmentData = () => ({
  type: types.RESET_ATTACHMENT_DATA,
});
