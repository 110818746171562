import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    height: 100vh;
    background: #fff;
    overflow: hidden;
`;

export const SideIcon = styled.div`
    background:#F5E2E5;
    right: 0;
    width: 50px;
    position:absolute;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    font-size: 15px;
    color: #F44336;
    font-weight: 700;
`