import React, { useState } from "react";
import { Button, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";

function AddQuickReply(props) {
  const { Option } = Select;
  const { setShortcut, text, setText, setNewReply, shortcut, saveLoader } =
    props;
  const [curPos, setCurPos] = useState(null);

  const addText = (e) => {
    setText(text.slice(0, curPos) + e + text.slice(curPos));
  };

  return (
    <div>
      <p style={{ color: "#474747", fontSize: "20px", fontWeight: "200" }}>
        Shortcut
      </p>
      <input
        value={shortcut}
        onChange={(e) => setShortcut(e.target.value)}
        type="text"
        placeholder="/ + For eg. U"
        style={{
          width: "100%",
          height: "60px",
          background: "#fff",
          padding: "20px",
          fontSize: "18px",
          border: "none",
        }}
      />
      <p style={{ fontSize: "16px", color: "#959595", margin: "10px 0" }}>
        Add a keyboard shortcut. The message corresponding to this shortcut will
        appear when this combination is the typed first.
      </p>
      <p style={{ fontSize: "20px", color: "#474747", fontWeight: "200" }}>
        Message
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Select
          placeholder="Customer details"
          style={{ width: 180, fontSize: "16px" }}
          size="large"
          onSelect={addText}
        >
          <Option value="{{userFirstName}}">userFirstName</Option>
          <Option value="{{userName}}">userName</Option>
          <Option value="{{userEmail}}">userEmail</Option>
          <Option value="{{userPhone}}">userPhone</Option>
          <Option value="{{userAddress}}">userAddress</Option>
          <Option value="{{userAddressCity}}">userAddressCity</Option>
          <Option value="{{userAddressPinCode}}">userAddressPinCode</Option>
        </Select>

        <Select
          placeholder="Order details"
          style={{ width: 200, fontSize: "16px", marginLeft: "20px" }}
          size="large"
          onSelect={addText}
        >
          <Option value="{{lastOrderNumber}}">lastOrderNumber</Option>
          <Option value="{{lastOrderPrice}}">lastOrderPrice</Option>
          <Option value="{{lastOrderTrackingUrl}}">lastOrderTrackingUrl</Option>
          <Option value="{{lastOrderAWB}}">lastOrderAWB</Option>
          <Option value="{{lastOrderShippingCompany}}">
            lastOrderShippingCompany
          </Option>
          <Option value="{{lastOrderShipmentStatus}}">
            lastOrderShipmentStatus
          </Option>
          <Option value="{{lastOrderProducts}}">lastOrderProducts</Option>
        </Select>
      </div>

      <TextArea
        onClick={(e) => setCurPos(e.target.selectionStart)}
        value={text}
        type="text"
        onChange={(e) => {
          setText(e.target.value);
          setCurPos(e.target.selectionStart);
        }}
        placeholder="Type a quick reply message"
        style={{
          width: "100%",
          height: "150px",
          background: "#fff",
          padding: "15px 20px",
          fontSize: "18px",
          border: "none",
          marginTop: "40px",
        }}
      />

      <Button
        onClick={() => setNewReply()}
        disabled={saveLoader}
        style={{
          height: "60px",
          borderRadius: "4px",
          fontSize: "20px",
          marginLeft: "auto",
          marginTop: "40px",
          display: "block",
        }}
        type="primary"
        variant="contained"
      >
        {saveLoader ? "Saving..." : "Save quick reply"}
      </Button>
    </div>
  );
}

export default AddQuickReply;
