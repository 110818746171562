import styled from 'styled-components';

export const QuickReplyWrapper = styled.div`
	width: 100%;
	flex: 2;
	margin: 36px 20px;
	border: 1px solid #d5d5d5;
	padding: 20px 0;
	display: ${(props)=>props.replypanel ? 'block': 'none'};
	max-height: 90vh;
`

export const HeaderWrapper =styled.div`
	display: flex;
	padding: 0px 20px 15px;
	border-bottom: 1px solid #D5D5D5;
	align-items: center;
	justify-content: space-between;
`

export const TitleWrapper = styled.p`
	margin: 0;
	font-weight: 600;
	color: #474747;
	font-size: 24px;
`

export const ContentWrapper = styled.div`
	background: #1D8DCC1A;
	padding: 20px;
	height: 96%;
	overflow-y: scroll;
`

export const StyledReply = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;

	.hover-icons{
		opacity: 0;
		position: relative;
		display: flex;
		align-items: center;
	}

	.send-button{
		background: #1D8DCC;
		color: #fff;
		height: 50px;
		width: 100px;
		border-radius: 4px;
		font-size: 20px;
	}

	:hover{
		background: #fff;
		.hover-icons{
			opacity:1;
		}
	}

	.dropdown-icons{
		position: absolute;
		background: white;
		top: 50px;
		width: 150px;
		text-align: center;
		padding: 10px 0;
		right: -10px;
		z-index: 10;
		box-shadow: 0px 3px 24px 8px rgba(29, 141, 204, 0.08);
		border-radius: 6px;
	}

	.more-button{
		width: 50px;
		height: 50px;
		margin: 0px 10px;
		:hover{
			background: #1D8DCC1A;
			border-radius: 4px;
		}
	}

	.icons{
		display: flex;
		align-items: center;
		cursor: pointer;
		:hover{
			background:  #F0F2F6;
		}

		span{
			color: #333333;
			font-size: 18px;
		}
	}
	}

	.icon{
		width: 60px;
		height: 60px;
		padding: 18px;
	}
`