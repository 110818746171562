import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  linkedTicketsDataSelector,
  linkedTicketsLoaderSelector,
  linkedTicketsErrorSelector,
  linkedTicketsApiHitSelector,
} from "@store-inbox/SummaryPanel/selector";
import { Link } from "react-router-dom";
import { setActiveConversation } from "@store-inbox/ConversationPanel/action";
import { Button, Input } from "antd";
import { toast } from "react-toastify";
import { postLinkedTickets } from "@store-inbox/SummaryPanel/action";
import { currentActiveConversationSelector } from "@store-inbox/ConversationPanel/selector";

function LinkTickets() {
  const data = useSelector(linkedTicketsDataSelector);
  const linkTicketsLoader = useSelector(linkedTicketsLoaderSelector);
  const error = useSelector(linkedTicketsErrorSelector);
  const ticketId = useSelector(currentActiveConversationSelector);
  const apiHit = useSelector(linkedTicketsApiHitSelector);

  const [ticketIdToLink, setTicketIdToLink] = useState("");
  const [linkedIds, setLinkedIds] = useState({ linked_ticket_ids: [] });

  const dispatch = useDispatch();

  toast.configure();

  const addTicketId = () => {
    if (ticketId !== "") {
      dispatch(
        postLinkedTickets({
          ticketId,
          formDataToSend: { link_ticket_id: ticketIdToLink },
        })
      );
    } else {
      toast.error("Enter ticket id", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  useEffect(() => {
    setLinkedIds(data);
  }, [data]);

  useEffect(() => {
    if (error == null && !linkTicketsLoader && apiHit) {
      toast.success("Tickets have been linked", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      const newLinkedTicketIds = [
        ...linkedIds.linked_ticket_ids,
        ticketIdToLink,
      ];

      // Update the state with the new array
      setLinkedIds({ linked_ticket_ids: newLinkedTicketIds });
      setTicketIdToLink("");
    } else if (error && error !== null) {
      toast.error("ERROR! Could not add ticket id", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  }, [error, linkTicketsLoader, apiHit]);

  return (
    <div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <Input
          placeholder="Add ticket id"
          onChange={(e) => setTicketIdToLink(e.target.value)}
        />
        <Button
          onClick={() => addTicketId()}
          type="primary"
          style={{ marginLeft: "10px" }}
          disabled={linkTicketsLoader}
        >
          {linkTicketsLoader ? "Adding.." : "+ Add"}
        </Button>
      </div>
      {linkedIds &&
        linkedIds.linked_ticket_ids &&
        linkedIds.linked_ticket_ids.length > 0 && (
          <div>
            <p style={{ fontSize: "14px", color: "#333" }}>
              The following tickets are linked:{" "}
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              {linkedIds.linked_ticket_ids.map((id) => {
                return (
                  <Link key={id} to={`/inbox/tickets/${id}`}>
                    <p
                      onClick={() => dispatch(setActiveConversation(id))}
                      style={{
                        background: "rgba(29, 141, 204, 0.1)",
                        fontSize: "13px",
                        width: "max-content",
                        padding: "2px 6px",
                        borderRadius: "10px",
                        margin: "5px",
                      }}
                    >
                      #{id}
                    </p>
                  </Link>
                );
              })}
            </div>
          </div>
        )}
    </div>
  );
}

export default LinkTickets;
