import { SCOPE } from "./types";
import get from "lodash/get";
import { createSelector } from "reselect";

export const selector = (state) => get(state, SCOPE);

export const conversationsDataSelector = createSelector(
  selector,
  (store) => store.tickets
);

export const loadingSelector = createSelector(
  selector,
  (store) => store.loading
);

export const payloadSelector = createSelector(
  selector,
  (store) => store.payload
);

export const agentsSelector = createSelector(selector, (store) => store.agents);

export const connectionsSelector = createSelector(
  selector,
  (store) => store.connections
);

export const ticketListApiSelector = createSelector(
  selector,
  (store) => store.data
);

export const templatesSelector = createSelector(
  selector,
  (store) => store.templates
);

export const templatesLoadingSelector = createSelector(
  selector,
  (store) => store.templates.loading
);

export const initiationSelector = createSelector(
  selector,
  (store) => store.initiation
);

export const msgsCount = createSelector(selector, (store) => store.msgsCount);

export const onlineStatusDataSelector = createSelector(
  selector,
  (store) => store.onlineStatus
);

export const popularTagsDataSelector = createSelector(
  selector,
  (store) => store.popularTags
);

export const refreshStatusSelector = createSelector(
  selector,
  (store) => store.refreshStatus
);

export const ticketCreateStatusSelector = createSelector(
  selector,
  (store) => store.ticket_create_status
);

export const fieldsSelector = createSelector(selector, (store) => store.fields);

export const emailGroupsSelector = createSelector(
  selector,
  (store) => store.emailGroups.response
);

export const initiateMailSelector = createSelector(
  selector,
  (store) => store.initiateMail
);