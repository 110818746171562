import React, {Fragment} from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { Time } from './index';
import { ReactComponent as BotIcon } from '@assets/icons/message/chatbot.svg';
import { ReactComponent as SentIcon } from '@assets/icons/message/sent.svg';
import { ReactComponent as SeenIcon } from '@assets/icons/message/seen.svg';
import { ReactComponent as DeliveredIcon } from '@assets/icons/message/newdelivered.svg';
import { StyledMeta, StyledParserMessage } from './styled';

const MessageStringParser = ({ message }) => {
    const handleClick = (e) => {
        //console.log(e.target.innerText);
    }
    const newElements = [];
    const parts = message.split(/button \d \(([^)]+)\)/gi);
    const msgText =  parts[0];
    for (let i = 1; i < parts.length; i += 2) {
        if (parts[i]) {
            newElements.push(<Button className="message-button" key={i} onClick={handleClick}>{parts[i]}</Button>);
        }
    }
    return (
        <div>
            <span>{msgText}</span>
            <div className="buttons-container">{newElements}</div>
        </div>);
};

function Bot(props) {
    const { message_type, actor, message, created_at, attachment, delivered, read } = props.data;
    return (
        <div className="message__row">
            <span className={`message message--${actor}`}>
                <div className="actual-message">
                    <StyledParserMessage><MessageStringParser message={message} /></StyledParserMessage>
                </div>
                <StyledMeta actor={actor}>
                    <div style={{display: 'flex',alignItems:'center'}}>
                        <Icon component={BotIcon} style={{margin: '5px 10px 8px 10px',fill: 'none'}} />
                        <Time created_at={created_at} />
                    </div>
                    <Fragment>
                        {delivered ? null : <div style={{display: 'flex',alignItems: 'center'}}><Icon component={SentIcon} className="icon-read" /> <span style={{color: '#6F6F6F',fontSize: '12px',padding: '0px 5px'}}> Sent </span> </div>}
                        {(delivered && !read) ? <div style={{display: 'flex',alignItems: 'center'}}><Icon component={DeliveredIcon} className="icon-read" /><span style={{color: '#6F6F6F',fontSize: '12px',padding: '0px 5px'}}> Delivered</span></div>: null}
                        {read ? <div style={{display: 'flex',alignItems: 'center'}}><Icon component={SeenIcon} /><span style={{color:'#16AC37',padding:'0 5px',fontSize: '12px'}}>Seen</span></div> : null}
                    </Fragment>
                </StyledMeta>
            </span>
        </div>
    );
}

export default Bot;