import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Select } from "antd";
import {
  labelsDataSelector,
  labelsLoaderSelector,
  labelsErrorSelector,
} from "@store-inbox/SummaryPanel/selector";
import { updateLabelValues } from "@store-inbox/SummaryPanel/action";
import { fieldsSelector } from "@store-inbox/MainPanel/selector";
import { currentActiveConversationSelector } from "@store-inbox/ConversationPanel/selector";
import { toast } from "react-toastify";

function Labels() {
  const { Option } = Select;

  const dispatch = useDispatch();

  const fieldValuesData = useSelector(labelsDataSelector);
  const fields = useSelector(fieldsSelector);
  const ticketId = useSelector(currentActiveConversationSelector);
  const labelsLoader = useSelector(labelsLoaderSelector);
  const labelsError = useSelector(labelsErrorSelector);

  const [labelsUpdating, setLabelsUpdating] = useState(false);
  const [updateFieldValues, setUpdateFieldValues] = useState({});

  const inputValuesRef = useRef({});

  const extractFieldIds = (fields) => {
    const result = [];

    fields.forEach((field) => {
      // Extract the id and is_required for the current field
      result.push({
        id: field.id,
        is_required: field.is_required,
        field_name: field.field_name,
      });

      // If the field has nested pre_defined_options with sub_fields, we need to handle them recursively
      if (field.pre_defined_options) {
        field.pre_defined_options.forEach((option) => {
          if (option.sub_fields) {
            // Recursively extract ids and is_required from sub_fields
            result.push(...extractFieldIds(option.sub_fields));
          }
        });
      }
    });

    return result;
  };

  useEffect(() => {
    const initializeFieldValues = (fields) => {
      const initialMultiFieldValues = {};
      const stack = [...(fields.response || [])];

      while (stack.length > 0) {
        const field = stack.pop();

        if (field.field_type === "single_input" && !field.is_fixed_values) {
          inputValuesRef.current[field.id] = fieldValuesData[field.id] || "";
        } else {
          initialMultiFieldValues[field.id] = fieldValuesData[field.id] || [];
        }

        // Add sub_fields of pre_defined_options to the stack
        if (field.pre_defined_options?.length > 0) {
          field.pre_defined_options.forEach((option) => {
            if (option.sub_fields?.length > 0) {
              stack.push(...option.sub_fields);
            }
          });
        }
      }

      return initialMultiFieldValues;
    };

    if (fields && fields.response && fields.response.length > 0) {
      const initialMultiFieldValues = initializeFieldValues(fields);
      setUpdateFieldValues(initialMultiFieldValues);
    }
  }, [fieldValuesData, fields]);

  const updateLabels = (e) => {
    e.preventDefault();
    setLabelsUpdating(true);
    const formDataToSend = { ...updateFieldValues, ...inputValuesRef.current };

    const fieldElements = document.querySelectorAll(
      "#customFields div[data-field-id]"
    );
    const ids = Array.from(fieldElements).map((element) =>
      element.getAttribute("data-field-id")
    );

    const extractFieldData = extractFieldIds(fields.response);

    const commonIds = extractFieldData.filter((field) =>
      ids.includes(String(field.id))
    );

    const requiredFields = commonIds.filter((field) => field.is_required);

    const missingFieldNames = requiredFields
      .filter(
        (field) =>
          !formDataToSend[field.id] || formDataToSend[field.id].length === 0
      )
      .map((field) => field.field_name);

    if (missingFieldNames.length > 0) {
      const uniqueMissingFields = [...new Set(missingFieldNames)];
      return toast.error(`Please fill out ${uniqueMissingFields.join(", ")}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }

    const filteredFormValues = Object.keys(formDataToSend)
      .filter((key) => ids.includes(key)) // Keep only keys that exist in the ids list
      .reduce((obj, key) => {
        obj[key] = formDataToSend[key]; // Rebuild the object with the filtered keys
        return obj;
      }, {});

    dispatch(
      updateLabelValues({
        ticketId,
        formDataToSend: filteredFormValues,
      })
    );
  };

  useEffect(() => {
    if (labelsLoader) {
      document.getElementById("updateLabels").innerText = "Updating";
      document.getElementById("updateLabels").setAttribute("disabled", true);
    } else {
      if (fields && fields.response && fields.response.length > 0) {
        document.getElementById("updateLabels").innerText = "Update";
        document.getElementById("updateLabels").removeAttribute("disabled");
        if (labelsError == null) {
          if (labelsUpdating) {
            toast.success(`Custom fields have been updated`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            });
          }
        } else {
          toast.error(
            labelsError.message || "Custom fields could not be updated",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            }
          );
        }
        setLabelsUpdating(false);
      }
    }
  }, [labelsLoader]);

  const RecursiveField = ({ field, selectedFieldValue = "" }) => {
    const handleInputChange = (value, field) => {
      inputValuesRef.current[field.id] = value; // Update ref directly
    };

    const handleChange = (value, field) => {
      const fieldElements = document.querySelectorAll(
        "#customFields div[data-field-id]"
      );
      const ids = Array.from(fieldElements).map((element) =>
        element.getAttribute("data-field-id")
      );

      const commonIds = Object.keys(updateFieldValues).filter((field) =>
        ids.includes(String(field))
      );

      const filteredFieldValues = Object.keys(updateFieldValues)
        .filter((key) => commonIds.includes(key))
        .reduce((acc, key) => {
          acc[key] = updateFieldValues[key];
          return acc;
        }, {});

      setUpdateFieldValues(() => ({
        ...filteredFieldValues,
        [field.id]: value,
      }));
    };

    const selectedOptions = useMemo(() => {
      const value = updateFieldValues[field.id];
      return field?.pre_defined_options?.filter((option) =>
        Array.isArray(value)
          ? value.includes(option.field_value)
          : value === option.field_value
      );
    }, [field.pre_defined_options, updateFieldValues, field.id]);

    // Compute child fields based on selected options
    const childFields =
      selectedOptions?.flatMap((option) => option["sub_fields"]) || [];

    return (
      <div style={{ marginBottom: "1rem" }} data-field-id={field.id}>
        <p
          style={{
            fontSize: "16px",
            textTransform: "capitalize",
            marginBottom: "6px",
          }}
        >
          {field.field_name} {selectedFieldValue && `(${selectedFieldValue})`}
          {field.is_required && " *"}
        </p>
        {!field.is_fixed_values ? (
          field.field_type === "single_input" ? (
            <Input
              required={field.is_required}
              type="text"
              placeholder={`Type your ${field.field_name} here...`}
              defaultValue={inputValuesRef.current[field.id] || ""} // Use ref value for input
              onChange={(e) => handleInputChange(e.target.value, field)}
            />
          ) : null
        ) : (
          <Select
            {...(field.field_type === "multiple_input"
              ? { mode: "multiple" }
              : {})}
            style={{
              width: "100%",
              fontSize: "16px",
            }}
            placeholder={`Select ${field.field_name}`}
            value={updateFieldValues[field.id] || []}
            onChange={(value) => handleChange(value, field)}
          >
            {field?.pre_defined_options?.map((option) => (
              <Option key={option.field_value} value={option.field_value}>
                {option.field_value}
              </Option>
            ))}
          </Select>
        )}
        {childFields.length > 0 && (
          <div style={{ marginTop: "1rem" }}>
            {childFields.map((childField, index) => (
              <RecursiveField
                key={`${childField.field_name}_${index}`}
                field={childField}
                selectedFieldValue={selectedOptions[index]?.field_value || ""}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {fields && fields.response && fields.response.length > 0 ? (
        <form id="customFields" onSubmit={updateLabels}>
          {fields.response.map((field, index) => (
            <RecursiveField key={index} field={field} selectedFieldValue="" />
          ))}
          <Button
            type="primary"
            htmlType="submit"
            variant="contained"
            id="updateLabels"
          >
            Update
          </Button>
        </form>
      ) : (
        <div style={{ fontSize: "14px", color: "#333" }}>
          <p style={{ fontSize: "14px" }}>No custom fields available. </p>
          <p style={{ fontSize: "14px", marginTop: "10px" }}>
            Go to Settings &gt; Inbox &gt; Custom Fields{" "}
            <a
              href={`${process.env.REACT_APP_ORIGIN}/settings/#inboxSettings`}
              target="_blank"
              style={{ color: "var(--color-primary)" }}
            >
              (Click here)
            </a>{" "}
            to create new Ticket Fields
          </p>
        </div>
      )}
    </div>
  );
}

export default Labels;
