import { List } from 'antd';
import styled from 'styled-components';

export const MainPanelWrapper = styled.div`
    width: 30%;
    max-width: 440px;
    height: 100vh;
    font-weight: 600;
    color: #474747;

    .ant-tabs-nav-list {
        width: 95%;

        .ant-tabs-tab {
            flex: 1;
            justify-content: center;
            font-weight: 400;
            font-size: 16px;
            margin: 0 10px;   
            color: #818181;
            padding: 10px 0px;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
            color: #474747;
        }
    }

    .new-count{
        background: var(--color-red);
        height: 8px;
        width: 8px;
        display: inline-block;
        border-radius: 50%;
        margin-left: 5px;
        margin-bottom: 40%;
    }

    .subtab-count{
        background: rgba(198,28,32,0.65);
        color: #fff;
        padding: 2px 5px;
	    border-radius:5px;
        font-size: 10px;
        min-width: 20px;
        min-height: 20px;
        text-align: center;
        position: absolute;
        top: 0px;
        right: -10px;
        z-index: 9;
    }
`;

export const StyledSubPanel = styled.div`
    .ant-tabs-tab {
        font-size: 12px !important;
        margin: 0 10px;
        color: #818181;
    }
    .new-count{
        background: var(--color-red);
        height: 8px;
        width:8px;
        display: inline-block;
        border-radius: 50%;
        margin-left: 0px;
        margin-bottom: 5px;
    }
`

export const StyledListWrap = styled.div`
    display: flex;
    height: 53vh;
    overflow: hidden;
    text-decoration: none;

	:hover{
		overflow-y: auto;
	}

    @media (min-height: 800px){
        height: 63vh;
    }

    @media (min-height: 1000px){
        height: 72vh;
    }

    >div{
        width:100%;
    }

    .ant-list{
        width:100%;
    }

    .gototop{
        :hover{
            text-decoration: underline;
        }
    }
`;

export const StyledListItem = styled.div`
    padding: 0px 16px 0px 24px;
    background-color: ${props => props.selected ? 'rgba(12, 71, 125, 0.08)' : 'transparent'};
    position: relative;
    border-bottom: 1px solid #F0F0F0;

    :hover{
        background: #F1F8FC;
        .overlay{
            opacity: 1 !important;
        }
        .message-time{
            .unread-dropdown{
                display: block;
            }
        }
    }
    
    &.load-style{
        margin: 20px 0;
    }

    .ant-list-item{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .ant-list-item-meta-title{
        font-size: 17px;
        font-weight: 400;
        text-transform: capitalize;
        color: #474747;
        white-space: nowrap;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ant-list-item-meta-description{
        font-size: 14px;
        font-weight: 200;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0px;
        color: #5B5B5B;
        height: 25px;
        max-width: 240px;
        white-space: nowrap;
    }

    .initial-avatar{
        background-color: var(--color-icon-background);
        color: #fff;
        font-weight: 400;
        padding: 8px 0px;
        height: 48px;
        width: 48px;
        font-size: 1em;
    }

    .overlay{
        background: #26282F99;
        position: absolute;
        height: 40px;
        width: 40px;
        left: 25px;
        border-radius: 50%;
        top: 18px;
        bottom: 0;
        right: 0;
        opacity: 0;
        content: '';
        color: #fff;
        transition: all 0.4s ease-in-out 0s;
    }

    .unread-count{
        height: 10px;
        width: 10px;
        text-align: center;
        padding: 2px;
        color: #fff;
        font-weight: 200;
        font-size: 16px;
        border-radius: 50%;
        background: var(--color-primary);
        margin: 0px 8px !important;
    }
`;


export const StyledListMeta = styled.div`
    max-width: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    .message-unread{
        height: 8px;
        width: 8px;
        color: var(--color-red);
        font-size: 0.7em;
        border-radius: 50%;
        background-color: rgb(244, 67, 54);
        text-align: center;
    }

    .message-time{
        font-size: 12px;
        font-weight: 200;
        margin-top: 4px;        
        margin-right: 10px;
        color: #5B5B5B;
        display: flex;
        align-items: center;

        .unread-dropdown{
            display: none;
        }
    }
`;

export const StyledSource = styled.img`
    position: absolute;
    bottom: 14px;
    left: 58px;
    z-index: 40;
`

export const StyledTagList = styled(List)`
    margin-left: 10px !important;
    .ant-list-item{
        padding: 6px 0;
        cursor: pointer;
    }

    .tag-text{
        background: #EBF0F4;
        font-size: 13px;
        padding: 4px 8px !important;
        border-radius: 40px;
        font-weight: 400;
        color: var(--color-primary);
    }
`;
