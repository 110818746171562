import { all } from 'redux-saga/effects';
import { sagas as mainSagas } from '@store-inbox/MainPanel';
import sagas from '@store-inbox/saga';
import { sagas as conversationSagas } from '@store-inbox/ConversationPanel';
import { sagas as summarySagas } from '@store-inbox/SummaryPanel';

export default function* rootSaga() {
  yield all([
    ...sagas.map(saga => saga()),
    ...mainSagas.map(saga => saga()),
    ...conversationSagas.map(saga => saga()),
    ...summarySagas.map(saga => saga()),
  ])
}