import { SCOPE } from './types';
import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selector = state => get(state, SCOPE);

export const conversationDataSelector = createSelector(selector, store => store.response);

export const activeConversationData = createSelector(selector, store => store.payload);

export const loadingSelector = createSelector(selector, store => store.loading);

export const currentActiveConversationSelector = createSelector(selector, store => store.payload.ticket_id);

export const derivedConversationId = createSelector(conversationDataSelector, (conversationData)=>{
    let id = "";
    if (conversationData && conversationData.data) {
        id = conversationData.data.conversation_id || "";
    }
    return id;
})

export const postId = createSelector(conversationDataSelector, (conversationData)=>{
    let id = [];
    if (conversationData && conversationData.data) {
        const comments = conversationData.data.tickets[0].content.filter(i=>i.content_type=="comment");
        for( let i=0; i<comments.length; i++ ){
            id.push(comments[i].post_id);
        }
    }
    return id;
})

export const quickRepliesSelector  = createSelector(selector, store => store.quickReplies);

export const audioSelector = createSelector(selector,store => store.audioPlay);

export const uploadStatus = createSelector(selector, store => store.uploadStatus);

export const commentLoader = createSelector(selector, store => store.commentLoading);

export const newCommentID = createSelector(selector, store => store.newCommentID);

export const emailFileSelector = createSelector(selector, store => store.emailFile);

export const ownershipSelector = createSelector(selector, store => store.ownership);
