import * as types from './types';

const initialState = {
    socket: false,
    drawer: false,
    activeConversation: '',
    profile: {},
    wsStatus: false,
}

export default (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case types.FILTER_DRAWER: {
            return {
                ...state,
                drawer: payload
            };
        }
        case types.LOAD_SOCKET: {
            return {
                ...state,
                socket: true
            };
        }
        case types.SET_PROFILE: {
            return {
                ...state,
                profile: { ...payload }
            };
        }
        case types.SET_WS_STATUS: {
            return {
                ...state,
                wsStatus: true,
            }
        }
        default:
            return state;
    }
};