import styled from 'styled-components';

export const StyledLog = styled.span` 
    font-size:0.8em;
    color:var(--color-primary);
    font-weight: 400;
`;

export const StyledDocument = styled.div`
    display:flex;
    flex-direction: row;
    border-radius: 15px;
    align-items: center;
    justify-content: space-between;

    .file-icon{
        height:100%;
        padding: 20px;
        width: 60px;
        font-size: 1.4em;
        &.merchant{
            background-color: #1D8DCC;
            color:#fff;
            border-radius: 0 15px 0 0;
        }
        &.customer{
            background-color: #1D8DCC;
            color:#fff;
            border-radius: 15px 0 0 0;
        }
    }

    .file-name{
        max-width: 200px;
        min-width: 100px;
        padding: 10px;
        font-size: 13px;
        overflow:hidden;
        text-overflow: ellipsis;
        height:100%;
        &.merchant{
            background-color: #fff;
            color:#474747;
        }
        &.customer{
            background-color: #fff;
            color:#474747;
        }
    }

    .file-button{
        height:100%;
        padding: 10px;
        &.merchant{
            background-color: #fff;
            border-radius: 15px 0 0 15px;
            div{
                background: rgba(12, 71, 125, 0.08);
                padding: 5px;
                border-radius: 50%;
                a{
                    background-color:#1D8DCC;
                    border: none;
                }
            }
        }
        &.customer{
            background-color: #fff;
            border-radius: 0 15px 15px 0;
            div{
                background: rgba(12, 71, 125, 0.08);
                padding: 5px;
                border-radius: 50%;
                a{
                    background-color:#1D8DCC;
                }
            }
        }
    }
`;

export const StyledMeta = styled.span`
    position: absolute; 
    display: flex;
    align-items: center;
    bottom: ${props=>props.actor==="customer" ? "-25px": "5px"};
    right: 0;
    padding: 0px 10px;
    justify-content: space-between;
    width: 100%;
	.time {
		font-size: 12px;
		color: #6F6F6F; 
        font-weight: 400;
	}

    .icon-read{
        font-size: 18px;
    }
`;

export const StyledParserMessage = styled.span`
    .buttons-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
    }
    button{
        background-color: #fff;
        color: #1D8DCC;
        font-weight: 500;
        border: 1px solid #1D8DCC;
        border-radius: 6px;
        text-align: center;
        margin: 0px 5px;
        &:hover{
            background-color: #1D8DCC;
            color: #fff;
        }
    }
    .ant-btn:active{
        border: 1px solid #6f6f6f !important;
        color: #6f6f6f;
    }
`;

export const StyleDelete = styled.span`
    display: flex;
    color: #A7AEC2;
    .deleteicon{
        background: rgba(255, 255, 255, 0.3);
        border-radius: 99px;
        height: 2.5rem;
        width: 2.5rem;
    }
    .delete{
        padding: 10px;
    }
    .deletetext{
        margin-left: 0.25rem;
        font-weight: 500;
        font-style: italic;
    }
`

export const StyledAgentIntial = styled.div`
    position: absolute;
    background: #fff;
    top: 0px;
    right: -10px;
    z-index: 10;
    width: 40px;
    height: 40px;
    border-radius: 50%;
`

export const StyledParent = styled.div`
    background: #fff;
    padding: 10px;
    border-radius: 6px;
    border-top-left-radius: 0px;
    margin-bottom: 6px;
    height: 55px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    display: flex;

    :hover{
        background: rgba(227,228,238);
        transition: 0.5s;
    }
`

export const StyledTicketHistory = styled.div`
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #1d8dcc;
    cursor: pointer;
    z-index: 10;
    .ticket-num{
        display: none;
    }
    :hover{
        width: 100%;
        height: 30px;
        border-radius: 15px;
        .ticket-num{
            display: block; 
        }
    }
`

export const StyledGoTo =styled.div`
position: fixed;
bottom: 140px;
stroke: #fff;
right: ${props=>props.openPanel ? "26%" : "10%" };
width: 40px;
height: 40px;
padding-left: 5px;
padding-right: 9px;
background: #1d8dcc;
border-radius: 50%;
z-index: 20;
cursor: pointer;
transform: translateX(-50%);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`