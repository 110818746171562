
import * as types from './types';
import { updateNewMessage } from './util';
import some from 'lodash/some';

const initialState = {
  response: {},
  activeTab: 0,
  newMessage: {},
  loading: false,
  payload: {
    ticket_id: '',
    page: 1,
    page_size: 25,
    fetch_since: null,
  },
  active_id: "",
  quickReplies: {
      response: {},
      error: null
  },
  audioPlay: '',
  uploadStatus: null,
  commentLoading: false,
  newCommentID: null,
  emailFile: null,
  sentSuccess: false,
  ownership: false
}


export default (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case types.INIT:
      return {
        state,
      };
    case types.SET_ACTIVE_CONVERSATION_ID:
      return {
        ...state,
        loading: true,
        payload: {
          ...state.payload,
          ticket_id: payload
        }
      };
    case types.SET_ACTIVE_CONVERSATION_ID_BG:
      return {
        ...state,
        loading: false,
        payload: {
          ...state.payload,
          ticket_id: payload
        }
      };
    case types.ACTIVE_ID:
      return {
        ...state,
        loading: false,
        active_id: payload
      };
    case types.RECEIVE_CONVERSATION:
      return {
        ...state,
        loading: false,
        response: payload
      };
    case types.GET_STATUS: {
      const { data: { status, logs }, currentConversationId } = payload;
      const logData = logs.filter(a => a.ticket_id == currentConversationId);

      if(logData[0].ticket_id == currentConversationId && state.response.data !== undefined){
        state.response.data.tickets[state.response.data.tickets.length-1].content = [...state.response.data.tickets[state.response.data.tickets.length-1].content,{ comment: logData[0].comment, content_type: "log", created_at: logData[0].created_at }]
        state.response.data.tickets[state.response.data.tickets.length-1].status = status
        return {
          ...state,
          newStatus: payload,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        };
      }
    }
    case types.GET_PRIORITY: {
      const { data: { log: priorityLog, priority } } = payload;
      if(priorityLog !==undefined){
        state.response.data.tickets[state.response.data.tickets.length-1].content = [ ...state.response.data.tickets[state.response.data.tickets.length-1].content,{ comment: priorityLog.comment, content_type: "log", created_at: priorityLog.created_at }]
        state.response.data.tickets[state.response.data.tickets.length-1].priority = priority;
        return {
          ...state,
          newPriority: payload,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        };
      }
    }
    case types.ADD_SUBSCRIBER:
    case types.REMOVE_SUBSCRIBER: {
      return {
        ...state,
      }
    }
    case types.ADD_TAGS_FEEDBACK: {
      const { data: { log, ticket_id}, currentConversationId } = payload;
      if (ticket_id === Number(currentConversationId)) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = [ ...state.response.data.tickets[state.response.data.tickets.length-1].content, { comment: log.comment, content_type: "log", created_at: log.created_at }]
        return{
          ...state,
          loading: false,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        }
      }
    }
    case types.REMOVE_TAGS_FEEDBACK: {
      const { data: { log, ticket_id}, currentConversationId } = payload;
      if (ticket_id === Number(currentConversationId)) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = [ ...state.response.data.tickets[state.response.data.tickets.length-1].content, { comment: log.comment, content_type: "log", created_at: log.created_at }]
        return{
          ...state,
          loading: false,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        }
      }
    }
    case types.ADD_SUBSCRIBER_FEEDBACK: {
      const { data: { log: subscriberLog, ticket: { id } }, currentConversationId } = payload;
      if (id === Number(currentConversationId)) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = [ ...state.response.data.tickets[state.response.data.tickets.length-1].content,{ comment: subscriberLog.comment, content_type: "log", created_at: subscriberLog.created_at }]
        return {
          ...state,
          loading: false,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        };
      } else {
        return state;
      }
    }
    case types.REMOVE_SUBSCRIBER_FEEDBACK: {
      const { data: { subscriber, log: subscriberLog, conversation_id, ticket_id }, currentConversationId, currentUserId } = payload;
      if (ticket_id === Number(currentConversationId)) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = [ ...state.response.data.tickets[state.response.data.tickets.length-1].content,{ comment: subscriberLog.comment, content_type: "log", created_at: subscriberLog.created_at }]
        return {
          ...state,
          loading: false,
          payload: {
            ...state.payload,
            ticket_id: subscriber == currentUserId ? null : ticket_id,
          },
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        };
      } else {
        return state;
      }
    }
    case types.SEND_MESSAGE: {
      const { message, temp_id, conversation_source, from, to, cc, bcc, attachment, conversation_id } = payload;
      let contentType = (conversation_source !== "email") ? "message" : "email";
      const toEmails = to && to!==null ? to.map(email => ({ email })) : null;
      const ccEmails = cc && cc!==null ? cc.map(email => ({ email })) : null;
      const bccEmails = bcc && bcc!== null ? bcc.map(email => ({ email })) : null;
      state.response.data.tickets[state.response.data.tickets.length-1].content = [...state.response.data.tickets[state.response.data.tickets.length-1].content, { message: message, conversation_source: conversation_source, temp_id: temp_id, content_type: contentType, actor: 'merchant', created_at: new Date().toISOString(), from: { email: from }, to: toEmails, cc: ccEmails, bcc: bccEmails, attachment: attachment, conversation_id: conversation_id, send_status: 'sending'}]
      return {
        ...state,
        response: {
          ...state.response,
          data: {
            ...state.response.data,
            tickets: [...state.response.data.tickets]
          }
        }
      };
    }
    case types.REPLY_TO_COMMENT: {
      const set = (obj, path, val) => { 
        const keys = [path];
        const lastKey = keys.pop();
        const lastObj = keys.reduce((obj, key) => 
            obj[key] = obj[key] || {}, 
            obj); 
        lastObj[lastKey] = val;
      };

      const randomID = Math.floor(Math.random() * (1000000 - 1 + 1) + 1);
      let value = {
        "actor": 'merchant',
        "comment": payload.comment,
        "children": [],
        "username": (state.response.data.tickets[state.response.data.tickets.length-1].content.filter(i=>i.content_type == "comment")).filter(i=>i.post_id==payload.post_id)[0].merchant_name,
        "parent_id": payload.comment_id,
        "created_at": null,
      };
      ((state.response.data.tickets[state.response.data.tickets.length-1].content.filter(i=>i.content_type == "comment")).filter(i=>i.post_id==payload.post_id)[0].comment_data[payload.index])[payload.comment_id].children.push(String(randomID));
      set((state.response.data.tickets[state.response.data.tickets.length-1].content.filter(i=>i.content_type == "comment")).filter(i=>i.post_id==payload.post_id)[0].comment_data[payload.index], randomID, value);
        return {
          ...state,
          commentLoading: true,
          newCommentID: randomID,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        }
      }
    case types.DELETE_COMMENT: {
      ((state.response.data.tickets[state.response.data.tickets.length-1].content.filter(i=>i.content_type == "comment")).filter(i=>i.post_id==payload.post_id)[0].comment_data[payload.index])[payload.comment_id].deleted = true;
      const parent_id = ((state.response.data.tickets[state.response.data.tickets.length-1].content.filter(i=>i.content_type == "comment")).filter(i=>i.post_id==payload.post_id)[0].comment_data[payload.index])[payload.comment_id].parent_id;
      if((((state.response.data.tickets[state.response.data.tickets.length-1].content.filter(i=>i.content_type == "comment")).filter(i=>i.post_id==payload.post_id)[0].comment_data[payload.index])[parent_id]) !== undefined){
        const index = ((state.response.data.tickets[state.response.data.tickets.length-1].content.filter(i=>i.content_type == "comment")).filter(i=>i.post_id==payload.post_id)[0].comment_data[payload.index])[parent_id]?.children.indexOf(payload.comment_id);
        ((state.response.data.tickets[state.response.data.tickets.length-1].content.filter(i=>i.content_type == "comment")).filter(i=>i.post_id==payload.post_id)[0].comment_data[payload.index])[parent_id].children.splice(index,1);
        
        return {
          ...state,
          loading: false,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        };
      }else{
        return {
          ...state,
          loading: false,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        };
      }
      
    }
    case types.SET_FILE_UPLOAD_SUCCESS: {
      const { data, currentConversationId } = payload;
      // const newContent = data.concat(state.response.data.content);
      if (currentConversationId == data[0].conversation_id) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = state.response.data.tickets[state.response.data.tickets.length-1].content.concat(data);
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          },
          uploadStatus: data,
        };
      } else {
        return state;
      }
    }
    case types.RESET_ATTACHMENT_DATA: {
      return {
        ...state,
        emailFile: null
      }
    }
    case types.SET_EMAIL_UPLOAD_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        uploadStatus: data,
        emailFile: data
      }
    }
    case types.SET_FILE_UPLOAD_ERROR: {
      return {
        ...state,
        uploadStatus: "error"
      }
    }
    case types.UPDATE_OWNERSHIP_CHANGE: {
      const { data: { logs }, currentConversationId, currentOwnerId } = payload;
      const logData = logs.filter(a => a.ticket_id == currentConversationId);

      if(logData !== null && logData !== undefined && logData.length > 0 && logData[0].ticket_id == currentConversationId){
        state.response.data.tickets[state.response.data.tickets.length-1].content = [...state.response.data.tickets[state.response.data.tickets.length-1].content, { comment: logData[0].comment, content_type: "log", created_at: logData[0].created_at }]
        state.response.data.tickets[state.response.data.tickets.length-1].status = "open";
        state.response.data.tickets[state.response.data.tickets.length-1].owner_id = currentOwnerId;
      }
      return {
        ...state,
        response: {
          ...state.response,
          data: {
            ...state.response.data,
            tickets:[...state.response.data.tickets]
          }
        },
        ownership: true,
      };
    }
    case types.CHANGE_OWNERSHIP_ERROR: {
      return {
        ...state,
        ownership: false
      }
    }
    case types.NEW_MESSAGE: {
      const { data, currentConversationId } = payload;
      if (currentConversationId == data.ticket.id) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = [...state.response.data.tickets[state.response.data.tickets.length-1].content,data.conversation.new_message]
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          },
          audioPlay: data.conversation.new_message.message,
        };
      } else {
        return {...state, audioPlay: data.conversation.new_message.message};
      }
    }
    case types.COMMENT_CREATED: {
      const { data, currentConversationId } = payload;
      const index = state.response.data.tickets[state.response.data.tickets.length-1].content.findIndex(i=>i.post_id == data.data.post_id);
      state.response.data.tickets[state.response.data.tickets.length-1].content.splice(index, 1);
      if (data.data.ticket_id === Number(currentConversationId)) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = [...state.response.data.tickets[state.response.data.tickets.length-1].content,data.data]
        return {
          ...state,
          commentLoading: false,    
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        }
      } else {
        return state;
      }
    }
    case types.COMMENT_DELETED: {
      const { data, currentConversationId } = payload;
      const index = state.response.data.tickets[state.response.data.tickets.length-1].content.findIndex(i=>i.post_id == data.data.post_id);
      state.response.data.tickets[state.response.data.tickets.length-1].content.splice(index, 1);
      if (data.data.ticket_id === Number(currentConversationId)) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = [...state.response.data.tickets[state.response.data.tickets.length-1].content, data.data, data.data.content_type="comment"]
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        }
      } else {
        return state;
      }
    }
    case types.NEW_COMMENT: {
      const { data, currentConversationId } = payload;
      const index = state.response.data.tickets[state.response.data.tickets.length-1].content.findIndex(i=>i.post_id == data.data.conversation.post_id);
      state.response.data.tickets[state.response.data.tickets.length-1].content.splice(index, 1);
      if (data.data.ticket.id === Number(currentConversationId)) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = [...state.response.data.tickets[state.response.data.tickets.length-1].content, data.data.conversation]
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        }
      } else {
        return state;
      }
    }
    case types.MESSAGE_DELETED: {
      const { data, currentConversationId } = payload;
      if (currentConversationId == data.conversation_id) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = state.response.data.tickets[state.response.data.tickets.length-1].content.map((content) => {
          if (content.id == data.id) {
            return { ...content, deleted: true }
          }
          return content;
        })
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          }
        };
      } else {
        return state;
      }
    }
    case types.UPDATE_MESSAGE_READ: {
      const { data, currentConversationId } = payload;
      if (currentConversationId == data.conversation_id) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = state.response.data.tickets[state.response.data.tickets.length-1].content.map((content) => {
          if (content.id == data.id) {
            return { ...content, read: true, delivered: true }
          }
          return content;
        })
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          },
          uploadStatus: false,
        };
      } else {
        return state;
      }
    }
    case types.UPDATE_MESSAGE_DELIVERED: {
      const { data, currentConversationId } = payload;
      if (currentConversationId == data.conversation_id) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = state.response.data.tickets[state.response.data.tickets.length-1].content.map((content) => {
          if (content.id == data.id) {
            return { ...content, delivered: true }
          }
          return content;
        })
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
            tickets: [...state.response.data.tickets]
            }
          },
          uploadStatus: false,
        };
      } else {
        return state;
      }
    }
    case types.MESSAGE_FAILED: {
      const { data, currentConversationId } = payload;
      if (currentConversationId == data.conversation_id) {
        state.response.data.tickets[state.response.data.tickets.length-1].content = state.response.data.tickets[state.response.data.tickets.length-1].content.map((content) => {
          if (content.id == data.id) {
            return { ...content, error_message: data.error_message }
          }
          return content;
        })
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
            tickets: [...state.response.data.tickets]
            }
          },
          uploadStatus: false,
        };
      } else {
        return state;
      }
    }
    case types.UPDATE_MESSAGE_SENT: {
      const { data, currentConversationId, success, error } = payload;
      if (error === null && currentConversationId == data.conversation_id) {
        const updatedContent = updateNewMessage(state.response.data.tickets[state.response.data.tickets.length-1].content, data, success, error);
        state.response.data.tickets[state.response.data.tickets.length-1].content = updatedContent;
        state.response.data.tickets[state.response.data.tickets.length-1].status = "open";
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          },
          uploadStatus: false,
        };
      } else if(error !==null){
        const updatedContent = updateNewMessage(state.response.data.tickets[state.response.data.tickets.length-1].content, {}, success, error);
        state.response.data.tickets[state.response.data.tickets.length-1].content = updatedContent;
        return {
          ...state,
          response: {
            ...state.response,
            data: {
              ...state.response.data,
              tickets: [...state.response.data.tickets]
            }
          },
          uploadStatus: false,
        };
      } else {
        return state;
      }
    }
    case types.GET_QUICK_REPLIES_SUCCESS: {
      return {
          ...state,
          quickReplies: {
              ...state.quickReplies,
              response: payload,
              error: null
          }
      };
    }
    case types.GET_QUICK_REPLIES_ERROR: {
      return {
          ...state,
          quickReplies: {
              ...state.quickReplies,
              response: [],
              error: payload
          }
      };
    }
    case types.UPDATE_QUICK_REPLIES: {
      return {
        ...state,
        quickReplies: {
          ...state.quickReplies,
          response: payload,
          error: null,
        }
      }
    }
    case types.UPDATE_STATUS: {
      return {
        ...state,
        response: {
          ...state.response,
          status: payload
        }
      }
    }
    default:
      return state;
  }
};