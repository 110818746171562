import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Skeleton,
  Menu,
  Dropdown,
  Button,
  Tooltip,
  Avatar,
  Modal,
  Divider,
  Select,
} from "antd";
import Icon from "@ant-design/icons";
import Subscribers from "./Subscribers";
import { loadingSelector } from "@store-inbox/ConversationPanel/selector";
import { ReactComponent as SubscriberIcon } from "@assets/icons/subscriber.svg";
import { ReactComponent as HighIcon } from "@assets/icons/priority/high.svg";
import { ReactComponent as MediumIcon } from "@assets/icons/priority/medium.svg";
import { ReactComponent as LowIcon } from "@assets/icons/priority/low.svg";
import { ReactComponent as NotSetIcon } from "@assets/icons/priority/notset.svg";
import { ReactComponent as ClosedIcon } from "@assets/icons/status/closed.svg";
import {
  setStatus,
  setPriority,
  changeOwnership,
  setActiveConversation,
  setFollowUp,
} from "@store-inbox/ConversationPanel/action";
import {
  StyledCloseChatIcon,
  StyledHeader,
  StyledTimer,
  StyledTopLayer,
} from "../styled";
import {
  StyledDropdownLayer,
  StyledDropdownIcon,
  StyledSource,
  StyledClose,
  StyledTicketId,
} from "./styled";
import {
  derivedAllAgentsDataSelector,
  derivedFullNameDataSelector,
} from "../utils";
import { getInitials } from "@utils/functions";
import Whatsapp from "@assets/icons/main/whatsapp.svg";
import Instagram from "@assets/icons/main/instagram.svg";
import Facebook from "@assets/icons/main/facebook.svg";
import EmailIcon from "@assets/icons/main/email.svg";
import { ReactComponent as MoreIcon } from "@assets/icons/message/more.svg";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countdown, { calcTimeDelta } from "react-countdown";
import { derivedConnectionsSelector } from "../../MainPanel/utils";
import { BsQuestionCircle } from "react-icons/bs";
import { useStopwatch } from "react-timer-hook";
import {
  timerDataSelector,
  timerLoadingSelector,
} from "@store-inbox/SummaryPanel/selector";
import { LuTimer } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
// import { payloadSelector } from '@store-inbox/MainPanel/selector';

const PriorityOptions = [
  {
    key: 1,
    title: "High Priority",
    display: true,
    icon: HighIcon,
    description: "High Priority",
  },
  {
    key: 2,
    title: "Medium Priority",
    display: true,
    icon: MediumIcon,
    description: "Medium Priority",
  },
  {
    key: 3,
    title: "Low Priority",
    display: true,
    icon: LowIcon,
    description: "Low Priority",
  },
  {
    key: 0,
    title: "No Priority",
    display: true,
    icon: NotSetIcon,
    description: "No Priority",
  },
];

const getPriority = (value) => {
  return PriorityOptions.filter((item) => {
    if (item.key === value) {
      return item;
    }
  });
};

const Header = React.forwardRef((props, ref) => {
  const settings = useSelector(derivedConnectionsSelector);
  const {
    ticket: {
      subscribers,
      id,
      status,
      priority,
      owner_id,
      session_expiry_at,
      is_csat_sent,
      expiration_info,
    },
    loading,
    isOwner,
    setIsOwner,
    conversation_source,
    setOpenPanel,
    openPanel,
    setDefaultKey,
  } = props;
  const { completed } = calcTimeDelta(new Date(session_expiry_at).getTime());
  const { Option } = Select;
  const existingTimer = useSelector(timerDataSelector);
  const timerLoading = useSelector(timerLoadingSelector);
  const [isTabActive, setIsTabActive] = useState(true);
  // const payload = useSelector(payloadSelector);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === "visible");
    };

    const handleFocus = () => setIsTabActive(true);
    const handleBlur = () => setIsTabActive(false);

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  useEffect(() => {
    const newOffset = new Date();
    if (existingTimer > 0) {
      // Create a new offset timestamp using the ticketTimer
      newOffset.setSeconds(newOffset.getSeconds() + existingTimer);

      // Reset the stopwatch with the new offset
      reset(newOffset);
      if (status === "open" || status === "assigned") start();
      else pause();
    } else {
      reset();
      if (status === "open" || status === "assigned") start();
      else pause();
    }
  }, [existingTimer]);

  useEffect(() => {
    if (id) {
      reset();
    }
  }, [id]);

  useEffect(() => {
    if (isTabActive) {
      start();
    } else {
      pause();
    }
  }, [isTabActive]);

  const StatusOptions = [
    {
      key: "closed",
      title: "Close ticket",
      display: status !== "closed",
      icon: ClosedIcon,
    },
    {
      key: "closed_csat",
      title: status !== "closed" ? "Close ticket & send CSAT" : "Send CSAT",
      display:
        settings.is_csat_enabled &&
        !is_csat_sent &&
        ((!completed && conversation_source === "whatsapp") ||
          conversation_source === "email"),
      icon: ClosedIcon,
    },
    {
      key: "follow_up",
      title: "Follow up",
      display: status == "open",
    },
  ];

  const hoursMenuOptions = [
    {
      key: "0",
      title: "no",
    },
    ...Array.from({ length: 23 }, (_, index) => ({
      key: `${index + 1}`,
      title: `${index + 1}`,
    })),
  ];

  const dispatch = useDispatch();
  const fullName = useSelector(derivedFullNameDataSelector);
  const conversationLoader = useSelector(loadingSelector);
  const [currentPriority, setCurrentPriority] = useState(PriorityOptions[0]);
  const [ownerStatus, setOwnerStatus] = useState(false);
  const history = useHistory();
  // const conversation = useSelector(tabsDataSelector);
  // const allTickets = useSelector(derivedAllTickets);
  const allAgents = useSelector(derivedAllAgentsDataSelector);
  const [ownerName, setOwnerName] = useState(null);
  const [followupModel, setFollowupModel] = useState(false);
  const [followupHours, setFollowupHours] = useState(0);
  const [agentDetails, setAgentDetails] = useState({});

  useEffect(() => {
    if (owner_id && allAgents && owner_id !== null) {
      let owner = allAgents.find((agent) => agent.id === owner_id);
      if (owner && owner !== null) {
        setOwnerName(owner.first_name + " " + owner.last_name);
      }
    } else {
      setOwnerName(null);
    }
  }, [owner_id, allAgents, id]);

  useEffect(() => {
    if (priority) {
      const derived = getPriority(priority);
      setCurrentPriority(derived[0]);
    } else {
      setCurrentPriority(PriorityOptions[3]);
    }
  }, [priority]);

  useEffect(() => {
    if (owner_id || isOwner) {
      setOwnerStatus(true);
    } else {
      setOwnerStatus(false);
    }
  }, [owner_id, isOwner]);

  useEffect(() => {
    if (status == "closed" && isOwner) {
      setIsOwner(false);
    }
  }, [isOwner, status]);

  useEffect(() => {
    if (allAgents && allAgents.length > 0) {
      let currentUser = allAgents.filter(
        (agent) => agent.id === settings.current_user_id
      );
      setAgentDetails(currentUser[0]);
    }
  }, [settings, allAgents]);

  const handleOwnership = () => {
    dispatch(
      changeOwnership({
        ticket_ids: [Number(id)],
        transfer_to: settings.current_user_id,
      })
    );
    setOwnerStatus(true);
    // dispatch(setSearchFilters({
    //     filters: {
    //         ...payload.filters
    //     },
    //     fetch_since: null,
    //     page_size: 25
    // }));
    // var index = conversation.findIndex(function(item,i){
    //     return item.ticket.id == id
    // });
    // conversation.splice(index,1);
    // if(conversation.length>0){
    //     const newID = conversation[index].ticket.id;
    //     history.push(`/inbox/tickets/${newID}`);
    // }else{
    //     history.push("/inbox/tickets");
    // }
    toast.success(`#${id} has been assigned to you`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const handlePriorityChange = (e) => {
    dispatch(
      setPriority({
        ticket_id: id,
        priority: e.key,
      })
    );
  };

  const handleStatusChange = (e) => {
    if (e.key === "follow_up") {
      setFollowupModel(true);
    } else {
      dispatch(
        setStatus({
          ticket_ids: [id],
          status: "closed",
          send_csat: e.key === "closed_csat" ? true : false,
        })
      );
      if (status !== "closed") {
        toast.success(`#${id} has been closed`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        // dispatch(setSearchFilters({
        //     filters: {
        //         ...payload.filters
        //     },
        //     fetch_since: null,
        //     page_size: 25
        // }));
        history.push("/inbox/tickets");
      } else {
        toast.success(`CSAT sent`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };

  const priorityMenu = (
    <Menu className="header-menu-icon" onClick={handlePriorityChange}>
      {PriorityOptions.map((item) => {
        if (item.display) {
          return (
            <Menu.Item key={item.key} value={item.key}>
              {/* <Tooltip
                                placement="right"
                                title={item.title}
                                className="tooltip"
                            > */}
              <StyledDropdownIcon>
                <Icon className="dropdown-icon" component={item.icon} />
                <span style={{ fontSize: "16px", marginLeft: "10px" }}>
                  {item.description}
                </span>
              </StyledDropdownIcon>
              {/* </Tooltip> */}
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );

  const statusMenu = (
    <Menu className="header-menu-icon" onClick={handleStatusChange}>
      {StatusOptions.map((item) => {
        if (item.display) {
          return (
            <Menu.Item
              style={{ marginLeft: "auto" }}
              key={item.key}
              value={item.key}
            >
              {/* <Tooltip
                                placement="left"
                                title={item.title}
                                className="tooltip"
                            > */}
              <StyledDropdownIcon>
                <span
                  className="moreicon"
                  style={{ fontSize: "16px", marginLeft: "auto" }}
                >
                  {item.title}
                </span>
              </StyledDropdownIcon>
              {/* </Tooltip> */}
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );

  // const ticketsMenu = (
  //   <Menu
  //     className="header-menu-icon"
  //     style={{ marginTop: "10px" }}
  //     onClick={handleTicketChange}
  //   >
  //     {allTickets &&
  //       allTickets[0].conversation_all_tickets.map((ticket, i) => {
  //         const id = Object.keys(allTickets[0].conversation_all_tickets[i]);
  //         const status = Object.values(
  //           allTickets[0].conversation_all_tickets[i]
  //         );
  //         return (
  //           <Menu.Item key={id} value={id}>
  //             <span>
  //               {id} - {status}
  //             </span>
  //           </Menu.Item>
  //         );
  //       })}
  //   </Menu>
  // );

  const openDrawer = () => {
    setOpenPanel(!openPanel);
    setDefaultKey(null);
  };

  const renderer = (openPanel, e) => {
    if (e.completed) {
      // Render a completed state
      return (
        <div className="countdown">
          <StyledTimer className="warning" closed={openPanel}>
            <span style={{ marginRight: "6px" }}>Ticket is expired!</span>
            {expiration_info && expiration_info !== null && (
              <Tooltip placement="bottom" title={expiration_info}>
                <BsQuestionCircle />
              </Tooltip>
            )}
          </StyledTimer>
        </div>
      );
    } else {
      // Render a countdown
      if (e.hours.toString().length == 1) {
        e.hours = "0" + e.hours.toString();
      }
      if (e.minutes.toString().length == 1) {
        e.minutes = "0" + e.minutes.toString();
      }
      if (e.seconds.toString().length == 1) {
        e.seconds = "0" + e.seconds.toString();
      }
      return (
        <div className="countdown">
          <StyledTimer
            closed={openPanel}
            className={e.hours < 3 ? "warning" : "safe"}
          >
            <span style={{ marginRight: "6px" }}>
              Ticket will expire in{" "}
              <span style={{ fontWeight: "600", margin: "0px 6px" }}>
                {" "}
                {e.hours}:{e.minutes}:{e.seconds}{" "}
              </span>{" "}
              hours
              {expiration_info && expiration_info !== null && (
                <Tooltip placement="bottom" title={expiration_info}>
                  <BsQuestionCircle />
                </Tooltip>
              )}
            </span>
          </StyledTimer>
        </div>
      );
    }
  };

  const handleFollowUp = () => {
    dispatch(
      setFollowUp({
        ticket_id: id,
        follow_up_hr: followupHours,
      })
    );
    setFollowupModel(false);
  };

  toast.configure();

  return (
    <StyledHeader ref={ref}>
      {loading ? (
        <Skeleton paragraph={{ rows: 1 }} />
      ) : (
        <Fragment>
          <StyledTopLayer>
            {conversationLoader ? (
              <Skeleton.Input style={{ width: 200 }} active={true} />
            ) : (
              <div className="customer-title">
                {conversation_source === "whatsapp" ? (
                  <StyledSource
                    src={Whatsapp}
                    source={conversation_source}
                    alt=""
                  />
                ) : conversation_source === "instagram" ? (
                  <StyledSource
                    src={Instagram}
                    source={conversation_source}
                    alt=""
                  />
                ) : conversation_source === "messenger" ? (
                  <StyledSource
                    src={Facebook}
                    source={conversation_source}
                    alt=""
                  />
                ) : conversation_source === "email" ? (
                  <StyledSource
                    src={EmailIcon}
                    source={conversation_source}
                    alt=""
                  />
                ) : (
                  ""
                )}
                <Avatar className="initial-avatar" onClick={openDrawer}>
                  {getInitials(fullName)}
                </Avatar>
                <div style={{ marginLeft: "10px" }}>
                  <p style={{ margin: "0" }} onClick={openDrawer}>
                    {fullName}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                      marginLeft: "0px",
                    }}
                  >
                    {status !== "closed" && (
                      <span style={{ marginRight: "4px" }}>{status} </span>
                    )}
                    {status === "closed" && <StyledClose>{status}</StyledClose>}

                    <StyledTicketId>#{id}</StyledTicketId>

                    {/* {allTickets &&
                    allTickets[0].conversation_all_tickets.length > 1 ? (
                      <Dropdown overlay={ticketsMenu} placement="bottomRight">
                        <Transition>
                          <CSSTransition
                            key={id}
                            timeout={10}
                            className="ticketid"
                          >
                            <StyledTicketId>
                              #{id}{" "}
                              <DownArrowIcon style={{ stroke: "#5b5b5b" }} />
                            </StyledTicketId>
                          </CSSTransition>
                        </Transition>
                      </Dropdown>
                    ) : (
                      <Transition>
                        <CSSTransition
                          key={id}
                          timeout={10}
                          className="ticketid"
                        >
                          <StyledTicketId>#{id}</StyledTicketId>
                        </CSSTransition>
                      </Transition>
                    )} */}
                    {ownerName !== null && (
                      <span style={{ fontSize: "14px", color: "#5b5b5b" }}>
                        &nbsp;( Owner : {ownerName} ){" "}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              {ownerStatus ? (
                <StyledDropdownLayer>
                  {(status === "open" || status === "assigned") &&
                    !timerLoading && (
                      <Tooltip title="Ticket Handling Time">
                        <p
                          style={{
                            fontSize: "14px",
                            marginleft: "auto",
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid var(--color-primary)",
                            padding: "4px 10px",
                            borderRadius: "50px",
                          }}
                        >
                          <LuTimer style={{ marginRight: "5px" }} />
                          <span>{String(hours).padStart(2, "0")}</span>:
                          <span>{String(minutes).padStart(2, "0")}</span>:
                          <span>{String(seconds).padStart(2, "0")}</span>
                        </p>
                      </Tooltip>
                    )}
                  {!isOwner && status !== "closed" ? (
                    <div>
                      <Icon
                        component={SubscriberIcon}
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  ) : null}
                  <Fragment>
                    {conversationLoader ? (
                      <Fragment>
                        <Skeleton.Input
                          style={{ width: 100, marginRight: 20 }}
                          active={true}
                        />
                        <Skeleton.Input
                          style={{ width: 100, marginRight: 20 }}
                          active={true}
                        />
                        <Skeleton.Input
                          style={{ width: 100, marginRight: 20 }}
                          active={true}
                        />
                      </Fragment>
                    ) : (
                      <Subscribers
                        disabled={!isOwner}
                        owner_id={owner_id}
                        formerSubscribers={subscribers}
                        id={id}
                        status={status}
                        ownerStatus={ownerStatus}
                        setOwnerStatus={setOwnerStatus}
                      />
                    )}
                  </Fragment>
                  <Dropdown
                    overlay={priorityMenu}
                    placement="bottomRight"
                    disabled={!isOwner}
                    trigger={["click"]}
                  >
                    <Icon component={currentPriority.icon} />
                  </Dropdown>
                  {status !== "closed" && (
                    <Dropdown
                      overlay={statusMenu}
                      placement="bottomRight"
                      disabled={!isOwner}
                      trigger={["click"]}
                    >
                      <MoreIcon />
                    </Dropdown>
                  )}
                </StyledDropdownLayer>
              ) : null}
              {ownerStatus
                ? null
                : agentDetails &&
                  Object.keys(agentDetails).length > 0 &&
                  agentDetails.can_assign_ticket_to_self && (
                    <Fragment>
                      <Button
                        className="assign-me-button"
                        size="small"
                        onClick={handleOwnership}
                      >
                        Assign to me
                      </Button>
                    </Fragment>
                  )}
              <StyledCloseChatIcon
                onClick={() => {
                  dispatch(setActiveConversation(""));
                  history.push("/inbox/tickets");
                }}
              >
                <Tooltip title="Close Chat window" placement="bottom">
                  <AiOutlineClose
                    size={24}
                    style={{
                      margin: "0px 5px",
                      padding: "5px 0px 0px 0px",
                    }}
                  />
                </Tooltip>
              </StyledCloseChatIcon>
            </div>
          </StyledTopLayer>
        </Fragment>
      )}
      <Modal
        visible={followupModel}
        onCancel={() => setFollowupModel(false)}
        centered
        footer={
          <Button
            key="submit"
            style={{
              background: "#0C477D",
              borderRadius: "5px",
              color: "#fff",
            }}
            onClick={handleFollowUp}
          >
            Submit
          </Button>
        }
      >
        <p>Ticket Follow up</p>
        <Divider />
        <p>
          Follow up this ticket in{" "}
          <Select
            defaultValue={followupHours}
            onChange={(value) => setFollowupHours(value)}
          >
            {hoursMenuOptions.map((item) => {
              return (
                <Option key={item.key}>
                  <p>{item.title}</p>
                </Option>
              );
            })}
          </Select>{" "}
          hours
        </p>
      </Modal>
      {session_expiry_at &&
        !conversationLoader &&
        conversation_source === "whatsapp" && (
          <Countdown
            date={new Date(session_expiry_at).getTime()}
            renderer={(e) => renderer(openPanel, e)}
          ></Countdown>
        )}
    </StyledHeader>
  );
});

export default Header;
