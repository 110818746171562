import React from 'react';
import { Image, Button } from 'antd';
import { FileTextOutlined, DownloadOutlined } from '@ant-design/icons';
import { StyledDocument } from './styled';
import AudioAttachment from './AudioAttachment';

function Attachment(props) {
    const { attachment, actor } = props;

    if (attachment.msg_type === 'story_mention' || attachment.msg_type === 'story_replies') {
        if (attachment.content_type === 'image') {
            return (
                <Image
                    id={attachment.id}
                    width={'auto'}
                    height={'auto'}
                    style={{padding: '8px 8px 12px',maxWidth: '350px',borderRadius: '20px',objectFit: 'cover',color: 'red'}}
                    alt=" Media not available"
                    src={attachment.url}
                />
            );
        } else if (attachment.content_type === 'video') {
            return (
                <video width="auto" style={{padding: '8px 8px 24px',maxWidth: '350px',borderRadius: '20px'}} controls preload="metadata">
                    <source id={attachment.id} src={attachment.url} />
                    Media not available
                </video>
            );
        }
    } else if (attachment.content_type === 'image') {
        return (
            <Image
                id={attachment.id}
                style={{padding: '8px 8px 3px',maxWidth: '350px',borderRadius: '17px',objectFit: 'cover'}}
                width={'auto'}
                height={'auto'}
                alt={attachment.name}
                src={attachment.url}
            />
        );
    } else if (attachment.content_type === 'document') {
        if (actor === 'merchant') {
            return (
                <StyledDocument actor={actor}>
                    <div className={`file-button ${actor}`}><div><Button type="primary" shape="circle" href={attachment.url} target="_blank" icon={<DownloadOutlined />} size="medium" /></div></div>
                    <div className={`file-name ${actor}`}>{attachment.file_name}</div>
                    <div className={`file-icon ${actor}`}><FileTextOutlined /></div>
                </StyledDocument>
            )
        } else {
            return (<StyledDocument actor={actor}>
                <div className={`file-icon ${actor}`}><FileTextOutlined /></div>
                <div className={`file-name ${actor}`}>{attachment.file_name}</div>
                <div className={`file-button ${actor}`}><div><Button type="primary" shape="circle" href={attachment.url} target="_blank" icon={<DownloadOutlined />} size="medium" /></div></div>
            </StyledDocument>)
        }
    } else if (attachment.content_type === 'audio') {
        if (actor === 'merchant') {
            return (
                <StyledDocument actor={actor}>
                    {/* <div className={`file-button ${actor}`}><div><Button type="primary" shape="circle" href={attachment.url} target="_blank" icon={<PlayCircleFilled />} size="medium" /></div></div>
                    <div className={`file-name ${actor}`}>{attachment.file_name}</div>
                    <div className={`file-icon ${actor}`}><AudioOutlined /></div> */}
                    <AudioAttachment actor={actor} song={attachment.url} type={attachment.content_type} />
                </StyledDocument>
            )
        } else {
            return (<StyledDocument actor={actor}>
                {/* <div className={`file-icon ${actor}`}><PlayCircleFilled /></div>
                <div className={`file-name ${actor}`}>{attachment.file_name}</div>
                <div className={`file-button ${actor}`}><div><Button type="primary" shape="circle" href={attachment.url} target="_blank" icon={<AudioOutlined />} size="medium" /></div></div> */}
                <AudioAttachment song={attachment.url} type={attachment.content_type} />
            </StyledDocument>)
        }
    } else if (attachment.content_type === 'voice') {
        if (actor === 'merchant') {
            return (
                <StyledDocument actor={actor}>
                    {/* <div className={`file-button ${actor}`}><div><Button type="primary" shape="circle" href={attachment.url} target="_blank" icon={<PlayCircleFilled />} size="medium" /></div></div>
                    <div className={`file-name ${actor}`}>{attachment.file_name}</div>
                    <div className={`file-icon ${actor}`}><AudioOutlined /></div> */}
                    <AudioAttachment song={attachment.url} type={attachment.content_type} />
                </StyledDocument>
            )
        } else {
            return (<StyledDocument actor={actor}>
               {/*  <div className={`file-icon ${actor}`}><PlayCircleFilled /></div>
                <div className={`file-name ${actor}`}>{attachment.file_name}</div>
                <div className={`file-button ${actor}`}><div><Button type="primary" shape="circle" href={attachment.url} target="_blank" icon={<AudioOutlined />} size="medium" /></div></div> */}
                <AudioAttachment song={attachment.url} type={attachment.content_type} />
            </StyledDocument>)
        }
    } else if (attachment.content_type === 'video') {
        return (
            <video width="auto" style={{padding: '8px 8px 3px',maxWidth: '350px',borderRadius: '20px'}} controls preload="metadata">
                <source id={attachment.id} src={attachment.url} />
            </video>
        )
    } else {
        return null;
    }

}

export default React.memo(Attachment);