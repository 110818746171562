import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tag, Collapse } from 'antd';
import { abandonedOrderDataSelector } from '@store-inbox/SummaryPanel/selector';
import OrderBox from '../common/OrderBox';
import { StyledAbandonedListWrapper, StyledOrderHeader } from '../styled';
import { format, parseISO } from 'date-fns';
import { ReactComponent as Empty } from '@assets/icons/ordernil.svg';

const { Panel } = Collapse;

export default function OrdersComponent() {
    const data = useSelector(abandonedOrderDataSelector);
    const [key,setKey] = useState(null);

    const Header = ({ order,index }) => {
    return (
        <StyledOrderHeader>
            <header>{`#${order.checkout_id}`}
            {!(key==index) && <p style={{fontSize: '12px',fontWeight: '400'}}>{format(parseISO(order.created_at), 'do MMM, yyyy')}</p>}
            </header>
            {(key==index) && <p style={{fontSize: '12px',fontWeight: '400'}}>{format(parseISO(order.created_at), 'do MMM, yyyy')}</p>}
            <div style={{marginTop: '-6px'}}>
                {order.is_cod ? <Tag style={{ backgroundColor: 'rgba(219, 37, 37, 0.2)', color: 'rgba(219, 37, 37, 1)' }}>COD</Tag> : null}
                {order.is_cancelled ? <Tag style={{ backgroundColor: 'rgba(228, 158, 20, 0.2)', color: 'rgba(228, 158, 20, 1)' }}>Cancel</Tag> : null}
                {order.fulfillment_status ? <Tag style={{ backgroundColor: 'rgba(22, 172, 55, 0.2)', color: 'rgba(22, 172, 55, 1)' }}>Paid</Tag> : null}
            </div>
            <p style={{fontSize:"12px",textDecoration: 'underline',color: 'var(--color-primary)'}}>{(key==index) ? 'Show Less' : 'More details'}</p>
        </StyledOrderHeader>
    );
}

    const handleCollapse = (e)=>{
        setKey(e);
    }

    if (data) {
        return (
            <StyledAbandonedListWrapper>
                {data.abandoned_checkouts && data.abandoned_checkouts.length === 0 ?
                    <div style={{textAlign: 'center', marginTop: "40px"}}>
                        <Empty style={{marginLeft: '-20px'}}/>
                        <p style={{color: "#474747",fontSize: "26px",fontWeight: "700",margin: "0",marginTop:"10px"}}>No orders!</p>
                        <p style={{color: "#474747",fontSize: "16px",fontWeight: "300"}}>No orders data available for this customer.</p>
                        <p style={{color: "#474747",fontSize: "16px",fontWeight: "300"}}>Try reloading this page.</p>
                    </div>
                    : <Collapse accordion="true" onChange={handleCollapse}>
                        {data.abandoned_checkouts && data.abandoned_checkouts.map((order, i) => {
                            return (
                                <Panel
                                    key={i}
                                    header={<Header index={i} order={order} />}
                                    showArrow={false}
                                >
                                    <OrderBox key={`abandon-inside${i}`} order={order} isOrder={false} />
                                </Panel>
                            )
                        })}
                    </Collapse>
                }
            </StyledAbandonedListWrapper>
        );
    } else {
        return <div>Loading...</div>
    }
}
