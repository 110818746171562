import {
  createStore,
  applyMiddleware
} from 'redux';

import createSagaMiddleware from 'redux-saga';
import saga from './sagas';

import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));

sagaMiddleware.run(saga);

export default store;