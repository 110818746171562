import React, { useEffect } from "react";
import { Layout } from "antd";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import Inbox from "./components/Inbox";
import { setProfile } from "@store-inbox/action";
import {
  getAgents,
  getConnections,
  getTemplates,
  getMsgsCount,
  getPopularTags,
  getFieldsAction,
  getEmailGroupsAction,
} from "@store-inbox/MainPanel/action";
import Validate from "./Validate";
import { getAllQuickReplies } from "@store-inbox/ConversationPanel/action";

const { Content } = Layout;

function AppRoute(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    const saved = localStorage.getItem("storeState");
    if (!saved) {
      if (process.env.NODE_ENV !== "development") {
        window.location.replace(`${process.env.REACT_APP_ORIGIN}/users/login/`);
      }
    } else {
      dispatch(setProfile(JSON.parse(saved)));
    }
  }, []);

  useEffect(() => {
    dispatch(getAgents());
    dispatch(getConnections());
    dispatch(getAllQuickReplies());
    dispatch(getTemplates());
    dispatch(getMsgsCount({ refresh: false }));
    dispatch(getPopularTags());
    dispatch(getFieldsAction());
    dispatch(getEmailGroupsAction());
  }, [dispatch]);

  return (
    <Layout className="layout">
      <Content>
        <Switch>
          <Route exact path="/">
            <Redirect from="/" to="/inbox/" />
          </Route>
          <Route path="/validate">
            <Validate />
          </Route>
          <Route path="/inbox/:id">
            <Inbox />
          </Route>
          <Route path="/inbox">
            <Inbox />
          </Route>
        </Switch>
      </Content>
    </Layout>
  );
}

export default AppRoute;
