import React, { Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { StyledOrderRow, StyledOrderWrapper, StyledOrders, StyledOrderDiscount } from '../styled';

function OrderBox(props) {
    const { order, isOrder } = props;

    return (
        <div>
            <StyledOrderWrapper>
                <StyledOrders>
                    {order.line_items && order.line_items.map((item, i) => {
                        return <StyledOrderRow key={`line-${i}`}>
                            <div>
                                <span className="order-heading">{item.name}</span>
                                <p style={{fontSize: '12px',color:'#6F6F6F'}}>SKU: <span className="order-value" style={{color:'#1F1F1F'}}> {item.sku}</span></p>
                            </div>
                            <span className="order-value value-quantity">₹ {item.price}</span>
                        </StyledOrderRow>
                    })}
                    <StyledOrderDiscount>
                        <StyledOrderRow>
                            <span className="order-heading">Discount <span className="order-value value-discount"><NumberFormat value={Number(order.total_discounts).toFixed(2) ?? ''} displayType={'text'} thousandSeparator={true} decimalScale={2} thousandsGroupStyle="lakh" prefix={'₹ '} /> </span> </span>
                            <span className="order-heading header-total" style={{fontSize: '1em'}}>Total Amount: <span className="order-value value-total"><NumberFormat value={Number(order.total_order_price).toFixed(2) ?? ''} displayType={'text'} thousandSeparator={true} decimalScale={2} thousandsGroupStyle="lakh" prefix={'₹ '} /></span></span>
                        </StyledOrderRow>
                    </StyledOrderDiscount>
                </StyledOrders>
                {isOrder ?
                    <Fragment>
                        <p style={{fontSize: '14px',marginTop: '10px',color:'#000',fontWeight: '500'}}>Shipment Details</p>
                        <StyledOrderRow>
                            <span className="order-heading">Status: <span className="order-value">{order.shipment_details?.fulfillment_status || '-'}</span></span>
                            <span className="order-heading">Courier: <span className="order-value">{order.shipment_details?.shipment_company || '-'}</span></span>
                            <span className="order-heading">AWB: <span className="order-value">{order.shipment_details?.shipment_tracking_id || '-'}</span></span>
                        </StyledOrderRow>
                        {order.shipment_details?.shipment_tracking_url && 
                            <Fragment>
                                <p style={{fontSize: '14px',marginTop: '10px',color:'#000',fontWeight: '500'}}>Tracking Link</p>
                                <span className="order-heading" style={{color: '#6F6F6F'}}>Track: <a href={order.shipment_details?.shipment_tracking_url || '/'} style={{color: '#1d8dcc',textDecoration: 'underline'}} target="_blank" className="order-value">{order.shipment_details?.shipment_tracking_url || ' - '}</a></span>
                            </Fragment>
                        }
                        <p style={{fontSize: '14px',marginTop: '10px',color:'#000',fontWeight: '500'}}>Contact Details</p>
                    </Fragment>
                    : null}
                    <StyledOrderRow style={{display: 'block',marginTop: '-8px'}}>
                        <p style={{fontSize: '1.1em',margin: '0'}} className="order-heading">Email: <span className="order-value">{order.user_email || '-'}</span></p>
                        <p style={{fontSize: '1.1em',margin: '0'}} className="order-heading">Phone: <span className="order-value">{order.user_phone_number || '-'}</span></p>
                    </StyledOrderRow>
            </StyledOrderWrapper>
        </div>
    );
}

export default OrderBox;