import styled from 'styled-components';


export const StyledSearchBar = styled.div`
    display: flex;
    margin: 10px 15px 20px 24px;

    button {
        height: 48px;
        width: 48px;
    }

    .search{
        width: 100%;
        margin: 0 3%;
    }

    .search input{
        font-size: 14px;
        border-radius: 4px !important;
    }
    .search-wrapper{
        display: flex;
        justify-content: space-evenly;
        border: 1px solid #D1D1D1;
        height: 48px;
        border-radius: 4px;
        font-size: 13px;
    }

    .icon-button{
        padding-top: 7px;
    }

    .filter-buttons{
        position: relative;
        stroke: var(--color-primary);
        border: 1px solid #D1D1D1;
        height: 48px;
        width: 48px;     
    }

    .filter-buttons:hover{
        background: rgba(29, 141, 204, 0.2);
        border: none;
    }

    .filter-active-button{
        position: absolute;
        top: 15px;
        left: 18px;
        background-color: transparent;
        width: 0px;
        height: 0px;
        min-width: 0px;
        border-radius: 4px;
    }

    .search-active-button{
        position: relative;
        background-color: transparent;
        width: 0px;
        height: 0px;
        min-width: 0px;
    }

`;