import styled from 'styled-components';

export const StyledChat = styled.div`
    height: 100%;
    overflow: scroll;
    z-index: -1000;

    .ant-collapse-item{
        :hover{
            background: #BFDFF1;
        }
    }
    .ant-collapse-item-active{
        border: 1px solid #1D8DCC80 !important;
        :hover{
            background: white;
        }
        .preview{
            display: none;
        }
    }
`

export const StyledTemplateNumber = styled.p`
    position: absolute;
    top: 12px;
    border-right: 1px solid #d1d1d1;
    padding: 3px 10px;
    font-size: 14px;
    z-index: 10;
    color: #333333;
    font-Weight: 400;
`

export const StyledHeader = styled.div`
    max-height: 80px;
    overflow: hidden;
    
    .ant-collapse-item-active{
        .preview{
            display: none;
        }
    }

    p{
        font-size: 15px;
        color: #1F1F1F;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .preview{
        font-size: 12px;
        color: #6F6F6F;
    }
`

export const StyledButton = styled.button`
    background: ${props=>props.disabled ? "#6E8E9F" : "#1D8DCC"};
    width: 100%;
    color: #fff;
    border-radius: 6px;
    height: 40px;
    margin-top: 10px;
    box-shadow: none;
    cursor: pointer;
`

export const StyledText = styled.p`
    white-space: pre-wrap;
    font-weight: 400;
    color: #333333;
    font-size: 14px;

    span{
        color: var(--color-primary) !important;
    }

`