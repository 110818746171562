import styled from 'styled-components';

export const StyledMenuWrapper = styled.div`
    width: 100%;
    height: 100vh;
    padding: 30px 0px 0px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #d1d1d1;

    .ant-menu-vertical{
        border: none !important;
    }

    .ant-menu-title-content{
        > div{
            width: 100%;
        }
    }

    .menu-item{
        :hover{
            background: #DDEEF8;
        }
    }
`;

export const StyledHoverMenu = styled.div`
    padding: 0px 0px;
    width: 100%;
    font-weight: 700;
    color: var(--color-primary);
    font-size: 13px;
`;