import styled from 'styled-components';

export const StyledAgentsInput = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;

export const StyledDropdownLayer = styled.div`
  display : flex;
  align-items: center;

  .tooltip{
    font-size: 50px;
    cursor: pointer;
    
    :hover{
      background: #EBF0F4;
      border-radius: 50%;
    }
  }
  
`;

export const StyledDropdownIcon = styled.div`
    display: flex;
    align-items: center;
    font-size: 2.5em;
    margin-top: 5px;
`;

export const StyledDisabledAgents = styled.div`
  .ant-tag{
    background-color:#C8DEEB;
    color: #4790BB;
    margin: 0 6px 6px 0;
    padding: 0px 5px;
    font-size:0.8em;
    border-radius: 5px;
  }
`;

export const StyledSubscriberIcon = styled.div`
  .agent-tooltip{
    cursor: pointer;
    margin-top: 2px;
    height: 50px;
    min-width: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    :hover{
      border-radius: 50%;
      background: #EBF0F4;
      
    }
  }
`;

export const StyledSource = styled.img`
    position: absolute;
    bottom: ${props => props.source && props.source === "whatsapp" ? "35px" : "15px"};
    left: 43px;
    z-index: 40;
    height: 20px;
`

export const StyledClose = styled.span`
    color: var(--color-red) !important;
    padding: 2px 0px !important;
    margin-right: 4px !important;
`

export const StyledTicketId = styled.span`
    animation: bounce 1s ease-in-out;
    display: block;

    @keyframes bounce {
      0% {
        opacity: 0;
        transform: scale(1,1);
        transform: translateY(10px);
      }
      50%{
        opacity: 1;
        transform: scale(1.2,1.2);
      }
      100% {
        transform: translateY(0px);
        transform: scale(1,1);
      }
    }
`