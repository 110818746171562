import React, { Fragment, useState } from "react";
import CustomerDetails from "./CustomerDetails/CustomerDetails.component";
import OrdersPanel from "./Orders/Orders.Tab.component";
import Scrollbar from "@components-inbox/common/Scrollbar";
import { StyledSummaryWrapper, StyledFlexWrapper } from "./styled";
import { Collapse, Divider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { currentActiveConversationSelector } from "@store-inbox/ConversationPanel/selector";
import Labels from "./Labels";
import LinkTickets from "./LinkTickets";
import Notes from "./Notes";

const DetailPanel = ({ defaultKey }) => {
  const { Panel } = Collapse;
  const [keyValue, setKeyValue] = useState(null);
  const customerId = useSelector(currentActiveConversationSelector);

  const Header = () => {
    return (
      <Fragment>
        {keyValue == 3 && customerId ? (
          <div style={{ height: "20px" }}></div>
        ) : (
          <div
            style={{ fontSize: "18px", color: "#474747", fontWeight: "400" }}
          >
            Orders{" "}
            <span style={{ marginLeft: "20px" }}>Abandoned Checkouts</span>
          </div>
        )}
      </Fragment>
    );
  };

  const handleKey = (e) => {
    setKeyValue(e);
  };

  return (
    <StyledSummaryWrapper>
      <Scrollbar>
        <StyledFlexWrapper>
          <Collapse
            defaultActiveKey={defaultKey}
            ghost="true"
            accordion="true"
            onChange={handleKey}
            expandIconPosition="right"
            bordered={false}
            expandIcon={({ isActive }) => (
              <DownOutlined rotate={isActive ? -180 : 0} />
            )}
          >
            <Panel
              header={
                <span
                  style={{
                    fontSize: "18px",
                    color: "#474747",
                    fontWeight: "400",
                  }}
                >
                  Profile
                </span>
              }
              key="1"
            >
              <CustomerDetails />
            </Panel>
            <Divider key="2" />
            <Panel header={<Header />} key="3">
              <OrdersPanel />
            </Panel>
            <Divider key="4" />
            <Panel
              header={
                <span
                  style={{
                    fontSize: "18px",
                    color: "#474747",
                    fontWeight: "400",
                  }}
                >
                  Custom Fields
                </span>
              }
              key="5"
            >
              <Labels />
            </Panel>
            <Divider key="6" />
            <Panel
              header={
                <span
                  style={{
                    fontSize: "18px",
                    color: "#474747",
                    fontWeight: "400",
                  }}
                >
                  Linked Tickets
                </span>
              }
              key="7"
            >
              <LinkTickets />
            </Panel>
            <Divider key="8" />
            <Panel
              header={
                <span
                  style={{
                    fontSize: "18px",
                    color: "#474747",
                    fontWeight: "400",
                  }}
                >
                  Internal Notes/Comments
                </span>
              }
              key="9"
            >
              <Notes />
            </Panel>
          </Collapse>
        </StyledFlexWrapper>
      </Scrollbar>
    </StyledSummaryWrapper>
  );
};

export default DetailPanel;
