import React from 'react';
import OrderChart from './OrderChart.component';
import ACChart from './ACChart.component';
import { StyledOrderStatus } from './styled';

function OrderStatus(props) {
    const { analytics = {}, isConversationsLoading } = props;
    return (
        <StyledOrderStatus>
            <ACChart loading={isConversationsLoading} analytics={analytics} />
            <OrderChart loading={isConversationsLoading} analytics={analytics} />
        </StyledOrderStatus>
    );
}

export default OrderStatus;