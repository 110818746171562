import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { derivedTemplates } from "./utils";
import { Button, Collapse, Divider, Input, Modal, Skeleton } from "antd";
import {
  DownOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  initiateConversation,
  falseinitiated,
  getTemplates,
} from "@store-inbox/MainPanel/action";
import {
  StyledButton,
  StyledChat,
  StyledHeader,
  StyledTemplateNumber,
  StyledText,
} from "./styled";
import { toast } from "react-toastify";
import ReactHtmlParser from "react-html-parser";
import {
  initiationSelector,
  templatesLoadingSelector,
} from "@store-inbox/MainPanel/selector";
import { useParams } from "react-router-dom";
import { setActiveConversationBG } from "@store-inbox/ConversationPanel/action";

function InitiateChat(props) {
  const {
    template,
    phone,
    setNewChat,
    setTemplate,
    selectedArray,
    setSelectedArray,
  } = props;
  const allTemplates = useSelector(derivedTemplates);
  const { Panel } = Collapse;
  const matcher = /{{(.*?)}}/g;
  const [text, setText] = useState("");
  const [newContent, setNewContent] = useState();
  const [value, setValue] = useState([]);
  const [keys, setKeys] = useState("");
  const inputValue = useRef(null);
  const [showContent, setShowContent] = useState(false);
  const initiated = useSelector(initiationSelector);
  const [searchTerm, setSearchTerm] = useState("");
  const [sendingChat, setSendingChat] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(false);
  const loading = useSelector(templatesLoadingSelector);
  const { id } = useParams();

  const dispatch = useDispatch();

  const panelChange = (e) => {
    setValue([]);
    setShowContent(false);
  };

  const handleText = (e, content) => {
    setNewContent(content);
    setValue({ ...value, [e.target.name]: e.target.value });
    setShowContent(true);
  };

  function displayText(template, data) {
    Object.keys(data).forEach((key) => {
      template = template.replaceAll(
        key,
        `<span className="replaced-text">${data[key]}</span>`
      );
    });
    return template;
  }

  useEffect(() => {
    setText(displayText(newContent, value));
    setKeys(Object.keys(value).sort((a, b) => (a > b ? 1 : -1)));
  }, [value]);

  const handleSubmit = (id) => {
    setSendingChat(true);
    setSendDisabled(true);
    dispatch(
      initiateConversation({
        template: id,
        parameters: value.length == 0 ? {} : value,
        phone: selectedArray.length > 0 ? null : String(phone),
        media_url: "",
        button_url: "",
        ticket_ids: selectedArray,
      })
    );
  };

  useEffect(() => {
    const keys = Object.keys(initiated);
    if (keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        if (initiated[keys[i]].msg_sent) {
          toast.success(
            `Message has been sent to ${keys[i].length < 9 ? "#" : ""}${
              keys[i]
            }`,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            }
          );
          dispatch(setActiveConversationBG(id));
        } else {
          toast.error(
            ` ${initiated[keys[i]].error} - ${keys[i].length < 9 ? "#" : ""}${
              keys[i]
            }`,
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            }
          );
        }
      }
      setNewChat(false);
      dispatch(falseinitiated());
      setSelectedArray([]);
      setSendingChat(false);
      setSendDisabled(false);
    }
  }, [initiated]);

  toast.configure();

  const Header = ({ title, content }) => {
    return (
      <StyledHeader>
        <p>{title}</p>
        <span className="preview">{content}</span>
      </StyledHeader>
    );
  };

  const Title = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ whiteSpace: "nowrap" }}>Select a template</span>
        {selectedArray.length > 0 ? (
          <p
            style={{
              marginBottom: "0px",
              fontSize: "12px",
              marginLeft: "10px",
              color: "#6F6F6F",
              fontWeight: "300",
              marginRight: "25px",
            }}
          >
            Message will be sent to ticket ids:{" "}
            {selectedArray.map((ids, index) => {
              return (
                <span key={ids}>
                  {index ? ", " : ""} #{ids}
                </span>
              );
            })}
          </p>
        ) : (
          <p
            style={{
              marginBottom: "0px",
              fontSize: "12px",
              marginLeft: "1rem",
              color: "#6F6F6F",
              fontWeight: "300",
            }}
          >
            Message will be sent to Ph: {phone}
          </p>
        )}
      </div>
    );
  };

  const refreshList = () => {
    dispatch(getTemplates());
  };

  return (
    <StyledChat>
      {template ? (
        <Modal
          footer={false}
          centered
          title={<Title />}
          className="template-modal"
          style={{ height: "85vh" }}
          visible={template}
          onCancel={() => {
            setTemplate(false);
            setNewChat(false);
            setSelectedArray([]);
          }}
        >
          <a
            href={`${process.env.REACT_APP_ORIGIN}/settings/#whatsAppSettings/`}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
              padding: "15px 10px",
              borderRadius: "4px",
              boxShadow: "0px 2px 4px #6f6f6f99",
              cursor: "pointer",
            }}
          >
            <PlusCircleOutlined />
            <span style={{ marginLeft: "5px", textWrap: 'nowrap' }}>Create a new template</span>
            <span
              style={{ marginLeft: "10px", fontSize: "12px", color: "#6f6f6f" }}
            >
              (Select <span style={{fontWeight: '700'}}>Initiate Chat</span> event to create the template)
            </span>
          </a>

          <div style={{ display: "flex" }}>
            <Input
              type="text"
              style={{ width: "80%", height: "50px", borderRadius: "4px" }}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search template name"
            />
            <Button
              style={{ height: "50px", marginLeft: "20px" }}
              icon={<ReloadOutlined style={{ transform: "scaleX(-1)" }} />}
              onClick={() => refreshList()}
            >
              Refresh List
            </Button>
          </div>
          {loading ? (
            <div>
              <Skeleton style={{ margin: "1rem 0px" }} />
              <Skeleton style={{ margin: "1rem 0px" }} />
              <Skeleton style={{ margin: "1rem 0px" }} />
            </div>
          ) : (
            <div
              style={{ overflow: "scroll", height: "65vh", marginTop: "10px" }}
            >
              <Collapse
                onChange={panelChange}
                ghost
                accordion="true"
                expandIconPosition="right"
                bordered={false}
                expandIcon={({ isActive }) => (
                  <DownOutlined rotate={isActive ? -180 : 0} />
                )}
              >
                {allTemplates
                  .filter((template) => {
                    if (searchTerm == "") {
                      return template;
                    } else if (
                      template.template_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return template;
                    }
                  })
                  .map((template, i) => {
                    return (
                      <Fragment>
                        <Panel
                          key={i}
                          header={
                            <Header
                              title={template.template_name}
                              content={template.content}
                            />
                          }
                        >
                          <StyledText>
                            {!showContent
                              ? template.content
                              : ReactHtmlParser(text)}
                          </StyledText>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexWrap: "wrap",
                            }}
                          >
                            {template.content &&
                              template.content !== null &&
                              template.content.match(matcher) &&
                              [...new Set(template.content.match(matcher))].map(
                                (key) => {
                                  return (
                                    <div
                                      style={{
                                        width: "48%",
                                        position: "relative",
                                      }}
                                      key={key}
                                    >
                                      <StyledTemplateNumber>
                                        {key}
                                      </StyledTemplateNumber>
                                      <Input
                                        ref={inputValue}
                                        type="text"
                                        style={{
                                          padding: "5px 10px 5px 60px",
                                          margin: "10px 0px",
                                        }}
                                        name={key}
                                        onChange={(e) =>
                                          handleText(e, template.content)
                                        }
                                      />
                                    </div>
                                  );
                                }
                              )}
                          </div>
                          <StyledButton
                            onClick={() => handleSubmit(template.id)}
                            disabled={
                              (
                                [...new Set(template.content.match(matcher))] ||
                                []
                              ).length !== keys.length || sendDisabled
                                ? true
                                : false
                            }
                          >
                            {sendingChat ? "Sending..." : "Send"}
                          </StyledButton>
                        </Panel>
                        <Divider />
                      </Fragment>
                    );
                  })}
              </Collapse>
            </div>
          )}
        </Modal>
      ) : (
        <p
          style={{
            color: "#1f1f1f",
            fontSize: "20px",
            fontWeight: "400",
            textAlign: "center",
            padding: "20px 40px",
          }}
        >
          Enter phone number and select a template to initiate conversation
        </p>
      )}
    </StyledChat>
  );
}

export default InitiateChat;
