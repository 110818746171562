import some from 'lodash/some';

export const updateNewMessage = (conversations, newConversation, success, error) => {
    const tempIdPresent = some(conversations, ['temp_id', newConversation.temp_id]);
    if (tempIdPresent) {
        return conversations.map((content) => {
            if (content.temp_id == newConversation.temp_id) {
                return { ...newConversation, ...content, success, error, send_status: 'sent' }
            }
            return content;
        })
    } else {
        const messagePresent = some(conversations, ['id', newConversation.id]);
        if (messagePresent) {
            return conversations.map((content) => {
                if (content.id == newConversation.id) {
                    return { ...content, id: newConversation.id, success, error, send_status: 'sent' }
                }
                return content;
            })
        } else {
            conversations[conversations.length - 1].success = success;
            conversations[conversations.length - 1].error = error;
            conversations[conversations.length - 1].send_status = 'not_sent';
            return [ ...conversations ];
        }
    }
};