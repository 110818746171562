import styled from 'styled-components';

export const FilterWrapper = styled.div`
    display:flex;
    flex-direction: column;
    font-size: 0.7rem;

    .ant-form-item{
        margin-bottom: 0;
    }

    fieldset{
        margin:5px;
    }

    span{
        font-size: 0.7rem;
    }

    .rdrInputRange:nth-child(2){
        display:none;
    }

    .ant-picker-input > input{
        ::placeholder, ::-webkit-input-placeholder, ::-moz-placeholder{
          font-size: 14px !important;
          color: #959595 !important;
        }
    }
`;

export const StyledRadioDivider = styled.div`
    display:flex;
    width:100%;
`;

export const FilterTitle = styled.p`
        font-size: 1rem;
        font-weight: 200;
        margin: 15px 0px 5px;
        color: #474747;
`

export const FilterElements = styled.div`
    height: 68vh;
    padding: 0px 15px;
    overflow: auto;
`

export const StyledButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 10px 0px;
    margin-top: 20px;

    p{
        font-size: 15px;
        margin-top: 10px !important;
        color: #474747;
    }

    span{
        font-size: 15px;
    }

    .filter-button{
        background: var(--color-primary);
        color: #fff;
        height: 2.5rem;
        width: 8.75rem;
        margin-left: auto;
        font-size: 15px;
    }

`;

export const FilterHeading = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 15px 0;
    
    p{
        font-size: 1.25rem;
        color: #474747;
        font-weight: 600;
    }

    .close{
        cursor: pointer;
    }


`