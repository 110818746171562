import { combineReducers } from 'redux'
import {
    reducer as rootReducer,
    SCOPE as rootScope
} from '@store-inbox/index';
import {
    reducer as mainReducer,
    SCOPE as mainScope
} from '@store-inbox/MainPanel';
import {
    reducer as conversationReducer,
    SCOPE as conversationScope
} from '@store-inbox/ConversationPanel';
import {
    reducer as summaryReducer,
    SCOPE as summaryScope
} from '@store-inbox/SummaryPanel';

const combinedReducer = combineReducers({
    [rootScope]:rootReducer,
    [mainScope]: mainReducer,
    [conversationScope]: conversationReducer,
    [summaryScope]: summaryReducer
})

export default combinedReducer;