import styled from 'styled-components';

export const StyledOrderRow = styled.div`
    display:flex;
    align-content: center;
    justify-content: space-between;
`;
export const StyledOrderBox = styled.div`
    min-height: 400px;
    padding: 10px 0px;

    .spent-total{
        display: flex;
        align-items: baseline;
        justify-content: center;
        font-size: 35px;
        font-weight: 700;
        color: #000;
    }
`;

export const StyledOrderWarnings = styled.div`
    display: flex;
    justify-content: end;
    select{
        width:50%;
        background: #fff;
        border: 0;
        font-weight: 700;
    }

    option{

    }
`;

export const StyledOrders = styled.div`
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
`;

export const StyledEachOrder = styled.div`
    display:flex;
    justify-content: space-between;
`;

export const StyledOrderDiscount = styled.div`
    display:flex;
    flex-direction: column;
    padding-left: 25%;
`;


export const StyledMainDetails = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content:space-around;
    margin-top: 10px;

    .icon-box{
        font-size: 24px;
    }

    .detail-box{
        width: 100%;
        font-size: 1em;
        margin: 0 5% 0 7%;
        .ant-divider{
            margin:5px 0px;
        }

        .detail-title{
            font-size: 0.7em;
            color:  rgba(63, 87, 141, 0.5);
            font-weight: 700;
        }

        .detail-value{
            display: flex;
            color: #474747;
            font-size: 1rem;
            align-items: center;
        }
    }
`;

export const StyledCopyButton = styled.span`
    cursor: pointer;
    padding-left: 5px;
`;

export const StyledMainHeader = styled.div`
    padding: 10px;
    text-align: center;
    margin: 0px;
    > span{
        width: 65px;
        height: 65px;
        line-height: 54px;
        font-size: 25px;
        border: 6px solid #d4d7dc;
        font-weight: 700;
        background: var(--color-primary);
    }
    .ant-skeleton-avatar{
        width: 65px;
        height: 65px;
        border: 6px solid #d4d7dc;
    }
    .main-name{
        font-weight: 700;
        color: #474747;
        margin-left: 2%;
        font-size: 22px;
        text-transform: uppercase;
    }
`;

export const StyledTotalSpent = styled.div`
    text-align:center;
    display:flex;
    align-items:center;
    justify-content:center;
    position: relative;
    margin: 10px;
    color:rgb(93, 167, 151);
    
    .spent-container{
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 10px;
        width:auto;
        background: rgba(93, 167, 151, 0.2);
    }

    .spent{
        position: relative;
        span{
            font-weight: 700;
        }
    }

    .spent-number{
        padding-right: 10px;
        font-size: 1.7em;
        font-weight: 700;
    }

    .spent-text{
        font-size: 0.8em;
        position: absolute;
        bottom: -15px;
        right: 10px;
    }
`;