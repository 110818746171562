import React from 'react';
import { Menu, Popover } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { StyledHoverMenu, StyledMenuWrapper } from './styled';
import { ReactComponent as InsightsIcon } from '@assets/icons/menu/layout.svg';
import { ReactComponent as OrdersIcon } from '@assets/icons/menu/inbox.svg';
import { ReactComponent as AbandonedIcon } from '@assets/icons/menu/checkout.svg';
import { ReactComponent as C2PIcon } from '@assets/icons/menu/dollar-sign.svg';
import { ReactComponent as LocationIcon } from '@assets/icons/menu/map-pin.svg';
import { ReactComponent as ConnectIcon } from '@assets/icons/menu/message-square.svg';
import { ReactComponent as NDRIcon } from '@assets/icons/menu/file-text.svg';
import { ReactComponent as NPSIcon } from '@assets/icons/menu/star.svg';
import { ReactComponent as ReturnIcon } from '@assets/icons/menu/archive.svg';
import { ReactComponent as ChatBotIcon } from '@assets/icons/menu/chatbot.svg';
import { ReactComponent as SettingsIcon } from '@assets/icons/menu/settings.svg';
import { ReactComponent as BroadcastIcon } from '@assets/icons/menu/broadcast.svg';

const AppMenu = () => {
    const inboxContent = (props) => {
        return (<StyledHoverMenu>
            <div className="each-menu">
                <div>{props}</div>
            </div>
        </StyledHoverMenu>);
    }
    
    return (
        <StyledMenuWrapper>
            <Menu
                defaultSelectedKeys={['6']}
                mode="inline"
                className="app-menu"
            >
                <Menu.Item key="1" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Insights")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/reports/dashboard/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={InsightsIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="2" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Orders")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/store/orders/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={OrdersIcon} style={{stroke: '#1f1f1f'}}  />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="3" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Abandoned Checkout")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/reports/dashboard/?d=conversion_reports&c=abandoned_checkout`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={AbandonedIcon} style={{stroke: '#1f1f1f'}}  />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="4" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("COD to Paid")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/reports/c2p-conversions/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={C2PIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="5" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Geo Analysis")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/geo-analysis/dashboard/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={LocationIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="6" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Connect")}>
                        <Link to="#" style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <p style={{color: 'var(--color-primary)',backgroundColor: '#DDEEF8',position: 'absolute',fontSize: '8px',margin:'0',lineHeight:'6px',padding: '3px',right: '2px',top: '0px',fontWeight: '600',zIndex: '999'}}>BETA</p>
                                <Icon component={ConnectIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </Link>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="7" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Schedule Broadcast")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/connect/broadcast/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={BroadcastIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="8" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Non Delivery Reports")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/reports/ndrs/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={NDRIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="9" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Net Promoter Scores")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/reports/nps/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={NPSIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="10" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Return Center")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/return-center/requests/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={ReturnIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="11" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Chatbot")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/chatbot/all/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={ChatBotIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
                <Menu.Item key="12" title="" className='menu-item'>
                    <Popover overlayClassName="menu-popper" placement="right" content={inboxContent("Settings")}>
                        <a href={`${process.env.REACT_APP_ORIGIN}/settings/`} style={{paddingLeft: '20px',width:'65px',display: 'inline-block'}}>
                            <div>
                                <Icon component={SettingsIcon} style={{stroke: '#1f1f1f'}} />
                            </div>
                        </a>
                    </Popover>
                </Menu.Item>
            </Menu>
        </StyledMenuWrapper>
    );
}

export default AppMenu;
