import * as types from "./types";
import * as conversationTypes from "@store-inbox/ConversationPanel/types";

const initialState = {
  orders: {
    loading: false,
    response: {},
    error: null,
  },
  abandonedOrders: {
    loading: false,
    response: {},
    error: null,
  },
  customerDetails: {
    loading: false,
    response: {},
    error: null,
  },
  labels: {
    loading: false,
    response: {},
    error: null,
  },
  linkedTickets: {
    loading: false,
    response: {},
    error: null,
    apiHit: false,
  },
  allTickets: {
    loading: false,
    response: {},
    error: null,
  },
  notes: {
    loading: false,
    response: {},
    error: null,
  },
  ticketTimer: {
    loading: false,
    response: {},
    error: null,
  },
  activeTab: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.INIT:
      return {
        ...state,
      };
    case conversationTypes.ACTIVE_ID:
      return {
        ...state,
        orders: {
          loading: true,
        },
        abandonedOrders: {
          loading: true,
        },
        customerDetails: {
          loading: true,
        },
        allTickets: {
          loading: true,
        },
      };
    case conversationTypes.SET_ACTIVE_CONVERSATION_ID:
      return {
        ...state,
        ticketTimer: {
          loading: true,
        },
      };
    case types.GET_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true,
        },
      };
    case types.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          ...state.orders,
          response: payload,
          loading: false,
          error: null,
        },
      };
    case types.GET_ORDERS_ERROR:
      return {
        ...state,
        orders: {
          ...state.orders,
          error: payload,
          loading: false,
          response: {},
        },
      };
    case types.GET_ABANDONED_ORDERS_SUCCESS:
      return {
        ...state,
        abandonedOrders: {
          ...state.abandonedOrders,
          response: payload,
          loading: false,
          error: null,
        },
      };
    case types.GET_ABANDONED_ORDERS_ERROR:
      return {
        ...state,
        abandonedOrders: {
          ...state.abandonedOrders,
          error: payload,
          loading: false,
          response: {},
        },
      };
    case types.GET_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          loading: true,
        },
      };
    }
    case types.GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          response: payload,
          loading: false,
          error: null,
        },
      };
    case types.GET_CUSTOMER_DETAILS_ERROR:
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          error: payload,
          loading: false,
          response: {},
        },
      };
    case types.GET_CUSTOMER_TICKETS_SUCCESS: {
      return {
        ...state,
        allTickets: {
          ...state.allTickets,
          loading: false,
          response: payload,
          error: null,
        },
      };
    }
    case types.GET_CUSTOMER_TICKETS_ERROR:
      return {
        ...state,
        allTickets: {
          ...state.allTickets,
          loading: false,
          response: payload,
          error: payload,
        },
      };
    case types.GET_FIELD_VALUES_SUCCESS:
      return {
        ...state,
        labels: {
          ...state.labels,
          response: payload,
          loading: false,
          error: null,
        },
      };
    case types.GET_FIELD_VALUES_ERROR:
      return {
        ...state,
        labels: {
          ...state.labels,
          error: payload,
          loading: false,
          response: {},
        },
      };
    case types.POST_TICKET_LABELS:
      return {
        ...state,
        labels: {
          ...state.labels,
          loading: true,
        },
      };
    case types.POST_TICKET_LABELS_SUCCESS:
      return {
        ...state,
        labels: {
          ...state.labels,
          response: payload,
          loading: false,
          error: null,
        },
      };
    case types.POST_TICKET_LABELS_ERROR:
      return {
        ...state,
        labels: {
          ...state.labels,
          error: payload,
          loading: false,
        },
      };
    case types.GET_LINKED_TICKETS_SUCCESS:
      return {
        ...state,
        linkedTickets: {
          ...state.linkedTickets,
          response: payload,
          loading: false,
          error: null,
          apiHit: false,
        },
      };
    case types.GET_LINKED_TICKETS_ERROR:
      return {
        ...state,
        linkedTickets: {
          ...state.linkedTickets,
          error: payload,
          loading: false,
          response: {},
          apiHit: false,
        },
      };
    case types.POST_LINKED_TICKETS:
      return {
        ...state,
        linkedTickets: {
          ...state.linkedTickets,
          loading: true,
          apiHit: false,
        },
      };
    case types.POST_LINKED_TICKETS_SUCCESS:
      return {
        ...state,
        linkedTickets: {
          ...state.linkedTickets,
          loading: false,
          error: null,
          apiHit: true,
        },
      };
    case types.POST_LINKED_TICKETS_ERROR:
      return {
        ...state,
        linkedTickets: {
          ...state.linkedTickets,
          error: payload,
          loading: false,
          apiHit: false,
        },
      };
    case types.GET_NOTES_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          response: payload,
          error: null,
          loading: false,
        },
      };
    case types.GET_NOTES_ERROR:
      return {
        ...state,
        notes: {
          ...state.notes,
          error: payload,
          loading: false,
        },
      };
    case types.TIMER_DATA: {
      return {
        ...state,
        ticketTimer: {
          response: payload.total_time_in_second,
          error: null,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
