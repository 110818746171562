import * as types from "./types";

export const init = () => ({ type: types.INIT });

export const getOrders = (data) => ({
  type: types.GET_ORDERS,
  payload: data,
});

export const getAbandonedOrders = (data) => ({
  type: types.GET_ABANDONED_ORDERS,
  payload: data,
});

export const getLabels = (data) => ({
  type: types.GET_CUSTOMER_LABELS,
  payload: data,
});

export const updateLabelValues = (data) => ({
  type: types.POST_TICKET_LABELS,
  payload: data,
});

export const getLinkedTickets = (data) => ({
  type: types.GET_LINKED_TICKETS,
  payload: data,
});

export const postLinkedTickets = (data) => ({
  type: types.POST_LINKED_TICKETS,
  payload: data,
});

export const getNotes = (data) => ({
  type: types.GET_NOTES,
  payload: data,
});
