import { createSelector } from "reselect";
import { templatesSelector } from '@store-inbox/MainPanel/selector';

export const derivedTemplates = createSelector(templatesSelector, (allTemplates) => {
    let templates = [];
    if (allTemplates && allTemplates.response.length > 0) {
        allTemplates.response.map((template) => {
            templates.push({
                ...template
            });
        });
    }
    return templates;
});