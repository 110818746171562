import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StyledScrollTab } from './styled';
import { StyledOrdersWrapper, StyledEmptyHolder } from '../styled';
import OrdersComponent from './Orders/Orders.component';
import { currentActiveConversationSelector } from '@store-inbox/ConversationPanel/selector';
import AbandonedOrders from './AbandonedOrders/AbandonedOrders.component';
import { Tabs } from 'antd';
import { ReactComponent as Empty } from '@assets/icons/ordernil.svg';

const { TabPane } = Tabs;

const OrdersTab = () => {
    const customerId = useSelector(currentActiveConversationSelector);

    function callback(key) {
        //console.log(key);
    }

    const Heading = ({data})=>{
        return(
            <span style={{fontSize: "18px"}}>{data}</span>
        )
    }

    return (
        <Fragment>
            {!customerId ?
                <div style={{textAlign: 'center', marginTop: "40px"}}>
                        <Empty style={{marginLeft: '-20px'}} />
                        <p style={{color: "#474747",fontSize: "26px",fontWeight: "700",margin: "0",marginTop:"10px"}}>No orders!</p>
                        <p style={{color: "#474747",fontSize: "16px",fontWeight: "300"}}>No orders data available for this customer.</p>
                        <p style={{color: "#474747",fontSize: "16px",fontWeight: "300"}}>Try reloading this page.</p>
                </div> :
                <StyledOrdersWrapper>
                    <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab={<Heading data="Orders" />} key="1">
                            <StyledScrollTab>
                                <OrdersComponent />
                            </StyledScrollTab>
                        </TabPane>
                        <TabPane tab={<Heading data="Abandoned Checkouts" />} key="2">
                            <AbandonedOrders />
                        </TabPane>
                    </Tabs>
                </StyledOrdersWrapper>
            }
        </Fragment>
    );
}

export default OrdersTab;