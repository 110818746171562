import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import { getOrderCalculations } from './utils';
import { StyledChart, StyledLegend, StyledTotal } from './styled';

const initialState = {
    cancelled: 0,
    delivered: 0,
    rtod: 0,
}

function OrderChart(props) {
    const { analytics = {}, loading } = props;
    const [total, setTotal] = useState(0);
    const [percentages, setPercentages] = useState(initialState);
    useEffect(() => {
        if (!loading && analytics.orders_cancelled !== undefined) {
            const totalOrder = analytics.orders_cancelled + analytics.orders_delivered + analytics.orders_rtod;
            setTotal(totalOrder);
            const calculatedPercentages = getOrderCalculations(analytics.orders_cancelled, analytics.orders_delivered, analytics.orders_rtod, totalOrder);
            setPercentages(calculatedPercentages);
        } else {
            setPercentages(initialState);
        }
    }, [analytics, loading]);

    return (
        <StyledChart>
            {/* <Progress type="circle" percent={percentages.cancelled} success={{ percent: percentages.delivered, strokeColor: '#5DA797' }} strokeColor={"#F7A400"} trailColor={percentages.rtod && "#F44336"} format={() => <StyledTotal><div className="total-number">{!loading ? analytics.total_orders : '-'}</div><div>Orders</div></StyledTotal>} /> */}
            <StyledTotal>
                <div className="total-number">{!loading ? total : '-'}</div>
                <div>Orders</div>
            </StyledTotal>
            <div className="legends">
                <StyledLegend>{!loading ? analytics.orders_delivered : '-'} - Delivered</StyledLegend>
                <StyledLegend>{!loading ? analytics.orders_cancelled : '-'} - Cancelled</StyledLegend>
                <StyledLegend>{!loading ? analytics.orders_rtod : '-'} - RTO</StyledLegend>
            </div>
        </StyledChart>
    );
}

export default OrderChart;