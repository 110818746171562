import * as types from "./types";

export const init = () => ({ type: types.INIT });

export const receiveConversations = (data) => ({
  type: types.RECEIVE_CONVERSATIONS,
  payload: data,
});

export const getConversations = (data) => ({
  type: types.GET_CONVERSATIONS,
  payload: data,
});

export const setSearchFilters = (data) => ({
  type: types.SET_CONVERSATIONS_SEARCH_FILTERS,
  payload: data,
});

export const setTabFilters = (data) => ({
  type: types.SET_TAB_FILTERS,
  payload: data,
});

export const getAgents = (payload) => ({
  type: types.GET_AGENTS,
  payload,
});

export const setOnlineStatus = (payload) => ({
  type: types.SET_ONLINE_STATUS,
  payload,
});

export const getOnlineStatus = () => ({
  type: types.GET_STATUS,
});

export const getConnections = (payload) => ({
  type: types.GET_CONNECTIONS,
  payload,
});

export const setPagination = (payload) => ({
  type: types.SET_CONVERSATIONS_PAGINATION,
  payload,
});

export const getTemplates = (payload) => ({
  type: types.GET_TEMPLATES,
  payload,
});

export const initiateConversation = (payload) => ({
  type: types.INITIATE_CONVERSATION,
  payload,
});

export const conversationInitiated = (payload) => ({
  type: types.CONVERSATION_INITIATED,
  payload,
});

export const falseinitiated = (payload) => ({
  type: types.FALSE_INITIATED,
  payload,
});

export const markAsUnread = (payload) => ({
  type: types.MARK_AS_UNREAD,
  payload,
});

export const setSearchFiltersBG = (payload) => ({
  type: types.SET_CONVERSATIONS_SEARCH_FILTERS_BG,
  payload,
});

export const getMsgsCount = (payload) => ({
  type: types.GET_MSGS_COUNT,
  payload,
});

export const msgsCountUpdate = (payload) => ({
  type: types.MSGS_COUNT_UPDATE,
  payload,
});

export const unassignedMsgsCountUpdate = (payload) => ({
  type: types.UNASSIGNED_MSGS_COUNT_UPDATE,
  payload,
});

export const getPopularTags = () => ({ type: types.GET_POPULAR_TAGS });

export const setPopularTags = (payload) => ({
  type: types.SET_POPULAR_TAGS,
  payload,
});

export const refreshStatus = (payload) => ({
  type: types.REFRESH_STATUS,
  payload,
});

export const createNewTicketAction = (payload) => ({
  type: types.CREATE_NEW_TICKET,
  payload,
});

export const getFieldsAction = (payload) => ({
  type: types.GET_FIELDS,
  payload,
});

export const resetMail = (payload) => ({
  type: types.RESET_MAIL,
  payload,
});

export const initiateMail = (payload) => ({
  type: types.INITIATE_MAIL,
  payload,
});

export const getEmailGroupsAction = (payload) => ({
  type: types.GET_EMAIL_GROUPS,
  payload,
});

export const updateTimer = (payload) => ({
  type: types.UPDATE_TIMER,
  payload
})