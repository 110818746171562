import styled from 'styled-components';


export const StyledOnlineStatusWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 200;
    margin: 0px 15px;
    
    p{
        margin: 2rem 0.6rem 1.5rem !important;
        font-size: 24px;
        color: #1F1F1F;
    }

    @media (max-width: 1600px){
        p{
            font-size: 20px;
        }
    }
    
 
    .ant-switch{
        background-color :var(--color-red);
        width: 32px;
        height: 18px;
    }

    .ant-switch-checked{
        background-color :#16AC37;
    }

    .ant-switch-handle{
        width: 12px;
        height: 12px;
        left: 4px;
        top: 3px;
    }

    .ant-switch-checked .ant-switch-handle{
        left: 28px;
    }

    @keyframes rotate360 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
      
    .rotate-icon {
        animation: rotate360 2s linear infinite;
    }
`;
