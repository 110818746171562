import { createSelector } from "reselect";
import { conversationDataSelector } from '@store-inbox/ConversationPanel/selector';
import { agentsSelector } from '@store-inbox/MainPanel/selector';

export const SUPPORTED_AUDIO_TYPES = [
    'audio/aac', 'audio/mp4', 'audio/amr', 'audio/mpeg', 'audio/ogg; codecs=opus',
]

export const SUPPORTED_IMAGE_TYPES = [
    'image/jpeg', 'image/png', 'image/svg'
]

export const SUPPORTED_VIDEO_TYPES = [
    'video/mp4', 'video/3gpp',
]

export const SUPPORTED_DOCUMENT_TYPES = [
    'application/pdf', 'text/csv', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint', 'application/msword',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text'
]

export const derivedConversationDataSelector = createSelector(conversationDataSelector, (conversationData) => {
    let conversations = [];
    if (conversationData && conversationData.data) {
        // for(let i=0;i<conversationData.data.tickets.length;i++){
            for(let j=0;j<conversationData.data.tickets[0].content.length;j++){
                conversations.push(conversationData.data.tickets[0].content[j]);
            }
        // }
    }
    conversations.sort(function(a, b) {
        var keyA = new Date(a.created_at),
          keyB = new Date(b.created_at);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    return conversations;
});

export const derivedAllTickets = createSelector(conversationDataSelector, (conversationData) => {
    let allTickets;
    if (conversationData && conversationData.data) {
        allTickets = conversationData.data.tickets;
    }
    return allTickets;
});

export const derivedTicketDataSelector = createSelector(conversationDataSelector, (conversationData) => {
    let ticket = {};
    if (conversationData && conversationData.data) {
        ticket = conversationData.data.tickets[conversationData.data.tickets.length-1] || {};
    }
    return ticket;
});

export const derivedConversationSource = createSelector(conversationDataSelector, (conversationData)=>{
    let source = "";
    if (conversationData && conversationData.data) {
        source = conversationData.data.conversation_source || "";
    }
    return source;
})

export const derivedMerchantId = createSelector(conversationDataSelector, (conversationData)=>{
    let source_id = "";
    if (conversationData && conversationData.data) {
        source_id = conversationData.data.conv_source_acc_id || "";
    }
    return source_id;
})

export const derivedSubject = createSelector(conversationDataSelector, (conversationData)=>{
    let subject = "";
    if (conversationData && conversationData.data) {
        subject = conversationData.data.subject || "";
    }
    return subject;
})

export const derivedConversationId = createSelector(conversationDataSelector, (conversationData)=>{
    let id = "";
    if (conversationData && conversationData.data) {
        id = conversationData.data.conversation_id || "";
    }
    return id;
})

export const derivedFullNameDataSelector = createSelector(conversationDataSelector, (conversationData) => {
    let fullName = '';
    if (conversationData && conversationData.data) {
        fullName = conversationData.data.full_name || '';
    }
    return fullName;
});

export const derivedAllTags = createSelector(conversationDataSelector, (conversationData) => {
    let allTags = [];
    if(conversationData && conversationData.data) {
        for (let i=0; i<conversationData.data.tickets.length;i++){
            allTags.push({
                ticket_id: conversationData.data.tickets[i].id,
                tags: conversationData.data.tickets[i].tags,
            })
        }
    }
    return allTags;
})

export const derivedAllAgentsDataSelector = createSelector(agentsSelector, (agentsData) => {
    let agents = [];
    if (agentsData && agentsData.response.length > 0) {
        agentsData.response.map((agent) => {
            agents.push({
                ...agent,
                name: agent.first_name + ' ' + agent.last_name,
            });
        });
    }
    return agents;
});

export const acceptedFileTypes = `image/jpeg, image/svg, image/png,application/pdf,text/csv,video/mp4,video/3gpp,
audio/aac, audio/mp4, audio/amr, audio/mpeg, audio/ogg; codecs=opus,
application/vnd.openxmlformats-officedocument.presentationml.presentation,
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
application/vnd.openxmlformats-officedocument.wordprocessingml.document,
application/vnd.ms-powerpoint,application/msword,
application/vnd.oasis.opendocument.presentation,
application/vnd.oasis.opendocument.spreadsheet,
application/vnd.oasis.opendocument.text`;


export const fileValidator = (file) => {
    let maxSize;
    let fileType;
    if (SUPPORTED_AUDIO_TYPES.includes(file.type)) {
        fileType = "Audio";
        maxSize = 16 * 1000 * 1000;
    } else if (SUPPORTED_IMAGE_TYPES.includes(file.type)) {
        fileType = "Image";
        maxSize = 5 * 1000 * 1000;
    } else if (SUPPORTED_VIDEO_TYPES.includes(file.type)) {
        fileType = "Video";
        maxSize = 16 * 1000 * 1000;
    } else if (SUPPORTED_DOCUMENT_TYPES.includes(file.type)) {
        fileType = "Document";
        maxSize = 100 * 1000 * 1000;
    } else {
        return null;
    }
    if (file.size > maxSize) {
        return {
            code: "size-too-large",
            fileType,
            size: maxSize / 1000000,
        };
    }
    return null
}