import React, { Fragment, useEffect, useRef } from 'react';
import { ReactComponent as AttachmentIcon } from '@assets/icons/message/paperclip.svg';
import { ReactComponent as FileIcon } from '@assets/icons/message/docs.svg';
import { ReactComponent as SendIcon } from '@assets/icons/message/send.svg';
import { Result, Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setUploadFile, setEmailUploadFile } from '@store-inbox/ConversationPanel/action';
import { useState } from 'react';
import { StyledUploadComponent } from '../styled';
import { ReactComponent as MusicIcon } from '@assets/icons/message/headphones.svg'
import ReactPlayer from 'react-player'
import { uploadStatus } from '@store-inbox/ConversationPanel/selector';
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/react";
import { toast } from 'react-toastify';
import Carousel from 'react-grid-carousel';

export default function Attachment({ownerStatus, ticket, conversationSource}){

    const dispatch = useDispatch();
    const [file, setFile] = useState([]);
    const [upload, setUpload] = useState(false);
    const [preview,setPreview] = useState([]);
    const [fileType,setFileType] = useState(null);
    const [videoURL,setVideoURL] = useState([]);
    const uploadLoad = useSelector(uploadStatus);
    const [uploadingStatus, setUploadingStatus] = useState(false);
    const previewRef = useRef(null);
    toast.configure();

    const startUpload = () => {
        let formData = new FormData();
        // formData.append('file', file);
        for (let i = 0; i < file.length; i++) {
            formData.append(String(`${i}`), file[i])
        }
        if(conversationSource !== "email"){
            dispatch(setUploadFile({
                files: formData,
            }))
        }else{
            dispatch(setEmailUploadFile({
                files: formData,
            }))
        }
    };

    const handleSubmit = (e)=>{
        if(conversationSource !== "email"){
            if(e.target.files.length<6){
                setFile(e.target.files);
                setFileType(e.target.files[0].type);
                setUpload(true);
                // if((e.target.files[0].type).indexOf("video")!==-1){
                //     setVideoURL(URL.createObjectURL(new Blob([e.target.files[0]], {type: Blob})));
                // }
                if(e.target.files && e.target.files[0].type.indexOf("image")!==-1){
                    let tempArray = [];
                    for (let i = 0; i < e.target.files.length; i++) {
                        let reader = new FileReader();
                        reader.onloadend = function (e){
                            tempArray.push(e.target.result);
                            setPreview([...tempArray]);
                        }
                        reader.readAsDataURL(e.target.files[i]);
                    }
                }else if(e.target.files && e.target.files[0].type.indexOf("video"!==-1)){
                    let tempArray = [];
                    for (let i = 0; i < e.target.files.length; i++) {
                        tempArray.push(URL.createObjectURL(new Blob([e.target.files[i]], {type: Blob})));
                        setVideoURL([...tempArray]);
                    }
                }else{
                    setPreview([]);
                    setVideoURL([]);
                }
            }else{
                toast.error("You cannot send more than 5 files at a time",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            }
        }else{
            setFile(e.target.files);
            setFileType(e.target.files[0].type);
            setUpload(true);
            for (let i=0;i<e.target.files.length;i++){
                if(e.target.files[i].size > 25000000){
                    toast.error("File size exceeds maximum limit of 25 MB",{
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                    });
                    setFile([]);
                    setUpload(false);
                }
            }
            if(e.target.files && e.target.files[0].type.indexOf("image")!==-1){
                let tempArray = [];
                for (let i = 0; i < e.target.files.length; i++) {
                    let reader = new FileReader();
                    reader.onloadend = function (e){
                        tempArray.push(e.target.result);
                        setPreview([...tempArray]);
                    }
                    reader.readAsDataURL(e.target.files[i]);
                }
            }else if(e.target.files && e.target.files[0].type.indexOf("video"!==-1)){
                let tempArray = [];
                for (let i = 0; i < e.target.files.length; i++) {
                    tempArray.push(URL.createObjectURL(new Blob([e.target.files[i]], {type: Blob})));
                    setVideoURL([...tempArray]);
                }
            }else{
                setPreview([]);
                setVideoURL([]);
            }
        }
    }

    const acceptedFilesTypes = 'image/jpeg, image/svg, image/png,application/pdf,text/csv,video/mp4,video/3gpp, audio/aac, audio/mp4, audio/amr, audio/mpeg, audio/ogg; codecs=opus, application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/msword,application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text';

    const handleCloseUpload = () => {
        setFile(null);
        setUpload(false);
    };

    const handleUpload = () => {
        startUpload();
        setUploadingStatus(true);
    };

    useEffect(()=>{
        if(uploadLoad && uploadLoad !== null){
            setFile(null);
            setUpload(false);
            setUploadingStatus(false);
            if(uploadLoad == "error"){
                toast.error("Error. Try uploading again",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                });
            }
        }
    },[uploadLoad])

    const AcceptedText = () => {
        // console.log(file);
        return <p>Sending <span>{file.name}</span></p>;
    }

    const override = css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
    `;

    return(
        <div>
            {ownerStatus ?
                upload ? <StyledUploadComponent conversationSource={conversationSource}>
                    <div>
                        <Button onClick={handleCloseUpload} className='close-button' type='link'>x</Button>
                    {/*  <Result
                            icon={<FileIcon />}
                            title={<AcceptedText ticket={ticket} file={file} />}
                            extra={
                                <Button size="large" type="primary" icon={<FileAddOutlined />} onClick={handleUpload}>
                                    Send File
                                </Button>
                            }
                        /> */}
                        {uploadingStatus && previewRef && previewRef.current !== null && 
                            <div style={{width: previewRef.current.clientWidth,height: previewRef.current.clientHeight,background: 'rgba(0,0,0,0.8)',position: 'absolute',top: previewRef.current.offsetTop,zIndex: '1',left: previewRef.current.offsetLeft}}>
                                <BarLoader color="#1d8dcc" loading={uploadingStatus} speedMultiplier="0.75" width="220px" height="4px" css={override} /> 
                            </div>
                        }
                        {file.length == 1 && fileType.indexOf("image")===-1 && <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginBottom: '20px'}}>{file[0].name}</p>}
                        <div ref={previewRef}>
                            {(preview && fileType.indexOf("image")!==-1) ? <Carousel cols={1} rows={1} gap={10} containerStyle={{width: '600px'}}>{preview && preview.map((prev, i)=>{
                                if(file[i] != undefined){
                                    return(
                                        <Carousel.Item key={i}>
                                            <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginBottom: '20px'}}>{file[i].name}</p>
                                            <img key={i} style={{margin: '20px auto',display :'block',maxHeight: '400px',objectFit: 'contain',maxWidth: '500px'}} src={prev} alt="Preview" />
                                            <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginTop: '10px'}}>{file[i].size < 1000000 ? Math.round(((file[i].size/1000)*100))/100 + ' KB' : Math.round(((file[i].size/1000000)*100))/100 + ' MB'}</p>
                                        </Carousel.Item>
                                    )
                                }
                            })}</Carousel> : (file.length === 1 && fileType.indexOf("video")!==-1) ? <ReactPlayer controls url={videoURL[0]} style={{margin: '20px',width: '640px',height: '360px'}} /> : (file && file.length > 1 && fileType.indexOf("video")!==-1) ? <Carousel cols={1} rows={1} gap={10} containerStyle={{width: '700px'}}>
                            {
                                videoURL.map((src, i)=>{
                                    return(
                                        <Carousel.Item key={i}>
                                            <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginBottom: '20px'}}>{file[i].name}</p>
                                            <ReactPlayer controls url={src} style={{width: '500px'}} />
                                            <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginTop: '10px'}}>{file[i].size < 1000000 ? Math.round(((file[i].size/1000)*100))/100 + ' KB' : Math.round(((file[i].size/1000000)*100))/100 + ' MB'}</p>
                                        </Carousel.Item>
                                    )
                                })
                            }
                            </Carousel>:
                            (file.length === 1 && fileType.indexOf("audio")!==-1) ? <MusicIcon style={{width: '300px',height: '300px',stroke:"#fff",background: "#1D8DCC",padding: "10px",margin: "0px 0"}} /> : (file && file.length > 1 && fileType.indexOf("audio")!==-1) ? <Carousel cols={1} rows={1} gap={10}>
                                {
                                    Object.keys(file).map((i)=>{
                                        return (
                                            <Carousel.Item key={i}>
                                                <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginBottom: '20px'}}>{file[i].name}</p>
                                                <MusicIcon style={{width: '300px',height: '300px',stroke:"#fff",background: "#1D8DCC",padding: "10px",margin: "0px auto",display: 'block'}} />
                                                <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginTop: '10px'}}>{file[i].size < 1000000 ? Math.round(((file[i].size/1000)*100))/100 + ' KB' : Math.round(((file[i].size/1000000)*100))/100 + ' MB'}</p>
                                            </Carousel.Item>
                                        )
                                    })
                                }</Carousel> :
                                (file.length == 1) ? <Result icon={<FileIcon style={{width: '200px',height: '200px'}} />} /> : <Carousel>
                                {
                                    Object.keys(file).map((i)=>{
                                        return (
                                            <Carousel.Item key={i}>
                                                <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400'}}>{file[i].name}</p>
                                                <Result icon={<FileIcon style={{width: '200px',height: '200px'}} />} />
                                                <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400'}}>{file[i].size < 1000000 ? Math.round(((file[i].size/1000)*100))/100 + ' KB' : Math.round(((file[i].size/1000000)*100))/100 + ' MB'}</p>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                                </Carousel>
                            }
                        </div>
                        {file[0].size !== null && file.length === 1 && fileType.indexOf("image")===-1 && 
                            <p style={{textAlign:'center',fontSize: "18px",color: "#000",fontWeight: '400',marginTop: '10px'}}>{file[0].size < 1000000 ? Math.round(((file[0].size/1000)*100))/100 + ' KB' : Math.round(((file[0].size/1000000)*100))/100 + ' MB'}</p>
                        }
                        {conversationSource !=="email" ? 
                            <Button disabled={uploadingStatus} style={{display: 'flex',fontSize: '16.5px',margin: '0px auto',justifyContent: 'center',alignItems: 'center',height: '50px',width: '150px',border: 'none',borderRadius: '4px'}} type="primary" onClick={handleUpload}>
                                {uploadingStatus ? "Sending..." : "Send"} <SendIcon style={{stroke: '#fff',marginLeft: '8px'}} />
                            </Button> 
                            : 
                            <Button disabled={uploadingStatus} style={{display: 'flex',fontSize: '16.5px',margin: '0px auto',justifyContent: 'center',alignItems: 'center',height: '50px',width: '150px',border: 'none',borderRadius: '4px',marginTop: '10px'}} type="primary" onClick={handleUpload}>
                                {uploadingStatus ? "Attaching..." : "Attach"}
                            </Button>
                        }
                        <input accept={acceptedFilesTypes} multiple disabled={uploadingStatus} type="file" id="file" style={{display: "none"}} onChange={e=>handleSubmit(e)} />
                        <label htmlFor="file" className="attachemnt-icon" style={{textDecoration: 'underline',display: 'flex',marginTop: '40px',justifyContent: 'center',fontSize: '18px',cursor:'pointer'}}>
                            Add a different file
                        </label>
                    </div>
                </StyledUploadComponent>
                :
                // <Tooltip title="Attach File" placement='left'>
                <Fragment>
                    <input accept={acceptedFilesTypes} multiple type="file" id="conversationFile" style={{display: "none"}} onChange={e=>handleSubmit(e)} />
                    <label htmlFor="conversationFile" className="attachemnt-icon">
                        {conversationSource !== "email" ? 
                        <AttachmentIcon style={{display: 'flex',marginRight: '6px', height: '40px', cursor: 'pointer'}} />
                        :   <Tooltip placement='top' title="Attach files">
                                <AttachmentIcon style={{display: 'flex',marginRight: '6px', height: '40px', cursor: 'pointer'}} />
                            </Tooltip>
                        }
                    </label>
                </Fragment>
                // </Tooltip>
                : null
            }
        </div>
    )
}