import React from 'react';
import { StyledSkeletonWrapper } from './styled';
import Logo from '@assets/pragma-light.svg';

export default function Animations() {
    return (
        <StyledSkeletonWrapper>
                <img src={Logo} alt="" />
                <a href="https://www.bepragma.ai/" target="_blank" style={{marginTop: '2%',textDecoration: 'underline',color: '#2871F0'}}>Checkout our new home</a>
        </StyledSkeletonWrapper>
    );
}