import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Wrapper } from './styled';
import Whatsapp from '@components-inbox/Panels/Whatsapp';

const Layout = () => {
    return <Wrapper>
        <Switch>
            <Route path="/inbox/tickets/:id">
                <Whatsapp />
            </Route>
            <Route path="/inbox/tickets">
                <Whatsapp />
            </Route>
            <Route exact path="/inbox">
                <Redirect from="/inbox" to="/inbox/tickets" />
            </Route>
        </Switch>
    </Wrapper>
}

export default Layout;