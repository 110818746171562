import { race, select, take, put, call, fork, delay, takeLatest } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga';
import * as types from './types';
import * as mainTypes from './MainPanel/types';
import * as conversationTypes from './ConversationPanel/types';
import * as mainActions from './MainPanel/action';
import * as mainSelector from './MainPanel/selector';
import * as rootSelector from './selector';
import * as conversationSelector from './ConversationPanel/selector';
import * as conversationActions from './ConversationPanel/action';
import { setwsstatus } from './action';
import Store from '../../store';
import { toast } from 'react-toastify';
import * as api from '@utils/api';

let ws; //define it here so it's available in return function
let counter = 0;
var intervalLoop;
var ticketContentLoop;
toast.configure();
function createEventChannel() {
    return eventChannel(emit => {
        function createWs() {
            const saved = JSON.parse(localStorage.getItem('storeState'));
            const { auth: { token } } = saved;
            const cleanedToken = token.substr(token.indexOf(' ') + 1)
            ws = new WebSocket(`wss://${process.env.REACT_APP_BE_ORIGIN}/ws/inbox/?jwt=${cleanedToken}`)
            ws.onopen = () => {
                console.log("Opening Websocket");
                clearInterval(ticketContentLoop);
                clearInterval(intervalLoop);
                counter = 0;
                const localstatus = mainActions.setOnlineStatus(localStorage.getItem("agent_status","offline"));
                if(localstatus && localstatus.payload !==null){
                    Store.dispatch(localstatus);
                }
                toast.dismiss();
            };
            ws.onerror = error => {
                console.log("ERROR: ", error);
                toast.dismiss();
                // toast.error('Connection could not be established. Try refreshing the page.',{
                //     position: toast.POSITION.TOP_CENTER,
                //     autoClose: false
                // })
            };
            ws.onmessage = e => {
                return emit({ data: JSON.parse(e.data) })
            };
            ws.onclose = e => {
                if (e.code === 1005) {
                    console.log("WebSocket: closed");
                    // you probably want to end the channel in this case
                    emit(END);
                // } else if (e.code === 1006) {
                    // setTimeout(() => {
                    //     localStorage.removeItem('storeState');
                    //     window.location.replace(`${process.env.REACT_APP_ORIGIN}/users/login/`);
                    // }, 5000);
                    // const intervals = Array[6];
                    // toast.configure();
                    // if(intervals[counter]){                        
                    //     setTimeout(function () {
                    //         counter++;
                    //         createWs();
                    //         emit({ data: 'restarting' });
                    //         // console.log('Reload the browser manually or wait for auto retry.');
                    //     }, 5 * 1000);
                    //     toast.error('Reconnecting to the server...',{
                    //         position: toast.POSITION.TOP_CENTER,
                    //         autoClose: 5000,
                    //         hideProgressBar: true,
                    //         closeOnClick: false,
                    //     })
                    // }
                } else {
                    // const intervals = [5, 10, 20, 40, 60, 90];
                    const intervals = [...Array(24).keys()];
                    intervalLoop = setInterval(() => Store.dispatch(mainActions.setSearchFiltersBG()), 6000);
                    // if(window.location.pathname.replace(/\/$/, '').match(/\d+$/) && window.location.pathname.replace(/\/$/, '').match(/\d+$/) !== null){
                    //    ticketContentLoop = setInterval(() => Store.dispatch(conversationActions.setActiveConversationBG(parseInt(window.location.pathname.replace(/\/$/, '').match(/\d+$/)[0]))), 6000);
                    // }
                    if (intervals[counter] && intervals[counter] !== null && intervals[counter] !== undefined) {
                        // console.log(`Socket closed Unexpectedly. Socket reconnection will be attempted. Retrying in ${intervals[counter]} s `, e);
                        setTimeout(function () {
                            counter++;
                            createWs();
                            emit({ data: 'restarting' });
                            // toast.dismiss();
                            // toast.error('Reconnecting to the server...',{
                            //     position: toast.POSITION.TOP_CENTER,
                            //     autoClose: 5000,
                            //     hideProgressBar: true,
                            //     closeOnClick: false,
                            // })
                            // console.log('Reload the browser manually or wait for auto retry.');
                        }, 5000);
                    // }else {
                        // console.log(`Please Note: Socket connection failed, please reload browser manually or check the internet connection. If nothing works, contact the administrator`);
                        // toast.dismiss();
                        // toast.error('Connection timed out. Pleases try refreshing the page.',{
                        //     position: 'top-center',
                        //     autoClose: false
                        // })                        
                    }
                }
            };
        }
        createWs();
        return () => {
            console.log("Closing Websocket");
            ws.close();
        };
    });
}

function* write(socket) {
    while (true) {
        const data = yield take(mainTypes.GET_CONVERSATIONS);
        // socket.send(JSON.stringify({ type: 'tickets.tickets', ...data.payload }));
    }
}

function* writeConversation(socket) {
    while (true) {
        const data = yield take(conversationTypes.GET_CONVERSATION);
        // if (data.payload.ticket_id) {
        //     socket.send(JSON.stringify({ type: 'tickets.ticket_content_v2', ticket_id: Number(data.payload.ticket_id)}));
        // }
    }
}

function* writeNewMessage(socket) {
    while (true) {
        const data = yield take(conversationTypes.SEND_MESSAGE);
        // socket.send(JSON.stringify({ type: 'conversations.send_message', ...data.payload }));
    }
}

function* writeNewComment(socket) {
    while (true) {
        const data = yield take(conversationTypes.REPLY_TO_COMMENT);
        // socket.send(JSON.stringify({ type: 'conversations.reply_to_comment', ...data.payload }));
    }
}

function* deleteComment(socket) {
    while (true) {
        const data = yield take(conversationTypes.DELETE_COMMENT);
        const currentConversationId = yield select(conversationSelector.derivedConversationId);
        // socket.send(JSON.stringify({ type: 'conversations.delete_comment', conversation_id: currentConversationId, ...data.payload }));
    }
}

function* addTags(socket) {
    while (true) {
        const data = yield take(conversationTypes.ADD_TAGS);
        // socket.send(JSON.stringify({ type: 'tickets.add_tags', ...data.payload }));
    }
}

function* removeTags(socket) {
    while (true) {
        const data = yield take(conversationTypes.REMOVE_TAGS);
        // socket.send(JSON.stringify({ type: 'tickets.remove_tags', ...data.payload }));
    }
}

function* addSubscriber(socket) {
    while (true) {
        const data = yield take(conversationTypes.ADD_SUBSCRIBER);
        socket.send(JSON.stringify({ type: 'tickets.add_subscriber', ...data.payload }));
    }
}

function* removeSubscriber(socket) {
    while (true) {
        const data = yield take(conversationTypes.REMOVE_SUBSCRIBER);
        socket.send(JSON.stringify({ type: 'tickets.remove_subscriber', ...data.payload }));
    }
}

function* setStatus(socket) {
    while (true) {
        const data = yield take(conversationTypes.SET_STATUS);
        // socket.send(JSON.stringify({ type: 'tickets.set_status', ...data.payload }));
    }
}

function* setPriority(socket) {
    while (true) {
        const data = yield take(conversationTypes.SET_PRIORITY);
        socket.send(JSON.stringify({ type: 'tickets.set_priority', ...data.payload }));
    }
}

function* setFilterSearchPagination(socket) {
    while (true) {
        yield take(mainTypes.SET_CONVERSATIONS_SEARCH_FILTERS);
        const payload = yield select(mainSelector.payloadSelector);
        const ticketsData = yield call(api.getTicketsListApi, { payload: payload } )
        yield put(mainActions.receiveConversations(ticketsData.data));
        // socket.send(JSON.stringify({ type: 'tickets.tickets', ...payload }));
    }
}

function* setTabFilters(socket) {
    while (true) {
        const { payload } = yield take(mainTypes.SET_TAB_FILTERS);
        // socket.send(JSON.stringify({ type: 'tickets.tickets', ...payload }));
    }
}

function* setPagination(socket) {
    while (true) {
        const data = yield take(mainTypes.SET_CONVERSATIONS_PAGINATION);
        // socket.send(JSON.stringify({ type: 'tickets.tickets', ...data.payload }));
    }
}

function* getPopularTags(socket) {
    while (true) {
        const data = yield take(mainTypes.GET_POPULAR_TAGS);
        socket.send(JSON.stringify({ type: 'tickets.popular_tags', ...data.payload }));
    }
}

function* setOwnershipToMe(socket) {
    while (true) {
        const data = yield take(conversationTypes.SET_OWNERSHIP_TO_ME);
        // socket.send(JSON.stringify({ type: 'tickets.change_ownership_to_me', ...data.payload }));
    }
}

function* changeOwnership(socket){
    while (true){
        const data = yield take(conversationTypes.CHANGE_OWNERSHIP);
        // socket.send(JSON.stringify({type: 'tickets.change_ownership', ...data.payload }));
    }
}

function* initiateConversation(socket){
    while(true){
        const data = yield take(mainTypes.INITIATE_CONVERSATION);
        // socket.send(JSON.stringify({type: 'conversations.initiate_conversation', ...data.payload }));
    }
}

function* markAsUnreadConversation(socket){
    while(true){
        const data = yield take(mainTypes.MARK_AS_UNREAD);
        socket.send(JSON.stringify({type: 'conversations.mark_unread', ...data.payload }));
    }
}

function* handleIO(socket) {
    if(socket.readyState == 1){
        Store.dispatch(setwsstatus(true));
        // yield fork(write, socket);
        // yield fork(setPagination, socket);
        // yield fork(setFilterSearchPagination, socket);
        // yield fork(writeConversation, socket);
        // yield fork(writeNewMessage, socket);
        // yield fork(writeNewComment, socket);
        yield fork(addTags, socket);
        yield fork(removeTags, socket);
        yield fork(addSubscriber, socket);
        yield fork(removeSubscriber, socket);
        // yield fork(setStatus, socket);
        yield fork(setPriority, socket);
        yield fork(getPopularTags, socket);
        // yield fork(setOwnershipToMe, socket);
        // yield fork(changeOwnership, socket);
        // yield fork(setTabFilters, socket);
        // yield fork(initiateConversation, socket);
        // yield fork(deleteComment, socket);
        yield fork(markAsUnreadConversation, socket);
    }else{
        const intervals = [1, 2, 3, 4, 5, 6];
        yield delay(intervals[counter] * 1000);
        counter++;
        yield fork(handleIO, ws)
    }
}

function* initializeWebSocketsChannel() {
    const channel = yield call(createEventChannel);
    // yield delay(1000);
    yield fork(handleIO, ws)
    while (true) {
        const { data } = yield take(channel);
        if (data === 'restarting' || ws.readyState == 0) {
            const intervals = [5, 10, 20, 40, 60, 90];
            yield delay(intervals[counter] * 1000);
            yield call(initializeWebSocketsChannel)
            return;
        }
        switch (data.type) {
            case "tickets.tickets": {
                yield put(mainActions.receiveConversations(data));
                break;
            }
            case "tickets.ticket_content_v2": {
                // const reversedData = Object.assign({}, data, {
                //     data: Object.assign({}, data.data, {
                //         content: data.data.content.reverse()
                //     })
                // });
                yield put(conversationActions.receiveConversation(data));
                break;
            }
            case "conversations.new_message": {
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                yield put(conversationActions.receiveNewMessage({ data: data.data, currentConversationId }));
                break;
            }
            case "tickets.ownership_transferred_to_me": {
                yield put(conversationActions.updateOwnershipChange(data));
                break;
            }
            case "tickets.ownership_transferred": {
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                const settings = yield select(mainSelector.connectionsSelector);
                const currentOwnerId = settings.response.current_user_id;
                yield put(conversationActions.updateOwnershipChange({ data: data.data, currentConversationId, currentOwnerId }));
                break;
            }
            case "tickets.status_updated": {
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                yield put(conversationActions.getStatus({data: data.data, currentConversationId}));
                break;
            }
            case "tickets.priority_updated": {
                yield put(conversationActions.getPriority(data));
                break;
            }
            case "tickets.tags_added": {
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                yield put(conversationActions.getTagsAdded({data: data.data, currentConversationId}));
                break;
            }
            case "tickets.tags_removed": {
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                yield put(conversationActions.getTagsRemoved({data: data.data, currentConversationId}));
                break;
            }
            case "tickets.subscriber_added": {
                const { auth: { user: { id } } } = yield select(rootSelector.profileSelector);
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                yield put(conversationActions.getSubscriberAdded({ data: data.data, currentConversationId, currentUserId: id }));
                break;
            }
            case "tickets.subscriber_removed": {
                const { auth: { user: { id } } } = yield select(rootSelector.profileSelector);
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                yield put(conversationActions.getSubscriberRemoved({ data: data.data, currentConversationId, currentUserId: id }));
                break;
            }
            case "tickets.popular_tags": {
                yield put(mainActions.setPopularTags(data));
                break;
            }
            case "conversations.message_sent": {
                const currentConversationId = yield select(conversationSelector.derivedConversationId);
                yield put(conversationActions.updateMessageSent({ data: data.data, currentConversationId, success: data.success, error: data.error }));
                break;
            }
            case "conversations.message_failed": {
                const currentConversationId = yield select(conversationSelector.derivedConversationId);
                yield put(conversationActions.updateMessageFailed({ data, currentConversationId }));
                break;
            }
            case "conversations.message_delivered": {
                const currentConversationId = yield select(conversationSelector.derivedConversationId);
                yield put(conversationActions.updateMessageDelivered({ data, currentConversationId }));
                break;
            }
            case "conversations.message_read": {
                const currentConversationId = yield select(conversationSelector.derivedConversationId);
                yield put(conversationActions.updateMessageRead({ data, currentConversationId }));
                break;
            }
            case "conversations.message_deleted": {
                const currentConversationId = yield select(conversationSelector.derivedConversationId);
                yield put(conversationActions.updateDeletedMessage({ data, currentConversationId }));
                break;
            }
            case "conversations.conversation_initiated": {
                yield put(mainActions.conversationInitiated({data}));
                break;
            }
            case "conversations.comment_created": {
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                const postID = yield select(conversationSelector.postId);
                const oldConversations = yield select(conversationSelector.conversationDataSelector);
                yield put(conversationActions.commentCreated({ data, currentConversationId, postID, oldConversations }));
                break;
            }
            case "conversations.comment_deleted": {
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                yield put(conversationActions.commentDeleted({ data, currentConversationId }));
                break;
            }
            case "conversations.new_comment": {
                const currentConversationId = yield select(conversationSelector.currentActiveConversationSelector);
                yield put(conversationActions.newComment({ data, currentConversationId }));
                break;
            }
            case "conversations.agent_counter_update": {
                yield put(mainActions.msgsCountUpdate({ data }))
                break;
            }
            case "conversations.unassigned_counter_update": {
                yield put(mainActions.unassignedMsgsCountUpdate({ data }))
                break;
            }
            default:

        }
        switch(data.error_on_type) {
            case "conversations.message_sent": {
                const currentConversationId = yield select(conversationSelector.derivedConversationId);
                yield put(conversationActions.updateMessageSent({ data: data.data, currentConversationId, success: data.success, error: data.error }));
                break;
            }
            default:   
        }
    }
}

export function* startStopChannel() {
    while (true) {
        yield take(types.LOAD_SOCKET);
        yield race({
            task: call(initializeWebSocketsChannel),
            cancel: take(types.STOP_SOCKET),
        });
        //if cancel wins the race we can close socket
        ws.close();
    }
}

function* watchReloadWS() {
    yield takeLatest([types.RELOAD_WS], initializeWebSocketsChannel)
}

export default [
    watchReloadWS,
    startStopChannel
]