import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { Typography, Skeleton, Divider, Avatar, Empty, Tooltip } from "antd";
import {
  customerDetailsDataSelector,
  customerDetailsLoaderSelector,
  allTicketsLoaderSelector,
  allTicketsDataSelector,
} from "@store-inbox/SummaryPanel/selector";
import {
  conversationDataSelector,
  loadingSelector,
  currentActiveConversationSelector,
} from "@store-inbox/ConversationPanel/selector";
import { getInitials } from "@utils/functions";
import { StyledEmptyHolder } from "../../styled";
import { ReactComponent as EmailIcon } from "@assets/icons/summary/mail.svg";
import { ReactComponent as PhoneIcon } from "@assets/icons/summary/telephone.svg";
import { ReactComponent as CityIcon } from "@assets/icons/summary/location.svg";
import { ReactComponent as CopyIcon } from "@assets/icons/summary/copy.svg";
import { ReactComponent as CompletedIcon } from "@assets/icons/message/sent.svg";
import OrderStatus from "./OrderStatus/OrderStatus.component";
import { StyledOrderBox, StyledMainDetails, StyledMainHeader } from "../styled";
import Timeline from "./Timeline/Timeline";

function OrderBox(props) {
  const customerId = useSelector(currentActiveConversationSelector);
  const customer = useSelector(customerDetailsDataSelector);
  const conversation = useSelector(conversationDataSelector);
  const isConversationDetailsLoading = useSelector(
    customerDetailsLoaderSelector
  );
  const isConversationsLoading = useSelector(loadingSelector);
  const [emailCopied, setEmailCopied] = useState(false);
  const [phoneCopied, setPhoneCopied] = useState(false);
  const [addressCopied, setAddressCopied] = useState(false);
  const isTicketsLoading = useSelector(allTicketsLoaderSelector);
  const allTickets = useSelector(allTicketsDataSelector);

  const onEmailCopy = () => {
    navigator.clipboard.writeText(
      customer && customer.basic_info ? customer.basic_info.email : ""
    );
    setEmailCopied(true);

    setTimeout(() => {
      setEmailCopied(false);
    }, 3000);
  };

  const onPhoneCopy = () => {
    navigator.clipboard.writeText(
      customer && customer.basic_info ? customer.basic_info.phone : ""
    );

    setPhoneCopied(true);

    setTimeout(() => {
      setPhoneCopied(false);
    }, 3000);
  };

  const onAddressCopy = () => {
    navigator.clipboard.writeText(
      customer && customer.basic_info ? customer.basic_info.city : ""
    );

    setAddressCopied(true);

    setTimeout(() => {
      setAddressCopied(false);
    }, 3000);
  };

  return (
    <StyledOrderBox>
      <Fragment>
        <StyledMainHeader style={{ display: "flex", alignItems: "center" }}>
          <Fragment>
            {!isConversationsLoading ? (
              <Avatar>
                {getInitials(
                  conversation.data ? conversation.data.full_name : ""
                )}
              </Avatar>
            ) : (
              <Skeleton.Avatar
                active={!customerId ? false : true}
                size="large"
                shape="circle"
              />
            )}
            <div className="main-name">
              {!isConversationsLoading && conversation.data
                ? conversation.data.full_name
                : "-"}
            </div>
          </Fragment>
        </StyledMainHeader>
        <Divider style={{ margin: "10px" }} />
        {!customerId ? (
          <StyledEmptyHolder>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Customer data unavailable."
            />
          </StyledEmptyHolder>
        ) : (
          <Fragment>
            <Fragment>
              <div className="spent-total">
                <span>₹</span>
                <div className="spent-number" style={{}}>
                  <NumberFormat
                    value={
                      !isConversationDetailsLoading &&
                      customer &&
                      customer.analytics
                        ? Number(customer.analytics.total_spent).toFixed(2)
                        : "-"
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    thousandsGroupStyle="lakh"
                  />
                </div>
              </div>
              <Divider style={{ margin: "10px" }} />
              <StyledMainDetails>
                <div className="icon-box">
                  <EmailIcon />
                </div>
                <div className="detail-box">
                  <Typography className="detail-value">
                    {customer && customer.basic_info
                      ? customer.basic_info.email
                      : "-"}
                  </Typography>
                </div>
                {customer &&
                customer.basic_info &&
                customer.basic_info.email ? (
                  <Tooltip title={!emailCopied ? "Copy" : "Copied"}>
                    {!emailCopied ? (
                      <CopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={onEmailCopy}
                      />
                    ) : (
                      <CompletedIcon />
                    )}
                  </Tooltip>
                ) : null}
              </StyledMainDetails>
              <StyledMainDetails>
                <div className="icon-box">
                  <PhoneIcon />
                </div>
                <div className="detail-box">
                  <Typography className="detail-value">
                    {customer && customer.basic_info
                      ? customer.basic_info.phone
                      : "-"}
                  </Typography>
                </div>
                {customer &&
                customer.basic_info &&
                customer.basic_info.phone ? (
                  <Tooltip title={!phoneCopied ? "Copy" : "Copied"}>
                    {!phoneCopied ? (
                      <CopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={onPhoneCopy}
                      />
                    ) : (
                      <CompletedIcon />
                    )}
                  </Tooltip>
                ) : null}
              </StyledMainDetails>
              <StyledMainDetails>
                <div className="icon-box">
                  <CityIcon />
                </div>
                <div className="detail-box">
                  <Typography className="detail-value">
                    {customer && customer.basic_info
                      ? customer.basic_info.city
                      : "-"}
                  </Typography>
                </div>
                {customer && customer.basic_info && customer.basic_info.city ? (
                  <Tooltip title={!addressCopied ? "Copy" : "Copied"}>
                    {!addressCopied ? (
                      <CopyIcon
                        style={{ cursor: "pointer" }}
                        onClick={onAddressCopy}
                      />
                    ) : (
                      <CompletedIcon />
                    )}
                  </Tooltip>
                ) : null}
              </StyledMainDetails>
            </Fragment>
            <Divider style={{ margin: "10px" }} />
            {customer &&
            customer.analytics &&
            customer.analytics.total_orders === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No stats yet."
              />
            ) : (
              <OrderStatus
                isConversationsLoading={isConversationDetailsLoading}
                analytics={customer ? customer.analytics : {}}
              />
            )}
            <p style={{ fontSize: "16px", fontWeight: "500" }}>Past Tickets</p>
            {isTicketsLoading && "Loading..."}
            {allTickets &&
              allTickets.length > 0 &&
              allTickets.map((item) => {
                return <Timeline item={item} />;
              })}
          </Fragment>
        )}
      </Fragment>
    </StyledOrderBox>
  );
}

export default OrderBox;
