import React from 'react';
import { Button, Popover } from 'antd';
import { ReactComponent as SmileyIcon } from '@assets/icons/message/smile.svg';
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import { StyledEmojiWrapper } from '../styled'

export default function TransitionsPopper({ ownerStatus, setChosenEmoji }) {

    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject);
    };

    const content = () => {
        return (
            <StyledEmojiWrapper>
                <Picker
                    preload={true}
                    onEmojiClick={onEmojiClick}
                    disableAutoFocus={true}
                    skinTone={SKIN_TONE_MEDIUM_DARK}
                    groupNames={{ smileys_people: "PEOPLE" }}
                    native
                />
            </StyledEmojiWrapper>
        );
    }

    return (
        <div>
            {ownerStatus ? <Popover content={content} trigger="click">
                <Button className="emoji-button" shape="circle" icon={<SmileyIcon />} size="medium" type="link" />
            </Popover>
                : null}
        </div>
    );
}
