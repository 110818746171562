import { createSelector } from "reselect";
import { quickRepliesSelector } from '@store-inbox/ConversationPanel/selector';

export const derivedAllQuickReplies = createSelector(quickRepliesSelector, (quickReplies) => {
    let replies = [];
    if (quickReplies && quickReplies.response.length > 0) {
        quickReplies.response.map((reply) => {
            replies.push({
                ...reply
            });
        });
    }
    return replies;
});