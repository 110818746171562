import styled from 'styled-components';

export const StyledAbandonedListWrapper = styled.div`
    background-color: #fff;
    .ant-collapse{
        background-color: #fff;
        border: none;
    }
    .ant-collapse-content{
        border-top: none;
        border-bottom: 1px solid #eee;
    }
    .ant-collapse > .ant-collapse-item{
        border: none;
    }
    .ant-collapse-header{
        padding: 15px 0px !important; 
    }
    .ant-collapse-header > div{
        padding: 5px 0px;
    }
    .ant-collapse-content > .ant-collapse-content-box{
        padding: 10px 0px !important;
    }
`;
export const StyledOrdersListWrapper = styled.div`
    background-color: #fff;
    .ant-collapse{
        background-color: #fff;
        border: none;
    }
    .ant-collapse-content{
        border-top: none;
        border-bottom: 1px solid #eee;
    }
    .ant-collapse > .ant-collapse-item{
        border: none;
    }
    .ant-collapse-header{
        padding: 15px 0px !important; 
    }
    .ant-collapse-header > div{
        padding: 5px 0px;
    }
    .ant-collapse-content > .ant-collapse-content-box{
        padding: 10px 0px !important;
    }
`;

export const StyledScrollTab = styled.div`
    
`;

export const StyledOrderWarnings = styled.div`
    display: flex;
    justify-content: end;
    select{
        width:50%;
        background: #fff;
        border: 0;
        font-weight: 700;
    }

    option{

    }
`;

export const StyledOrderDiscount = styled.div`
    display:flex;
    flex-direction: column;
    margin-top: 10px;
`;


export const StyledOrderHeader = styled.div`
    display:flex;
    width:100%;
    padding:10px;
    cursor:pointer;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    header{
        margin: 3px 5px;
        margin-left: 0px;
        font-weight:700;
        color:#000;
    }

    .ant-tag{
        border-radius:15px;
        padding: 0 8px;
        font-size: 10px;
        font-weight: 700;
        border: none;
        line-height: 1.6em;
        height: 18px;
        text-transform: uppercase;
    }
`;

export const StyledAllTags = styled.div`
    .ant-tag{
        border-radius:15px;
        padding: 0 8px;
        font-size: 10px;
        font-weight: 700;
        border: none;
        line-height: 1.6em;
        text-transform: uppercase;
    }

    display: flex;
    justify-content: space-between;
    width: 50%;
`  

export const StyledCollapseTop = styled.div`
    display:flex;
    height: 35px;
    font-size: 15px;
    position: absolute;
    top: 0;
    right: 0;
    margin:5px;
`;

export const StyledOrderRow = styled.div`
    display:flex;
    align-content: center;
    justify-content: space-between;
    font-size: 0.9em;
    padding: 10px 0;

    .order-heading{
        color: #6F6F6F;
    }

    .icon-payment{
        cursor: pointer;
        span + span{
            padding: 0px 5px;
        }
    }

    .order-verified{
        cursor: pointer;
    }

    .order-value{
        color:#1f1f1f;
        user-select: all;
    }

    .value-date{
        font-weight: 700;
    }

    .value-quantity{
        font-weight: 700;
        color: #1F1F1F;
        flex-shrink: 0;
    }

    .value-discount{
        font-weight: 700;
        color: #1F1F1F;
    }

    .header-total{
        color: #6F6F6F;
        font-weight: 200;
        font-size:1em;
    }

    .value-total{
        color: #1F1F1F;
        font-weight: 700;
        font-size:1.2em;
    }
`;

export const StyledOrderWrapper = styled.div`
`;

export const StyledOrders = styled.div`
`;