export const getACCalculations = (x, y, sum) => {
    const abandonedValue = Math.round((x / sum) * 100);
    return {
        abandoned: 100,
        converted: 100 - abandonedValue,
    }
}

export const getOrderCalculations = (x, y, z, sum) => {
    const rtod = Math.round((z / sum) * 100);
    const cancelled = 100 - rtod;
    const delivered = Math.round((y / sum) * 100);
    return {
        cancelled,
        delivered,
        rtod,
    }
}