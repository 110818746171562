import { ReactComponent as MessageIcon } from '@assets/icons/message/messages.svg';
import { Button } from 'antd';

export default function Message({ownerStatus,setReplyPanel}){

    return(<div>
    {ownerStatus ? 
        <Button className="message-icon" icon={<MessageIcon />} onClick={()=>setReplyPanel(true)} size="Small" type="link" />
        : null}
</div>
    )
}