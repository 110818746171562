import styled from 'styled-components';

export const StyledOrderStatus = styled.div`
    width: 100%;
`;

export const StyledChart = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
    .legends{
        font-size: 0.6em;
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .ant-progress{
        .ant-progress-inner{
            width:75px!important;
            height:75px!important;
        }
    }

    .total-number{
        color:var(--color-primary);
        font-size:20px;
        width: 40px;
        height: 40px;        
        border-radius: 50%;
        background: #EBF0F4;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
`;

export const StyledTotal = styled.div`
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #474747;
`;

export const StyledLegend = styled.div`
     margin: 5px;
     color:#474747;
     font-size: 14px;
     font-weight:200;
`;