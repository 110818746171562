import React from 'react';
import CustomerDetails from './CustomerDetails/CustomerDetails.component';
import { StyledDetailAnalyticsWrapper } from '../styled';


const CustomerDetailsComponent = () => {
    return (
        <StyledDetailAnalyticsWrapper>
            <CustomerDetails />
        </StyledDetailAnalyticsWrapper>
    );
}

export default CustomerDetailsComponent;