import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { EmailHeading, EmailWrapper } from "./styled";
import {
  StyledEmailAttachment,
  StyledEmailGroups,
  StyledEmailReply,
} from "../ConversationPanel/styled";
import ReactPlayer from "react-player";
import {
  createEditor,
  Editor,
  Text,
  Transforms,
  Element as SlateElement,
  Range,
} from "slate";
import {
  Slate,
  Editable,
  withReact,
  useSelected,
  useSlate,
  ReactEditor,
} from "slate-react";

import { ReactComponent as TypeIcon } from "@assets/icons/message/type.svg";
import { ReactComponent as AlignIcon } from "@assets/icons/message/alignment.svg";
import { ReactComponent as ListIcon } from "@assets/icons/message/list.svg";
import { ReactComponent as LinkIcon } from "@assets/icons/message/link.svg";
import { ReactComponent as UnderlineIcon } from "@assets/icons/message/underline.svg";
import { ReactComponent as BoldIcon } from "@assets/icons/message/bold.svg";
import { ReactComponent as ItalicIcon } from "@assets/icons/message/italic.svg";
import { ReactComponent as ColorIcon } from "@assets/icons/message/text-color.svg";
import { ReactComponent as SendIcon } from "@assets/icons/message/send.svg";
import { SketchPicker } from "react-color";
import { ReactComponent as AlignLeftIcon } from "@assets/icons/message/align-left.svg";
import { ReactComponent as AlignCenterIcon } from "@assets/icons/message/align-center.svg";
import { ReactComponent as AlignRighttIcon } from "@assets/icons/message/align-right.svg";
import { ReactComponent as AlignJustifyIcon } from "@assets/icons/message/align-justify.svg";
import { ReactComponent as NumberListIcon } from "@assets/icons/message/number-list.svg";
import { ReactComponent as DocxIcon } from "@assets/icons/message/docx.svg";
import { ReactComponent as PdfIcon } from "@assets/icons/message/pdficon.svg";
import { ReactComponent as GenericIcon } from "@assets/icons/message/genericicon.svg";

import Attachment from "../ConversationPanel/TextBox/Attachment";
import { Toolbar } from "../ConversationPanel/ConversationPanel";
import { withHistory } from "slate-history";
import { Button, Divider, Dropdown, Input, Modal, Select } from "antd";
import { css as Emotioncss } from "@emotion/css";
import { isKeyHotkey } from "is-hotkey";
import { useDispatch, useSelector } from "react-redux";
import isUrl from "is-url";

import { emailFileSelector } from "@store-inbox/ConversationPanel/selector";
import {
  derivedAllAgentsDataSelector,
  derivedAllTickets,
  derivedConversationSource,
} from "../ConversationPanel/utils";
import { IoClose } from "react-icons/io5";
import { derivedConnectionsSelector } from "../MainPanel/utils";
import { initiateMail } from "@store-inbox/MainPanel/action";
import { resetAttachmentData } from "@store-inbox/ConversationPanel/action";
import {
  initiateMailSelector,
  emailGroupsSelector,
} from "@store-inbox/MainPanel/selector";
import {
  customerDetailsDataSelector,
  orderDataSelector,
} from "@store-inbox/SummaryPanel/selector";
import { toast } from "react-toastify";
import { derivedAllQuickReplies } from "../QuickReplyPanel/utils";

function Email({ createNewEmail, setCreateNewEmail, allTags }) {
  const withInlines = (editor) => {
    const { insertData, insertText, isInline } = editor;

    editor.isInline = (element) =>
      ["link", "button"].includes(element.type) || isInline(element);

    editor.insertText = (text) => {
      if (text && isUrl(text)) {
        wrapLink(editor, text);
      } else {
        insertText(text);
      }
    };

    editor.insertData = (data) => {
      const text = data.getData("text/plain");

      if (text && isUrl(text)) {
        wrapLink(editor, text);
      } else {
        insertData(data);
      }
    };

    return editor;
  };

  const editor = useMemo(
    () => withInlines(withHistory(withReact(createEditor()))),
    []
  );

  const statusOptions = [
    {
      key: "assigned",
      title: "Assigned",
    },
    {
      key: "open",
      title: "Open",
    },
    {
      key: "closed",
      title: "Closed",
    },
  ];

  const dispatch = useDispatch();
  const { Option } = Select;

  const [attachmentData, setAttachmentData] = useState([]);
  const [link, setLink] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [color, setColor] = useState("#474747");
  const [cc, setCC] = useState(false);
  const [bcc, setBCC] = useState(false);
  const [ccID, setCcID] = useState([]);
  const [bccID, setBccID] = useState([]);
  const [toID, setToID] = useState([]);
  const [source, setSource] = useState("");
  const [ticket, setTicket] = useState({});
  const [subject, setSubject] = useState("");
  const [subTicketID, setSubTicketID] = useState(null);
  const [fromID, setFromID] = useState("");
  const [fromEmails, setFromEmails] = useState([]);
  const [initiatingMail, setInitiatingMail] = useState(false);
  const [tempNewEmail, setTempNewEmail] = useState("");
  const [isFocused, setIsFocused] = useState("");
  const [showShortcuts, setShowShortcuts] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [quickReplyInput, setQuickReplyInput] = useState("");
  const [allAgents, setAllAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("open");
  const [tags, setTags] = useState([]);

  const attachmentUrls = useSelector(emailFileSelector);
  const conversation_source = useSelector(derivedConversationSource);
  const allTickets = useSelector(derivedAllTickets);
  const settings = useSelector(derivedConnectionsSelector);
  const initiateMailValue = useSelector(initiateMailSelector);
  const emailGroups = useSelector(emailGroupsSelector);
  const customerData = useSelector(customerDetailsDataSelector);
  const orderData = useSelector(orderDataSelector);
  const replies = useSelector(derivedAllQuickReplies);
  const derivedAgents = useSelector(derivedAllAgentsDataSelector);

  const replyListRef = useRef(null);

  const basicInfo = customerData && customerData.basic_info;
  const firstOrder =
    orderData &&
    orderData.orders &&
    orderData.orders.length > 0 &&
    orderData.orders[0];

  toast.configure();

  useEffect(() => {
    if (attachmentUrls !== null) {
      setAttachmentData([...new Set(attachmentData.concat(attachmentUrls))]);
    } else {
      setAttachmentData([]);
    }
  }, [attachmentUrls]);

  useEffect(() => {
    setSource(conversation_source);
  }, [conversation_source]);

  useEffect(() => {
    if (settings.email_accounts) {
      let email_accounts = [...settings.email_accounts];
      let tempMails = [];
      for (let i = 0; i < email_accounts.length; i++) {
        tempMails.push({ value: email_accounts[i], label: email_accounts[i] });
      }
      setFromEmails(fromEmails.concat(tempMails));
      setFromID(tempMails[0].value);
    }
    setSelectedAgent(settings.current_user_id);
  }, [settings]);

  const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
  const LIST_TYPES = ["numbered-list", "bulleted-list"];

  const initialValue = {
    type: "paragraph",
    children: [{ text: "" }],
  };

  const insertLink = (editor, url) => {
    if (editor.selection) {
      wrapLink(editor, url);
    }
  };

  useEffect(() => {
    if (allTickets) {
      setTicket({
        id: allTickets[allTickets.length - 1].id,
        status: allTickets[allTickets.length - 1].status,
        subscribers: allTickets[allTickets.length - 1].subscribers,
        tags: allTickets[allTickets.length - 1].tags,
        owner_id: allTickets[allTickets.length - 1].owner_id,
        priority: allTickets[allTickets.length - 1].priority,
        session_expiry_at: allTickets[allTickets.length - 1].session_expiry_at,
        is_csat_sent: allTickets[allTickets.length - 1].is_csat_sent,
        expiration_info: allTickets[allTickets.length - 1].expiration_info,
      });
    }
  }, [allTickets]);

  const isLinkActive = (editor) => {
    const [link] = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
    return !!link;
  };

  const wrapLink = (editor, url) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && selection.isCollapsed;
    const link = {
      type: "link",
      url,
      children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  };

  const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
  };

  const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
      <Button
        active={isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
        )}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
        style={{ border: "none", padding: "0px" }}
      >
        {icon == "list_numbered" ? (
          <ListIcon
            style={{
              cursor: "pointer",
              stroke: "#474747",
              height: "24px",
              width: "24px",
              marginLeft: "10px",
              marginBottom: "0",
            }}
          />
        ) : (
          (icon = "list_bulleted" ? (
            <NumberListIcon
              style={{
                cursor: "pointer",
                stroke: "#474747",
                marginBottom: "0",
              }}
            />
          ) : (
            ""
          ))
        )}
      </Button>
    );
  };

  const isBlockActive = (editor, format, blockType = "type") => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format,
      })
    );

    return !!match;
  };

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    });
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      };
    }

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const handleBold = (e) => {
    e.preventDefault();
    CustomEditor.toggleBoldMark(editor);
  };

  const handleItalic = (e) => {
    e.preventDefault();
    CustomEditor.toggleItalicMark(editor);
  };

  const handleUnderline = (e) => {
    e.preventDefault();
    CustomEditor.toggleUnderlineMark(editor);
  };

  const handleColor = () => {
    CustomEditor.toggleColorMark(editor);
  };

  const handleLeftAlign = (e) => {
    e.preventDefault();
    CustomEditor.toggleLeftMark(editor);
  };

  const handleCenterAlign = (e) => {
    e.preventDefault();
    CustomEditor.toggleCenterMark(editor);
  };

  const handleRightAlign = (e) => {
    e.preventDefault();
    CustomEditor.toggleRightMark(editor);
  };

  const handleJustify = (e) => {
    e.preventDefault();
    CustomEditor.toggleJustifyMark(editor);
  };

  const colorMenu = (
    <SketchPicker
      onChangeComplete={(e) => {
        setColor(e.hex);
        handleColor();
      }}
    />
  );

  useEffect(() => {
    if (!createNewEmail) {
      const point = { path: [0, 0], offset: 0 };
      editor.selection = { anchor: point, focus: point };

      // For good measure, you can reset the history as well
      editor.history = { redos: [], undos: [] };
      setValue([initialValue]);
      dispatch(resetAttachmentData());
      setAttachmentData([]);
    }
  }, [createNewEmail]);

  const CodeElement = (props) => {
    return (
      <pre {...props.attributes}>
        <code>{props.children}</code>
      </pre>
    );
  };

  const InlineChromiumBugfix = () => (
    <span
      contentEditable={false}
      className={Emotioncss`
        font-size: 0;
      `}
    >
      ${String.fromCodePoint(160) /* Non-breaking space */}
    </span>
  );

  const LinkComponent = ({ attributes, children, element }) => {
    const selected = useSelected();
    return (
      <a
        {...attributes}
        href={element.url}
        style={{ textDecoration: "underline" }}
        target="_blank"
        className={
          selected
            ? Emotioncss`
                        box-shadow: 0 0 0 3px #ddd;
                        `
            : ""
        }
      >
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </a>
    );
  };

  // Define a React component to render leaves with bold text.
  const Leaf = (props) => {
    return (
      <span
        {...props.attributes}
        className={
          props.leaf.text == ""
            ? Emotioncss`
                  padding-left: 0.1px;
                `
            : null
        }
        style={{
          fontWeight: props.leaf.bold ? "bold" : "400",
          fontStyle: props.leaf.italic ? "italic" : "",
          textDecoration: props.leaf.underline ? "underline" : "",
          color: props.leaf.color ? color : "#474747",
          textAlign: props.leaf.left
            ? "left"
            : props.leaf.center
            ? "center"
            : props.leaf.right
            ? "right"
            : props.leaf.justify
            ? "justify"
            : "left",
          display:
            props.leaf.left ||
            props.leaf.right ||
            props.leaf.center ||
            props.leaf.justify
              ? "block"
              : "inline",
        }}
      >
        {props.children}
      </span>
    );
  };

  const CustomEditor = {
    isBoldMarkActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.bold === true,
        universal: true,
      });

      return !!match;
    },

    isItalicMarkActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.italic === true,
        universal: true,
      });

      return !!match;
    },

    isUnderlineMarkActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.underline === true,
        universal: true,
      });

      return !!match;
    },

    isColorMarkActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.color === true,
        universal: true,
      });

      return !!match;
    },

    isLeftMarkActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.left === true,
        universal: true,
      });

      return !!match;
    },

    isRightMarkActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.right === true,
        universal: true,
      });

      return !!match;
    },

    isCenterMarkActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.center === true,
        universal: true,
      });

      return !!match;
    },

    isJustifyMarkActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.justify === true,
        universal: true,
      });

      return !!match;
    },

    isCodeBlockActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.type === "code",
      });

      return !!match;
    },

    isParaBlockActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.type === "para",
      });

      return !!match;
    },

    isListBlockActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.type === "list",
      });

      return !!match;
    },

    isOrderedListBlockActive(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.type === "orderedList",
      });

      return !!match;
    },

    toggleBoldMark(editor) {
      const isActive = CustomEditor.isBoldMarkActive(editor);
      Transforms.setNodes(
        editor,
        { bold: isActive ? false : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleItalicMark(editor) {
      const isActive = CustomEditor.isItalicMarkActive(editor);
      Transforms.setNodes(
        editor,
        { italic: isActive ? false : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleUnderlineMark(editor) {
      const isActive = CustomEditor.isUnderlineMarkActive(editor);
      Transforms.setNodes(
        editor,
        { underline: isActive ? false : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleColorMark(editor) {
      const isActive = CustomEditor.isColorMarkActive(editor);
      Transforms.setNodes(
        editor,
        { color: isActive ? false : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleLeftMark(editor) {
      const isActive = CustomEditor.isLeftMarkActive(editor);
      Transforms.setNodes(
        editor,
        { left: isActive ? false : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleCenterMark(editor) {
      const isActive = CustomEditor.isCenterMarkActive(editor);
      Transforms.setNodes(
        editor,
        { center: isActive ? false : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleRightMark(editor) {
      const isActive = CustomEditor.isRightMarkActive(editor);
      Transforms.setNodes(
        editor,
        { right: isActive ? false : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleJustifyMark(editor) {
      const isActive = CustomEditor.isJustifyMarkActive(editor);
      Transforms.setNodes(
        editor,
        { justify: isActive ? false : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleCodeBlock(editor) {
      const isActive = CustomEditor.isCodeBlockActive(editor);
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "code" },
        { match: (n) => Editor.isBlock(editor, n) }
      );
    },

    toggleParaBlock(editor) {
      const isActive =
        CustomEditor.isListBlockActive(editor) ||
        CustomEditor.isOrderedListBlockActive(editor);
      if (
        editor.children[editor.children.length - 1].children[0].text !==
          undefined &&
        editor.children[editor.children.length - 1].children[0].text.length <= 1
      ) {
        Transforms.setNodes(
          editor,
          { type: isActive ? null : "para" },
          { match: (n) => Editor.isBlock(editor, n) }
        );
      }
    },

    toggleListBlock(editor) {
      const isActive = CustomEditor.isListBlockActive(editor);
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "list" },
        { match: (n) => Editor.isBlock(editor, n) }
      );
    },

    toggleOrderedListBlock(editor) {
      const isActive = CustomEditor.isOrderedListBlockActive(editor);
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "orderedList" },
        { match: (n) => Editor.isBlock(editor, n) }
      );
    },
  };

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "code":
        return <CodeElement {...props} />;
      case "link":
        return <LinkComponent {...props} />;
      case "para":
        return <p {...props.attributes}>{props.children}</p>;
      case "bulleted-list":
        return (
          <ul style={{ textAlign: props.element.align }} {...props.attributes}>
            {props.children}
          </ul>
        );
      case "list-item":
        return (
          <li style={{ textAlign: props.element.align }} {...props.attributes}>
            {props.children}
          </li>
        );
      case "numbered-list":
        return (
          <ol style={{ textAlign: props.element.align }} {...props.attributes}>
            {props.children}
          </ol>
        );
      default:
        return <p {...props.attributes}>{props.children}</p>;
    }
  });

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  });

  const [value, setValue] = useState([initialValue]);
  const [selection, setSelection] = useState(null);

  const handleTo = (e) => {
    const newList = e.target.value.split(",");
    setTempNewEmail(newList[newList.length - 1] || ""); // Ensure it's a string
    setToID([...new Set(newList)]);
  };

  const handleCc = (e) => {
    const newList = e.target.value.split(",");
    setTempNewEmail(newList[newList.length - 1] || ""); // Ensure it's a string
    setCcID([...new Set(newList)]);
  };

  const handleBcc = (e) => {
    const newList = e.target.value.split(",");
    setTempNewEmail(newList[newList.length - 1] || ""); // Ensure it's a string
    setBccID([...new Set(newList)]);
  };

  const styleMenu = (
    <div
      style={{
        background: "#fff",
        borderRadius: "6px",
        boxShadow:
          "0px 2px 2px rgba(0, 0, 0, 0.12), 0px -2px 2px rgba(0, 0, 0, 0.12)",
        padding: "10px",
      }}
    >
      <UnderlineIcon onClick={(e) => handleUnderline(e)} />
      <BoldIcon
        style={{ marginLeft: "20px", cursor: "pointer" }}
        onClick={(e) => handleBold(e)}
      />
      <ItalicIcon
        style={{ marginLeft: "20px" }}
        onClick={(e) => handleItalic(e)}
      />
      <Dropdown overlay={colorMenu}>
        <ColorIcon style={{ marginLeft: "20px" }} />
      </Dropdown>
    </div>
  );

  const alignMenu = (
    <div
      style={{
        background: "#fff",
        borderRadius: "6px",
        boxShadow:
          "0px 2px 2px rgba(0, 0, 0, 0.12), 0px -2px 2px rgba(0, 0, 0, 0.12)",
        padding: "10px",
      }}
    >
      <AlignLeftIcon
        style={{ cursor: "pointer" }}
        onClick={(e) => handleLeftAlign(e)}
      />
      <AlignCenterIcon
        style={{ marginLeft: "20px", cursor: "pointer" }}
        onClick={(e) => handleCenterAlign(e)}
      />
      <AlignRighttIcon
        style={{ marginLeft: "20px", cursor: "pointer" }}
        onClick={(e) => handleRightAlign(e)}
      />
      <AlignJustifyIcon
        style={{ marginLeft: "20px", cursor: "pointer" }}
        onClick={(e) => handleJustify(e)}
      />
    </div>
  );

  const listMenu = (
    <div
      style={{
        background: "#fff",
        borderRadius: "6px",
        boxShadow:
          "0px 2px 2px rgba(0, 0, 0, 0.12), 0px -2px 2px rgba(0, 0, 0, 0.12)",
        padding: "5px",
      }}
    >
      <BlockButton format="bulleted-list" icon="list_bulleted" />
      <BlockButton format="numbered-list" icon="list_numbered" />
    </div>
  );

  const removeAttachment = (item) => {
    setAttachmentData(
      attachmentData.filter(function (obj) {
        return obj.url !== item;
      })
    );
  };

  const handleInsertLink = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    insertLink(editor, link);
  };

  const editorKeyDownEvent = (event) => {
    const { selection } = editor;

    if (selection && selection.isCollapsed) {
      const { nativeEvent } = event;
      if (isKeyHotkey("left", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset", reverse: true });
        return;
      }
      if (isKeyHotkey("right", nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: "offset" });
        return;
      }
    }

    if (event.key === "Enter") {
      CustomEditor.toggleParaBlock(editor);
    }
    if (event.key === "/") {
      setQuickReplyInput("/");
      setShowShortcuts(true);
      setSelectedIndex(0);
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        Math.min(prevIndex + 1, replies.length - 1)
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (event.key === " ") {
      setQuickReplyInput("");
      setShowShortcuts(false);
    } else if (event.key === "Backspace") {
      setQuickReplyInput((prevInput) => {
        const newInput = prevInput.slice(0, -1);
        if (newInput === "/") {
          setShowShortcuts(true);
        } else if (newInput === "") {
          setQuickReplyInput("");
          setShowShortcuts(false);
        }
        return newInput;
      });
    } else if (event.key === "Enter" && showShortcuts) {
      event.preventDefault();
      const selectedReply = replies[selectedIndex];
      if (selectedReply) {
        let content = displayText(selectedReply.message, variables);
        insertContent(content);
      }
    } else if (showShortcuts) {
      setQuickReplyInput(quickReplyInput + event.key);
    }

    if (!event.ctrlKey) {
      return;
    }

    switch (event.key) {
      // When "`" is pressed, keep our existing code block logic.
      case "`": {
        event.preventDefault();
        CustomEditor.toggleCodeBlock(editor);
        break;
      }

      // When "B" is pressed, bold the text in the selection.
      case "b": {
        event.preventDefault();
        CustomEditor.toggleBoldMark(editor);
        break;
      }

      case "i": {
        event.preventDefault();
        CustomEditor.toggleItalicMark(editor);
        break;
      }

      case "u": {
        event.preventDefault();
        CustomEditor.toggleUnderlineMark(editor);
        break;
      }
    }
  };

  const handleEditorPaste = (event) => {
    event.preventDefault();

    const pastedText = event.clipboardData.getData("text/plain");
    const pastedHTML = event.clipboardData.getData("text/html");

    if (pastedText.match(/^https?:\/\/\S+$/)) {
      // If the pasted content is a plain URL, convert it to an anchor tag HTML
      const url = pastedText;
      const anchorText = url;

      // Insert the anchor tag as a link within a text node
      const linkNode = {
        type: "link",
        url,
        children: [{ text: anchorText }],
      };
      Transforms.insertNodes(editor, linkNode);
    } else if (pastedHTML) {
      // If the pasted content is HTML, insert the HTML content as nodes
      const fragment = new DOMParser().parseFromString(pastedHTML, "text/html");
      const children = Array.from(fragment.body.childNodes);

      // Insert each child node, and wrap anchor tags within a link node
      const nodesToInsert = children.map((child) => {
        if (child.nodeName === "A") {
          return {
            type: "link",
            url: child.href,
            children: [{ text: child.textContent }],
          };
        }
        return {
          type: "paragraph",
          children: [{ text: child.textContent }],
        };
      });

      Transforms.insertNodes(editor, nodesToInsert);
    } else {
      // If no HTML or URL, insert the plain text
      Transforms.insertText(editor, pastedText);
    }
  };

  const handleFocus = (field) => {
    setIsFocused(field);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused("");
    }, 200); // Delay to allow click event to register
  };

  const handleEmailGroups = (group) => {
    if (isFocused === "to") {
      setToID([
        ...new Set([
          ...group.email_ids,
          ...toID.filter((a) => a !== tempNewEmail),
        ]),
      ]);
    } else if (isFocused === "cc") {
      setCcID([
        ...new Set([
          ...group.email_ids,
          ...ccID.filter((a) => a !== tempNewEmail),
        ]),
      ]);
    } else if (isFocused === "bcc") {
      setBccID([
        ...new Set([
          ...group.email_ids,
          ...bccID.filter((a) => a !== tempNewEmail),
        ]),
      ]);
    }
    setTempNewEmail("");
  };

  const EmailGroupsSection = () => {
    return (
      <StyledEmailGroups>
        {emailGroups
          .filter((a) =>
            a.group_name
              .toLowerCase()
              .includes((tempNewEmail || "").toLowerCase())
          )
          .map((group) => {
            return (
              <div
                key={group.group_name}
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleEmailGroups(group)}
              >
                <p>{group.group_name}</p>
                <p>{group.email_ids.map((email) => email).join(" , ")}</p>
                <Divider style={{ margin: "5px" }} />
              </div>
            );
          })}
      </StyledEmailGroups>
    );
  };

  const handleSubmit = () => {
    if (toID.length > 0) {
      setInitiatingMail(true);
      dispatch(
        initiateMail({
          subject: subject,
          from_email: fromID,
          to_emails: toID,
          cc_emails: ccID,
          bcc_emails: bccID,
          link_ticket_id: subTicketID !== "" ? subTicketID : null,
          owner_id: selectedAgent,
          status: selectedStatus,
          tags: tags.length > 0 ? tags.join(",") : null,
          email_body: ReactEditor.toDOMNode(editor, editor)
            .innerHTML.replace(/\$/g, "")
            .replace(/\$&nbsp;/g, ""),
          attachment: attachmentData,
        })
      );
    } else {
      toast.error(`Recipient email address is required to send an email`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  useEffect(() => {
    if (initiateMailValue.error == null && initiatingMail) {
      setCreateNewEmail(false);
      setInitiatingMail(false);
      toast.success(
        `Mail has been sent and ticket id is: ${initiateMailValue.response.ticket_id}`,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        }
      );
      dispatch(resetAttachmentData());
      setAttachmentData([]);
    } else if (initiateMailValue.error !== null) {
      setInitiatingMail(false);
      const err = initiateMailValue?.error || initiateMailValue;
      if (
        err.error &&
        err.error !== null &&
        Object.keys(err.error).length > 0
      ) {
        {
          Object.keys(err.error).map((key) =>
            toast.error(`${key} : ${err.error[key][0]}`, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            })
          );
        }
      } else {
        toast.error(`Mail could not be sent`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  }, [initiateMailValue]);

  function displayText(template, data) {
    const matcher = /{{(.*?)}}/g;

    return template.replace(matcher, (match) => {
      return data[match.split(/{{|}}/).filter(Boolean)[0].trim()];
    });
  }

  const variables = {
    userName: basicInfo
      ? basicInfo.first_name + " " + basicInfo.last_name
      : "NOT_FOUND",
    userEmail: basicInfo && basicInfo.email ? basicInfo.email : "NOT_FOUND",
    userPhone: basicInfo && basicInfo.phone ? basicInfo.phone : "NOT_FOUND",
    userAddress: basicInfo && basicInfo.city ? basicInfo.city : "NOT_FOUND",
    userAddressPinCode:
      basicInfo && basicInfo.pin ? basicInfo.pin : "NOT_FOUND",
    lastOrderNumber: firstOrder ? firstOrder.order_number : "NOT_FOUND",
    lastOrderPrice: firstOrder ? firstOrder.total_order_price : "NOT_FOUND",
    lastOrderTrackingUrl:
      firstOrder && firstOrder.shipment_details
        ? firstOrder.shipment_details.shipment_tracking_url
        : "NOT_FOUND",
    lastOrderAWB:
      firstOrder && firstOrder.shipment_details
        ? firstOrder.shipment_details.shipment_tracking_id
        : "NOT_FOUND",
    lastOrderShippingCompany:
      firstOrder && firstOrder.shipment_details
        ? firstOrder.shipment_details.shipment_company
        : "NOT_FOUND",
    lastOrderShipmentStatus:
      firstOrder && firstOrder.shipment_details
        ? firstOrder.shipment_details.fulfillment_status
        : "NOT_FOUND",
    lastOrderProducts: firstOrder
      ? firstOrder.line_items.map((el) => el.name).join(", ")
      : "NOT_FOUND",
  };

  const insertContent = (content) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);

      // Get text from the beginning of the document to the selection point
      const beforeText = Editor.string(editor, {
        anchor: { path: [0], offset: 0 }, // Start from the beginning
        focus: start,
      });

      // Find the index of the last slash
      const slashIndex = beforeText.lastIndexOf("/");

      if (slashIndex !== -1) {
        // Define the range to delete
        const from = {
          path: start.path,
          offset: start.offset - (beforeText.length - slashIndex),
        };
        const to = { path: start.path, offset: start.offset };

        // Delete the text from the slash to the current selection
        Transforms.delete(editor, { at: { anchor: from, focus: to } });
      } else {
        // Handle the case where there is no slash
        // Just delete from the current position to the start of selection
        Transforms.delete(editor, {
          at: { anchor: start, focus: selection.focus },
        });
      }

      // Insert the new content at the current selection
      Transforms.insertText(editor, content, { at: editor.selection });

      // Reset input and hide shortcuts
      setQuickReplyInput("");
      setShowShortcuts(false);
    }
  };

  useEffect(() => {
    if (replyListRef.current) {
      const selectedElement = replyListRef.current.children[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView(true);
      }
    }
  }, [selectedIndex, showShortcuts]);

  useEffect(() => {
    if (derivedAgents) {
      let temp = [];
      for (let i = 0; i < derivedAgents.length; i++) {
        if (derivedAgents[i].id !== undefined) {
          temp.push({
            key: derivedAgents[i].id,
            title: `${derivedAgents[i].first_name} ${derivedAgents[i].last_name}`,
          });
        }
      }
      setAllAgents(temp);
    }
  }, [settings, derivedAgents]);

  const handleDeleteTag = (e) => {
    const index = tags.indexOf(e);
    const tempTags = [...tags];
    tempTags.splice(index, 1);
    setTags(tempTags);
  };

  return (
    <EmailWrapper>
      <EmailHeading>
        <p>Compose Email</p>
        <IoClose
          color="#fff"
          size={20}
          onClick={() => {
            setAttachmentData([]);
            dispatch(resetAttachmentData());
            setCreateNewEmail(false);
          }}
        />
      </EmailHeading>
      <div style={{ margin: "0px 10px" }}>
        <label
          for="From"
          style={{
            display: "flex",
            color: "#5b5b5b",
            alignItems: "center",
            margin: "5px 0",
          }}
        >
          From:{" "}
          <Select
            options={fromEmails}
            value={fromID}
            onChange={(e) => setFromID(e)}
            style={{ width: "100%", marginLeft: "4px" }}
          />
        </label>
        <label
          for="To"
          style={{
            display: "flex",
            color: "#5b5b5b",
            alignItems: "center",
            margin: "5px 0",
          }}
        >
          To:{" "}
          <Input
            required
            placeholder="Recipient's mail"
            value={toID}
            autoFocus
            onChange={(e) => handleTo(e)}
            type="email"
            style={{
              border: "none",
              borderBottom: "1px solid #d1d1d1",
              margin: "0",
              marginLeft: "24px",
            }}
            onFocus={() => handleFocus("to")}
            onBlur={handleBlur}
          />{" "}
        </label>
        {isFocused === "to" && emailGroups.length > 0 && <EmailGroupsSection />}
        <label
          for="subject"
          style={{
            display: "flex",
            color: "#5b5b5b",
            alignItems: "center",
            margin: "5px 0",
          }}
        >
          Sub:{" "}
          <Input
            type="text"
            required
            placeholder="Subject"
            value={subject}
            style={{
              border: "none",
              borderBottom: "1px solid #d1d1d1",
              marginLeft: "15px",
            }}
            onChange={(e) => setSubject(e.target.value)}
          />
        </label>
        <p
          style={{
            position: "absolute",
            right: "9%",
            textDecoration: "underline",
            color: "#5b5b5b",
            top: "13%",
            cursor: "pointer",
          }}
          onClick={() => {
            setCC(!cc);
          }}
        >
          Cc
        </p>
        <p
          style={{
            position: "absolute",
            right: "3%",
            textDecoration: "underline",
            color: "#5b5b5b",
            top: "13%",
            cursor: "pointer",
          }}
          onClick={() => {
            setBCC(!bcc);
          }}
        >
          Bcc
        </p>
        {cc && (
          <label
            for="cc"
            style={{
              display: "flex",
              color: "#5b5b5b",
              alignItems: "center",
              margin: "5px 0",
            }}
          >
            Cc:{" "}
            <Input
              placeholder="Cc"
              value={ccID}
              onChange={(e) => handleCc(e)}
              type="email"
              style={{
                marginLeft: "20px",
                border: "none",
                borderBottom: "1px solid #d1d1d1",
              }}
              onFocus={() => handleFocus("cc")}
              onBlur={handleBlur}
            />{" "}
          </label>
        )}
        {isFocused === "cc" && emailGroups.length > 0 && <EmailGroupsSection />}
        {bcc && (
          <label
            for="bcc"
            style={{
              display: "flex",
              color: "#5b5b5b",
              alignItems: "center",
              margin: "5px 0 10px",
            }}
          >
            Bcc:{" "}
            <Input
              placeholder="Bcc"
              value={bccID}
              onChange={(e) => handleBcc(e)}
              type="email"
              style={{
                marginLeft: "0px",
                border: "none",
                borderBottom: "1px solid #d1d1d1",
                marginLeft: "15px",
              }}
              onFocus={() => handleFocus("bcc")}
              onBlur={handleBlur}
            />{" "}
          </label>
        )}
        {isFocused === "bcc" && emailGroups.length > 0 && (
          <EmailGroupsSection />
        )}
        <label
          for="linkTicket"
          style={{
            display: "flex",
            color: "#5b5b5b",
            alignItems: "center",
            margin: "5px 0",
          }}
        >
          Link Ticket ID:
          <Input
            placeholder="Link this email with a Ticket ID here (optional)"
            onChange={(e) => setSubTicketID(e.target.value)}
            type="number"
            name="Link Ticket"
            id="linkTicket"
            value={subTicketID}
            style={{
              marginLeft: "0px",
              border: "none",
              borderBottom: "1px solid #d1d1d1",
              marginLeft: "15px",
              width: "80%",
            }}
          />
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px 0",
          }}
        >
          <label
            for="agent"
            style={{
              display: "flex",
              color: "#5b5b5b",
              alignItems: "center",
            }}
          >
            Agent:
            <Select
              value={selectedAgent}
              onChange={(value) => setSelectedAgent(value)}
              style={{ width: "200px", marginleft: "10px" }}
              placeholder="Select an agent"
            >
              {allAgents &&
                allAgents.map((item) => (
                  <Option key={item.key} value={item.key}>
                    {item.title}
                  </Option>
                ))}
            </Select>
          </label>
          <label
            for="status"
            style={{
              display: "flex",
              color: "#5b5b5b",
              alignItems: "center",
              marginLeft: "5px",
            }}
          >
            Status:
            <Select
              value={selectedStatus}
              onChange={(value) => setSelectedStatus(value)}
              style={{ width: "200px", marginLeft: "10px" }}
            >
              {statusOptions.map((item) => {
                return (
                  <Option key={item.key} value={item.key}>
                    <p>{item.title}</p>
                  </Option>
                );
              })}
            </Select>
          </label>
        </div>
        <label
          for="tags"
          style={{
            display: "flex",
            color: "#5b5b5b",
            alignItems: "center",
            margin: "5px 0",
          }}
        >
          Tags:
          <Select
            allowClear={true}
            mode="multiple"
            style={{
              width: "100%",
              fontSize: "14px",
              marginLeft: "10px",
            }}
            placeholder="Select Tags"
            value={tags !== null && tags.length > 0 ? tags.map((a) => a) : []}
            placement="topLeft"
            onSelect={(e) => setTags([...tags, e])}
            onDeselect={(e) => handleDeleteTag(e)}
          >
            {allTags}
          </Select>
        </label>
      </div>
      <div className="slate-editor">
        <Slate editor={editor} selection={selection} value={value}>
          <Editable
            renderElement={renderElement}
            value={value}
            placeholder="Compose Email"
            className="editor-container"
            spellCheck
            renderLeaf={renderLeaf}
            onPaste={(event) => {
              handleEditorPaste(event);
            }}
            onKeyDown={(event) => {
              editorKeyDownEvent(event);
            }}
            style={{ height: "300px", overflowY: "scroll" }}
          />
          {showShortcuts && replies?.length > 0 && (
            <StyledEmailReply style={{ left: "0", right: "0" }}>
              <ul
                ref={replyListRef}
                style={{ listStyle: "none", padding: "0" }}
              >
                {replies
                  .filter((val) => {
                    if (quickReplyInput == "") {
                      return val;
                    } else if (
                      val.shortcut
                        .toLowerCase()
                        .includes(quickReplyInput.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((reply, i) => {
                    return (
                      <Fragment key={i}>
                        <li
                          className={i === selectedIndex ? "selected" : ""}
                          style={{ padding: "5px" }}
                          key={reply.id}
                          tabIndex={i}
                        >
                          <div
                            style={{ margin: "15px 0", cursor: "pointer" }}
                            onClick={() => {
                              let content = displayText(
                                reply.message,
                                variables
                              );
                              insertContent(content);
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                color: "#474747",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              {reply.shortcut}{" "}
                              <span
                                style={{
                                  color: "#6F6F6F",
                                  fontSize: "16px",
                                  fontWeight: "200",
                                }}
                              >
                                - Quick Reply
                              </span>
                            </p>
                            <p
                              style={{
                                margin: "5px 0 0 0",
                                color: "#474747",
                                fontSize: "16px",
                              }}
                            >
                              {reply.message}
                            </p>
                          </div>
                        </li>
                        <Divider style={{ margin: "0" }} />
                      </Fragment>
                    );
                  })}
              </ul>
            </StyledEmailReply>
          )}
          {attachmentData && attachmentData.length > 0 && (
            <div>
              {attachmentData.map((data) => {
                return (
                  <StyledEmailAttachment key={data.url}>
                    <div>
                      {data.content_type.indexOf("image") !== -1 ? (
                        <img
                          style={{ width: "20px", height: "20px" }}
                          src={data.url}
                          alt="Preview"
                        />
                      ) : data.content_type.indexOf("video") !== -1 ? (
                        <ReactPlayer
                          url={data.url}
                          width="20px"
                          height="20px"
                        />
                      ) : data.content_type.indexOf("pdf") ? (
                        <PdfIcon width="20px" height="20px" />
                      ) : data.content_type.indexOf("docx") ? (
                        <DocxIcon width="20px" height="20px" />
                      ) : (
                        <GenericIcon width="20px" height="20px" />
                      )}
                      <a
                        href={data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.file_name}
                      </a>
                    </div>
                    <p onClick={() => removeAttachment(data.url)}>X</p>
                  </StyledEmailAttachment>
                );
              })}
            </div>
          )}
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "80%",
              maxWidth: "570px",
              position: "fixed",
              bottom: "0px",
              background: "#fff",
              padding: "10px",
              margin: "0px",
            }}
          >
            <Dropdown overlay={styleMenu} placement="topLeft">
              <TypeIcon style={{ cursor: "pointer" }} />
            </Dropdown>
            <Attachment
              ownerStatus={true}
              ticket={ticket}
              conversationSource="email"
            />
            <Dropdown overlay={alignMenu} placement="top">
              <AlignIcon style={{ cursor: "pointer" }} />
            </Dropdown>
            <Dropdown overlay={listMenu} placement="top">
              <ListIcon stroke="#474747" style={{ cursor: "pointer" }} />
            </Dropdown>
            <LinkIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleInsertLink()}
            />
            <Modal
              title="Add Link"
              centered
              onCancel={() => setIsModalVisible(false)}
              visible={isModalVisible}
              onOk={() => handleOk()}
              okButtonProps={{
                style: { background: "var(--color-primary)" },
              }}
            >
              <input
                placeholder="Paste the url here"
                onChange={(e) => setLink(e.target.value)}
                style={{ width: "100%", height: "40px", padding: "0 15px" }}
              />
              <Button
                onClick={() => unwrapLink(editor)}
                style={{
                  margin: "auto",
                  marginTop: "10px",
                  border: "none",
                  background: "var(--color-primary)",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "10px",
                  display: "block",
                }}
              >
                Remove Link
              </Button>
            </Modal>
            <Button
              onClick={(e) => handleSubmit(e)}
              style={{
                display: "flex",
                fontSize: "16px",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                width: "100px",
                border: "none",
                borderRadius: "4px",
              }}
              disabled={initiatingMail}
              type="primary"
            >
              {initiatingMail ? "Sending " : "Send "}{" "}
              <SendIcon style={{ stroke: "#fff", margin: "0 8px" }} />
            </Button>
          </Toolbar>
        </Slate>
      </div>
    </EmailWrapper>
  );
}

export default Email;
