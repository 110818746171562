import React from "react";
import { useLocation } from "react-router-dom";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function Validate() {
  const search = useLocation().search;
  const hostname = window.location.hostname;
  let origin;
  switch (hostname) {
    case "localhost":
    case "inbox.test.logisy.in": {
      origin = `https://test.logisy.in`;
      break;
    }
    case "inbox.logisy.tech": {
      origin = `https://logisy.tech`;
      break;
    }
    case "inbox.stage.logisy.tech": {
      origin = `https://stage.logisy.tech`;
      break;
    }
    default:
      origin = `https://test.logisy.in`;
      break;
  }
  const auth = new URLSearchParams(search).get("token");
  const ticketID = new URLSearchParams(search).get("t");
  const authParse = parseJwt(auth);
  const authObject = { auth: { token: `jwt ${auth}`, user: authParse } };
  localStorage.setItem("storeState", JSON.stringify(authObject));
  if (auth === null || auth === "" || auth === undefined) {
    window.location.href = `${origin}/users/login`;
  } else if (
    ticketID &&
    ticketID !== null &&
    ticketID !== undefined &&
    ticketID !== "None" &&
    auth !== null &&
    auth !== "" &&
    auth !== undefined
  ) {
    window.location.href = `https://${hostname}/inbox/tickets/${ticketID}`;
  } else {
    window.location.href = "/inbox/tickets";
  }
  return <div></div>;
}

export default Validate;
