import * as types from './types';

export const loadSockets = () => ({
    type: types.LOAD_SOCKET,
});

export const openDrawer = (payload) => ({
    type: types.FILTER_DRAWER,
    payload
});

export const setProfile = (payload) => ({
    type: types.SET_PROFILE,
    payload
});

export const setwsstatus = (payload) =>({
    type: types.SET_WS_STATUS,
    payload
})

export const reloadWS = () => ({
    type: types.RELOAD_WS,
})