export const SCOPE = "conversation";
export const INIT = `${SCOPE}/INIT`;
export const GET_CONVERSATION = `${SCOPE}/GET_CONVERSATION`;
export const RECEIVE_CONVERSATION = `${SCOPE}/RECEIVE_CONVERSATION`;
export const SET_ACTIVE_CONVERSATION_ID = `${SCOPE}/SET_ACTIVE_CONVERSATION_ID`;
export const SEND_MESSAGE = `${SCOPE}/SEND_MESSAGE`;
export const ADD_TAGS = `${SCOPE}/ADD_TAGS`;
export const REMOVE_TAGS = `${SCOPE}/REMOVE_TAGS`;
export const SET_STATUS = `${SCOPE}/SET_STATUS`;
export const UPDATE_STATUS = `${SCOPE}/UPDATE_STATUS`;
export const STATUS_UPDATE_ERROR = `${SCOPE}/STATUS_UPDATE_ERROR`;
export const GET_STATUS = `${SCOPE}/GET_STATUS`;
export const SET_PRIORITY = `${SCOPE}/SET_PRIORITY`;
export const GET_PRIORITY = `${SCOPE}/GET_PRIORITY`;
export const NEW_MESSAGE = `${SCOPE}/NEW_MESSAGE`;
export const ADD_SUBSCRIBER_FEEDBACK = `${SCOPE}/ADD_SUBSCRIBER_FEEDBACK`;
export const REMOVE_SUBSCRIBER_FEEDBACK = `${SCOPE}/REMOVE_SUBSCRIBER_FEEDBACK`;
export const ADD_SUBSCRIBER = `${SCOPE}/ADD_SUBSCRIBER`;
export const REMOVE_SUBSCRIBER = `${SCOPE}/REMOVE_SUBSCRIBER`;
export const ADD_TAGS_FEEDBACK = `${SCOPE}/ADD_TAGS_FEEDBACK`;
export const REMOVE_TAGS_FEEDBACK = `${SCOPE}/REMOVE_TAGS_FEEDBACK`;
export const SET_FILE_UPLOAD = `${SCOPE}/SET_FILE_UPLOAD`;
export const SET_FILE_UPLOAD_SUCCESS = `${SCOPE}/SET_FILE_UPLOAD_SUCCESS`;
export const SET_FILE_UPLOAD_ERROR = `${SCOPE}/SET_FILE_UPLOAD_ERROR`;
export const UPDATE_MESSAGE_DELIVERED = `${SCOPE}/UPDATE_MESSAGE_DELIVERED`;
export const UPDATE_MESSAGE_READ = `${SCOPE}/UPDATE_MESSAGE_READ`;
export const UPDATE_MESSAGE_SENT = `${SCOPE}/UPDATE_MESSAGE_SENT`;
export const SET_OWNERSHIP_TO_ME = `${SCOPE}/SET_OWNERSHIP_TO_ME`;
export const CHANGE_OWNERSHIP = `${SCOPE}/CHANGE_OWNERSHIP`;
export const UPDATE_OWNERSHIP_CHANGE = `${SCOPE}/UPDATE_OWNERSHIP_CHANGE`;
export const CHANGE_OWNERSHIP_ERROR = `${SCOPE}/CHANGE_OWNERSHIP_ERROR`;
export const MESSAGE_DELETED = `${SCOPE}/MESSAGE_DELETED`;
export const ACTIVE_ID = `${SCOPE}/ACTIVE_ID`;
export const GET_QUICK_REPLIES = `${SCOPE}/GET_QUICK_REPLIES`;
export const GET_QUICK_REPLIES_SUCCESS = `${SCOPE}/GET_QUICK_REPLIES_SUCCESS`;
export const GET_QUICK_REPLIES_ERROR = `${SCOPE}/GET_QUICK_REPLIES_ERROR`;
export const REPLY_TO_COMMENT = `${SCOPE}/REPLY_TO_COMMENT`;
export const DELETE_COMMENT = `${SCOPE}/DELETE_COMMENT`;
export const COMMENT_CREATED = `${SCOPE}/COMMENT_CREATED`;
export const COMMENT_DELETED = `${SCOPE}/COMMENT_DELETED`;
export const NEW_COMMENT = `${SCOPE}/NEW_COMMENT`;
export const SET_EMAIL_UPLOAD = `${SCOPE}/SET_EMAIL_UPLOAD`;
export const SET_EMAIL_UPLOAD_SUCCESS = `${SCOPE}/SET_EMAIL_UPLOAD_SUCCESS`;
export const SET_EMAIL_UPLOAD_ERROR = `${SCOPE}/SET_EMAIL_UPLOAD_ERROR`;
export const MESSAGE_FAILED = `${SCOPE}/MESSAGE_FAILED`;
export const SET_ACTIVE_CONVERSATION_ID_BG = `${SCOPE}/SET_ACTIVE_CONVERSATION_ID_BG`;
export const SET_FOLLOW_UP = `${SCOPE}/SET_FOLLOW_UP`;
export const UPDATE_QUICK_REPLIES = `${SCOPE}/UPDATE_QUICK_REPLIES`;
export const RESET_ATTACHMENT_DATA = `${SCOPE}/RESET_ATTACHMENT_DATA`;
