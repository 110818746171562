import React from "react";
import { StyledTimeline } from "./styled";
import { MdEventNote } from "react-icons/md";
import ReactHtmlParser from "react-html-parser";

function NotesTimeline({ note }) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const modifyAnchorTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    doc.querySelectorAll("a").forEach((link) => {
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
    });

    return doc.body.innerHTML;
  };

  const RenderHtmlContent = ({ htmlContent }) => {
    const modifiedHtml = modifyAnchorTags(htmlContent);

    return <div>{ReactHtmlParser(modifiedHtml)}</div>;
  };

  return (
    <StyledTimeline className="relative">
      <div className="column-right ">
        <div className="col-card-right">
          <div className="col-card-subtitle col-card-title-right">
            <div>
              <span style={{ fontWeight: "700" }}>{note.agent_name}</span>
              <span style={{ fontSize: "12px" }}>
                {note.created_at && (
                  <span> {formatDate(note.created_at)} </span>
                )}
              </span>
            </div>
          </div>
          <div className="col-card-title col-card-subtitle-right">
            <RenderHtmlContent htmlContent={note.notes} />
          </div>
        </div>
      </div>

      <div className="column-center">
        <div className="line"></div>
        <div className="ticket-circle">
          <MdEventNote />
        </div>
      </div>

      <div className="clearfix"></div>
    </StyledTimeline>
  );
}

export default NotesTimeline;
