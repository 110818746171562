import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tag, Collapse } from 'antd';
import { orderDataSelector, ordersLoaderSelector } from '@store-inbox/SummaryPanel/selector';
import OrderBox from '../common/OrderBox';
import { StyledOrdersListWrapper, StyledOrderHeader,StyledAllTags } from '../styled';
import { parseISO, format } from 'date-fns';
import { ReactComponent as Empty } from '@assets/icons/ordernil.svg';

const { Panel } = Collapse;


export default function OrdersComponent() {
  const data = useSelector(orderDataSelector);
  const loader = useSelector(ordersLoaderSelector);
  const [key,setKey] = useState(null);

  const Header = ({ order, index }) => (
    <Fragment>
      <StyledOrderHeader>
        <div style={{display: 'flex',width: '75%',justifyContent: 'space-between',marginRight: '20px'}}>
          <header>
            <a style={{color: '#000'}} href={order && order.order_url!==null ? order.order_url : "#"} target={order && order.order_url!==null ? "_blank" : "_self"} rel='noreferrer noopener'>{`#${order.order_number}`}</a>
            {!(key==index) && <p style={{fontSize: '12px',fontWeight: '400',flexShrink: '0'}}>{format(parseISO(order.order_created_at), 'dd MMM, yyyy')}</p>}
          </header>
          {(key==index) && <p style={{fontSize: '12px',fontWeight: '400',flexShrink: '0',margin: '4px 6px'}}>{format(parseISO(order.order_created_at), 'dd MMM, yyyy')}</p>}
          {!(order.is_cod && order.is_cancelled && order.fulfillment_status) && 
            <div style={{display: 'flex',justifyContent: 'space-between',marginTop: '4px'}}>
              {order.is_cod ? <Tag style={{ backgroundColor: 'rgba(219, 37, 37, 0.2)', color: 'rgba(219, 37, 37, 1)' }}>COD</Tag> : null}
              {order.is_cancelled ? <Tag style={{ backgroundColor: 'rgba(228, 158, 20, 0.2)', color: 'rgba(228, 158, 20, 1)' }}>Cancel</Tag> : null}
              {order.fulfillment_status ? <Tag style={{ backgroundColor: 'rgba(22, 172, 55, 0.2)', color: 'rgba(22, 172, 55, 1)' }}>Paid</Tag> : null}
            </div>
          }
        </div>
        <p style={{fontSize:"12px",textDecoration: 'underline',color: 'var(--color-primary)',marginTop:'4px',marginLeft: '10px',flexShrink: '0'}}>{(key==index) ? 'Show Less' : 'More details'}</p>
      </StyledOrderHeader>
      {(key==index) && (order.is_cod && order.is_cancelled && order.fulfillment_status) &&
         <StyledAllTags>
          {order.is_cod ? <Tag style={{ backgroundColor: 'rgba(219, 37, 37, 0.2)', color: 'rgba(219, 37, 37, 1)' }}>COD</Tag> : null}
          {order.is_cancelled ? <Tag style={{ backgroundColor: 'rgba(228, 158, 20, 0.2)', color: 'rgba(228, 158, 20, 1)' }}>Cancel</Tag> : null}
          {order.fulfillment_status ? <Tag style={{ backgroundColor: 'rgba(22, 172, 55, 0.2)', color: 'rgba(22, 172, 55, 1)' }}>Paid</Tag> : null}
        </StyledAllTags>
      }
    </Fragment>
  );

  const handleCollapse = (e)=>{
    setKey(e);
  }

  if (!loader) {
    return (
      <StyledOrdersListWrapper>
        {data.orders && data.orders.length === 0 ?
                    <div style={{textAlign: 'center', marginTop: "40px"}}>
                        <Empty style={{marginLeft: '-20px'}} />
                        <p style={{color: "#474747",fontSize: "26px",fontWeight: "700",margin: "0",marginTop:"10px"}}>No orders!</p>
                        <p style={{color: "#474747",fontSize: "16px",fontWeight: "300"}}>No orders data available for this customer.</p>
                        <p style={{color: "#474747",fontSize: "16px",fontWeight: "300"}}>Try reloading this page.</p>
                    </div>
          :
          <Collapse accordion="true" onChange={handleCollapse}>
            {data.orders && data.orders.map((order, i) => {
              return (
                <Panel
                  key={i}
                  header={<Header order={order} index={i} />}
                  showArrow={false}
                >
                  <OrderBox order={order} key={`order-inside${i}`} isOrder={true} />
                </Panel>
              )
            })}
          </Collapse>
        }
      </StyledOrdersListWrapper>
    );
  } else {
    return <div>Loading...</div>
  }
}