import React, { useState, useEffect, useRef } from "react";
import { derivedAllTickets } from "../../ConversationPanel/utils";
import {
  convertToRaw,
  EditorState,
  Modifier,
  AtomicBlockUtils,
} from "draft-js";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { StyledNotes } from "./styled";
import { setEmailUploadFile } from "@store-inbox/ConversationPanel/action";
import { updateNotesAPI } from "@utils/api";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { toast } from "react-toastify";
import { notesSelector } from "@store-inbox/SummaryPanel/selector";
import NotesTimeline from "./NotesTimeline";
import { FaInfoCircle } from "react-icons/fa";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Notes() {
  const dispatch = useDispatch();

  const allTickets = useSelector(derivedAllTickets);
  const allNotes = useSelector(notesSelector);

  const [notesLoading, setNotesLoading] = useState(false);
  const [ticketId, setTicketId] = useState("");
  const [media, setMedia] = useState([]);
  const [updatedNotes, setUpdatedNotes] = useState(allNotes);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const editorRef = useRef(null);

  toast.configure();

  useEffect(() => {
    if (allTickets && allTickets !== null && allTickets.length > 0) {
      setTicketId(allTickets[0].id);
    }
  }, [allTickets]);

  useEffect(() => {
    if (allNotes && allNotes.length > 0) {
      setUpdatedNotes(allNotes);
    } else {
      setUpdatedNotes([]);
    }
  }, [allNotes]);

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      const onUploadSuccess = (response) => {
        const fileUrl = response.data.data[0].url; // Adjust according to your response structure
        // const fileName = response.data.data[0].file_name;
        const fileType = file.type.split("/")[0];
        setMedia([...media, response.data.data[0]]);

        if (fileType === "image") {
          setTimeout(() => {
            const imageModal = document.querySelector(".rdw-image-wrapper");
            if (imageModal) {
              const closeButton = imageModal.querySelector(
                ".rdw-image-modal-btn-section .rdw-image-modal-btn:first-child"
              );
              if (closeButton) {
                closeButton.click();
              }
            }
          }, 0);
          // For images, we'll use AtomicBlockUtils to insert the image
          const contentState = editorState.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity(
            "IMAGE",
            "IMMUTABLE",
            { src: fileUrl }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = AtomicBlockUtils.insertAtomicBlock(
            EditorState.set(editorState, {
              currentContent: contentStateWithEntity,
            }),
            entityKey,
            " "
          );
          setEditorState(newEditorState);
          resolve({ data: { link: null } });

          // resolve({ data: { link: fileUrl } });
        } else {
          // Insert URL directly into the editor content for non-image files
          const contentState = editorState.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity(
            "LINK",
            "MUTABLE",
            { url: fileUrl, target: "_blank", rel: "noopener noreferrer" }
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newContentState = Modifier.insertText(
            contentStateWithEntity,
            editorState.getSelection(),
            fileUrl,
            null,
            entityKey
          );
          const newEditorState = EditorState.push(
            editorState,
            newContentState,
            "insert-characters"
          );
          setEditorState(newEditorState);

          resolve({ data: { link: null } });
        }

        setTimeout(() => {
          const imageModal = document.querySelector(".rdw-image-wrapper");
          if (imageModal) {
            const closeButton = imageModal.querySelector(
              ".rdw-image-modal-btn-section .rdw-image-modal-btn:last-child"
            );
            if (closeButton) {
              closeButton.click();
            }
          }
        }, 0);
      };

      const onUploadFailure = (error) => {
        reject(error);
      };

      dispatch(
        setEmailUploadFile({
          files: formData,
          onSuccess: onUploadSuccess,
          onFailure: onUploadFailure,
        })
      );
    });
  };

  const updateNotes = () => {
    setNotesLoading(true);
    updateNotesAPI(
      ticketId,
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
      media
    )
      .then((resp) => {
        toast.success("Notes updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setUpdatedNotes([resp.data.data, ...updatedNotes]);
        setEditorState(EditorState.createEmpty());
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong. Please try again", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      })
      .finally(() => {
        setNotesLoading(false);
      });
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();

        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          insertImage(imageUrl);
        };
        reader.readAsDataURL(blob);
        event.preventDefault();
      }
    }
  };

  const insertImage = (imageUrl) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      {
        src: imageUrl,
      }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " "
    );

    setEditorState(
      EditorState.forceSelection(
        newEditorState,
        newEditorState.getCurrentContent().getSelectionAfter()
      )
    );
  };

  return (
    <StyledNotes>
      <div style={{ border: "1px solid #d5d5d5" }} onPaste={handlePaste}>
        <Editor
          ref={editorRef}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          placeholder="Add a note"
          toolbar={{
            options: [
              "inline",
              "emoji",
              "blockType",
              "link",
              "colorPicker",
              "image",
              "textAlign",
              "list",
            ],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            image: {
              uploadEnabled: true,
              uploadCallback: uploadImageCallBack,
              inputAccept:
                "image/gif,image/jpeg,image/jpg,image/png,image/svg,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, video/mp4",
              defaultSize: {
                height: "85%",
                width: "85%",
              },
            },
            embedded: {
              embedCallback: (link) => {
                // Example embed logic for videos, PDFs, etc.
                if (link.match(/.(mp4|pdf|docx)$/i)) {
                  return { src: link };
                }
                return false;
              },
            },
          }}
        />
      </div>
      <p
        style={{
          fontSize: "12px",
          margin: "0",
          marginTop: "5px",
          fontWeight: "400",
          color: "#585f6d99",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FaInfoCircle style={{ marginRight: "2px" }} size={12} />
        Once a note is added, it cannot be edited or deleted
      </p>
      <Button
        className="update-button"
        style={{ marginTop: "2px" }}
        type="primary"
        disabled={notesLoading}
        onClick={() => updateNotes()}
      >
        {notesLoading ? "Adding..." : "Add Note"}
      </Button>

      <p style={{ fontSize: "1rem", margin: "0", fontWeight: "500" }}>
        Previous Notes:{" "}
      </p>

      {updatedNotes &&
        updatedNotes.length > 0 &&
        updatedNotes.map((note) => {
          return <NotesTimeline note={note} />;
        })}
    </StyledNotes>
  );
}

export default Notes;
